<template>
  <AppLayout>
    <h2 class="mb-4">
      Listado de Sucursales
      <router-link
        v-if="(userType === 'Admin' || userModules.includes(moduleSubsidiary)) && userType !== 'Invitado'"
        class="btn btn-primary float-right"
        :to="{ path: '/' + nodeSubsidiary + '/register' }"
      >
        <font-awesome-icon icon="plus" /> Registrar
      </router-link>
    </h2>
    <div class="card mb-4">
      <div class="card-body">
        <div class="row">
          <div class="col-md-12">
            <div class="dropdown">
              <button class="btn btn-primary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                Selecciona un cliente...
              </button>
              <div id="dropdownMenu" class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                <input type="text" placeholder="Buscar.." class="form-control" id="inputSearch" @keyup="filterDropdown()">
                <a v-for="row in dataClient" :key="row.id" @click="getSubsidiaries(row.id)" class="dropdown-item" href="#">{{ row.nombre }}</a>
              </div>
            </div>
          </div>  
        </div>
        <br /> 
        <CommonList :userType="userType" :moduleCommon="moduleSubsidiary" :dataCommon="dataSubsidiary" :nodeCommon="nodeSubsidiary" @deleteCommon="deleteSubsidiary"></CommonList>
      </div>
    </div>
  </AppLayout>
</template>

<script>
import api from "@/api.js"
import AppLayout from "@/components/layout/AppLayout.vue"
import CommonList from "@/components/common/CommonList.vue"
export default {
  name: "subsidiarylist",
  components: {
    AppLayout,
    CommonList
  },
  data() {
    return {
      dataClient: [],
      dataSubsidiaries: [],
      dataSubsidiary: [],
      nodeSubsidiary: "sucursal",
      moduleSubsidiary: "Sucursales",
      userType: "Admin",
      userModules: []
    };
  },
  created: function() {
    this.getClients();
    this.$data.userType = localStorage.getItem("userType");
    this.$data.userModules = localStorage.getItem("userModules");
  },
  methods: {
    getClients: function() {
      let that = this;
      let loader = this.$loading.show();
      this.dataClient.length = 0;
      api
        .getClients()
        .then((data) => {
          that.dataClient = data.clients;
          that.dataSubsidiaries.length = 0;
          api
            .getSubsidiaries()
            .then((data) => {
              that.dataSubsidiaries = data.subsidiaries;
              loader.hide();
            })
            .catch((error) => {
              that.showAlert(error.response.data.message);
              loader.hide();
              if(error.response.status === 401){
                localStorage.removeItem("userToken");
                that.$router.push('/login');
              }
            });
        })
        .catch((error) => {
          that.showAlert(error.response.data.message);
          loader.hide();
          if(error.response.status === 401){
            localStorage.removeItem("userToken");
            that.$router.push('/login');
          }
        });
    },
    getSubsidiaries: function(id) {
      this.dataSubsidiary.length = 0;
      this.dataSubsidiary = this.dataSubsidiaries.filter(subsidiary => subsidiary.id_cliente === id)
    },
    deleteSubsidiary: function(id) {
      let that = this;
      let loader = this.$loading.show();
      api
        .deleteSubsidiary(id)
        .then(() => {
          that.dataSubsidiaries = that.dataSubsidiaries.filter(subsidiary => subsidiary.id != id);
          that.dataSubsidiary = that.dataSubsidiary.filter(subsidiary => subsidiary.id != id);
          loader.hide();
        })
        .catch((error) => {
          that.showAlert(error.response.data.message);
          loader.hide();
          if(error.response.status === 401){
            localStorage.removeItem("userToken");
            that.$router.push('/login');
          }
        });
    },
    showAlert: function(message) {
      this.$swal({
        position: "top-end",
        icon: "error",
        title: message,
        showConfirmButton: false,
        timer: 1500
      });
    },
    filterDropdown: function() {
      let filter = document.getElementById("inputSearch").value.toUpperCase();
      let a = document.getElementById("dropdownMenu").getElementsByTagName("a");
      for (let i = 0; i < a.length; i++) {
        let txtValue = a[i].textContent || a[i].innerText;
        if (txtValue.toUpperCase().indexOf(filter) > -1) {
          a[i].style.display = "";
        } else {
          a[i].style.display = "none";
        }
      }
    } 
  }
};
</script>
