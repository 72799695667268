<template>
  <div>
    <div id="divSt0">
      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label class="font-weight-bold">Consecutivo</label>
            <p class="lead">NUP {{ consecutivo }}</p>
          </div>
          <div class="form-group">
            <label class="font-weight-bold">Fecha Estimada</label>
            <p class="lead">{{ fecha_estimada }}</p>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label class="font-weight-bold">Proveedor</label>
            <p class="lead">{{ proveedor }}</p>
          </div>
          <div class="form-group">
            <label class="font-weight-bold">Bodega</label>
            <p class="lead">{{ bodega }}</p>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="form-group">
            <table class="table table-hover">
              <thead>
                <tr>
                  <th>Número Lote</th>
                  <th>Fecha Lote</th>
                  <th>Fecha Vencimiento</th>
                  <th>Producto</th>
                  <th>Cantidad</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="row in detalle" :key="row.id">
                  <td>{{ !!row.lote_numero ? row.lote_numero : "No Diligenciado" }}</td>
                  <td>{{ !!row.lote_fecha ? row.lote_fecha.substr(0,10) : "No Diligenciado" }}</td>
                  <td>{{ !!row.lote_vencimiento ? row.lote_vencimiento.substr(0,10) : "No Diligenciado" }}</td>
                  <td>{{ row.codigo + " - " + row.nombre }}</td>
                  <td>{{ row.cantidad }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div id="divSt1">
      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label class="font-weight-bold">Proforma</label>
            <br/>
            <div v-if="!isEditOrden" class="input-group mb-3">
              <a v-if="documento_orden" ref="#" @click="downloadFile(documento_orden)" class="btn btn-secondary text-white mr-2">
                <font-awesome-icon icon="eye" /> 
              </a>
              <p v-else class="lead mr-2">No Diligenciado</p>
              <button @click="isEditOrden = true" class="btn btn-primary text-white">
                <font-awesome-icon icon="edit" />
              </button>
            </div>
            <div v-else class="input-group mb-3">
              <div class="input-group-prepend" id="file0">
                <button @click="isEditOrden = false" class="btn btn-danger" type="button"><font-awesome-icon icon="times" /></button>
                <button @click="uploadFile('documento_orden', 'isEditOrden')" class="btn btn-primary" type="button"><font-awesome-icon icon="upload" /></button>
              </div>
              <div class="custom-file">
                <input id="documento_orden" type="file" class="form-control" aria-describedby="file0" accept="application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/msword, application/vnd.ms-excel, application/pdf, image/*" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>   
    <div id="divSt2">
      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label class="font-weight-bold">B/L Number</label>
            <div v-if="!isEditNumero" class="input-group mb-3">
              <p v-if="numero_carga" class="lead mr-2">{{ numero_carga }}</p>
              <p v-else class="lead mr-2">No Diligenciado</p>
              <button @click="isEditNumero = true" class="btn btn-primary text-white">
                <font-awesome-icon icon="edit" />
              </button>
            </div>
            <div v-else class="input-group mb-3">
              <div class="input-group-prepend">
                <button @click="isEditNumero = false" class="btn btn-danger" type="button"><font-awesome-icon icon="times" /></button>
              </div>
              <input id="numero_carga" type="text" :value="numero_carga" class="form-control" />
              <div class="input-group-append">
                <button @click="editItem('numero_carga', 'isEditNumero')" class="btn btn-primary" type="button"><font-awesome-icon icon="save" /></button>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label class="font-weight-bold">Empresa</label>
            <div v-if="!isEditEmpresa" class="input-group mb-3">
              <p v-if="empresa" class="lead mr-2">{{ empresa }}</p>
              <p v-else class="lead mr-2">No Diligenciado</p>
              <button @click="isEditEmpresa = true" class="btn btn-primary text-white">
                <font-awesome-icon icon="edit" />
              </button>
            </div>
            <div v-else class="input-group mb-3">
              <div class="input-group-prepend">
                <button @click="isEditEmpresa = false" class="btn btn-danger" type="button"><font-awesome-icon icon="times" /></button>
              </div>
              <input id="empresa" :value="empresa" type="text" class="form-control" />
              <div class="input-group-append">
                <button @click="editItem('empresa', 'isEditEmpresa')" class="btn btn-primary" type="button"><font-awesome-icon icon="save" /></button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="divSt3">
      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label class="font-weight-bold">Commercial Invoice</label>
            <br />
            <div v-if="!isEditFactura" class="input-group mb-3">
              <a v-if="documento_factura" ref="#" @click="downloadFile(documento_factura)" class="btn btn-secondary text-white mr-2">
                <font-awesome-icon icon="eye" /> 
              </a>
              <p v-else class="lead mr-2">No Diligenciado</p>
              <button @click="isEditFactura = true" class="btn btn-primary text-white">
                <font-awesome-icon icon="edit" />
              </button>
            </div>
            <div v-else class="input-group mb-3">
              <div class="input-group-prepend" id="file0">
                <button @click="isEditFactura = false" class="btn btn-danger" type="button"><font-awesome-icon icon="times" /></button>
                <button @click="uploadFile('documento_factura', 'isEditFactura')" class="btn btn-primary" type="button"><font-awesome-icon icon="upload" /></button>
              </div>
              <div class="custom-file">
                <input id="documento_factura" type="file" class="form-control" aria-describedby="file0" accept="application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/msword, application/vnd.ms-excel, application/pdf, image/*" />                
              </div>
            </div>
          </div>
          <div class="form-group">
            <label class="font-weight-bold">Packing List</label>
            <br />
            <div v-if="!isEditPl" class="input-group mb-3">
              <a v-if="documento_pl" ref="#" @click="downloadFile(documento_pl)" class="btn btn-secondary text-white mr-2">
                <font-awesome-icon icon="eye" /> 
              </a>
              <p v-else class="lead mr-2">No Diligenciado</p>
              <button @click="isEditPl = true" class="btn btn-primary text-white">
                <font-awesome-icon icon="edit" />
              </button>
            </div>
            <div v-else class="input-group mb-3">
              <div class="input-group-prepend" id="file0">
                <button @click="isEditPl = false" class="btn btn-danger" type="button"><font-awesome-icon icon="times" /></button>
                <button @click="uploadFile('documento_pl', 'isEditPl')" class="btn btn-primary" type="button"><font-awesome-icon icon="upload" /></button>
              </div>
              <div class="custom-file">
                <input id="documento_pl" type="file" class="form-control" aria-describedby="file0" accept="application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/msword, application/vnd.ms-excel, application/pdf, image/*" />               
              </div>
            </div>
          </div>
          <div class="form-group">
            <label class="font-weight-bold">B/L</label>
            <br />
            <div v-if="!isEditBl" class="input-group mb-3">
              <a v-if="documento_bl" ref="#" @click="downloadFile(documento_bl)" class="btn btn-secondary text-white mr-2">
                <font-awesome-icon icon="eye" /> 
              </a>
              <p v-else class="lead mr-2">No Diligenciado</p>
              <button @click="isEditBl = true" class="btn btn-primary text-white">
                <font-awesome-icon icon="edit" />
              </button>
            </div>
            <div v-else class="input-group mb-3">
              <div class="input-group-prepend" id="file0">
                <button @click="isEditBl = false" class="btn btn-danger" type="button"><font-awesome-icon icon="times" /></button>
                <button @click="uploadFile('documento_bl', 'isEditBl')" class="btn btn-primary" type="button"><font-awesome-icon icon="upload" /></button>
              </div>
              <div class="custom-file">
                <input id="documento_bl" type="file" class="form-control" aria-describedby="file0" accept="application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/msword, application/vnd.ms-excel, application/pdf, image/*" />  
              </div>
            </div>
          </div>
          <div class="form-group">
            <label class="font-weight-bold">MSDS (Material Safety data sheet)</label>
            <br />
            <div v-if="!isEditMds" class="input-group mb-3">
              <a v-if="documento_mds" ref="#" @click="downloadFile(documento_mds)" class="btn btn-secondary text-white mr-2">
                <font-awesome-icon icon="eye" /> 
              </a>
              <p v-else class="lead mr-2">No Diligenciado</p>
              <button @click="isEditMds = true" class="btn btn-primary text-white">
                <font-awesome-icon icon="edit" />
              </button>
            </div>
            <div v-else class="input-group mb-3">
              <div class="input-group-prepend" id="file0">
                <button @click="isEditMds = false" class="btn btn-danger" type="button"><font-awesome-icon icon="times" /></button>
                <button @click="uploadFile('documento_mds', 'isEditMds')" class="btn btn-primary" type="button"><font-awesome-icon icon="upload" /></button>
              </div>
              <div class="custom-file">
                <input id="documento_mds" type="file" class="form-control" aria-describedby="file0" accept="application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/msword, application/vnd.ms-excel, application/pdf, image/*" />
              </div>
            </div>
          </div>
          <div class="form-group">
            <label class="font-weight-bold">BSE Free Declaration</label>
            <br />
            <div v-if="!isEditBse" class="input-group mb-3">
              <a v-if="documento_bse" ref="#" @click="downloadFile(documento_bse)" class="btn btn-secondary text-white mr-2">
                <font-awesome-icon icon="eye" /> 
              </a>
              <p v-else class="lead mr-2">No Diligenciado</p>
              <button @click="isEditBse = true" class="btn btn-primary text-white">
                <font-awesome-icon icon="edit" />
              </button>
            </div>
            <div v-else class="input-group mb-3">
              <div class="input-group-prepend" id="file0">
                <button @click="isEditBse = false" class="btn btn-danger" type="button"><font-awesome-icon icon="times" /></button>
                <button @click="uploadFile('documento_bse', 'isEditBse')" class="btn btn-primary" type="button"><font-awesome-icon icon="upload" /></button>
              </div>
              <div class="custom-file">
                <input id="documento_bse" type="file" class="form-control" aria-describedby="file0" accept="application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/msword, application/vnd.ms-excel, application/pdf, image/*" />
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label class="font-weight-bold">Certificate of analysis origin</label>
            <br />
            <div v-if="!isEditCertificado1" class="input-group mb-3">
              <a v-if="documento_certificado1" ref="#" @click="downloadFile(documento_certificado1)" class="btn btn-secondary text-white mr-2">
                <font-awesome-icon icon="eye" /> 
              </a>
              <p v-else class="lead mr-2">No Diligenciado</p>
              <button @click="isEditCertificado1 = true" class="btn btn-primary text-white">
                <font-awesome-icon icon="edit" />
              </button>
            </div>
            <div v-else class="input-group mb-3">
              <div class="input-group-prepend" id="file0">
                <button @click="isEditCertificado1 = false" class="btn btn-danger" type="button"><font-awesome-icon icon="times" /></button>
                <button @click="uploadFile('documento_certificado1', 'isEditCertificado1')" class="btn btn-primary" type="button"><font-awesome-icon icon="upload" /></button>
              </div>
              <div class="custom-file">
                <input id="documento_certificado1" type="file" class="form-control" aria-describedby="file0" accept="application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/msword, application/vnd.ms-excel, application/pdf, image/*" />
              </div>
            </div>
          </div>
          <div class="form-group">
            <label class="font-weight-bold">Certificate of analysis for each lot shipped</label>
            <br />
            <div v-if="!isEditCertificado2" class="input-group mb-3">
              <a v-if="documento_certificado2" ref="#" @click="downloadFile(documento_certificado2)" class="btn btn-secondary text-white mr-2">
                <font-awesome-icon icon="eye" /> 
              </a>
              <p v-else class="lead mr-2">No Diligenciado</p>
              <button @click="isEditCertificado2 = true" class="btn btn-primary text-white">
                <font-awesome-icon icon="edit" />
              </button>
            </div>
            <div v-else class="input-group mb-3">
              <div class="input-group-prepend" id="file0">
                <button @click="isEditCertificado2 = false" class="btn btn-danger" type="button"><font-awesome-icon icon="times" /></button>
                <button @click="uploadFile('documento_certificado2', 'isEditCertificado2')" class="btn btn-primary" type="button"><font-awesome-icon icon="upload" /></button>
              </div>
              <div class="custom-file">
                <input id="documento_certificado2" type="file" class="form-control" aria-describedby="file0" accept="application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/msword, application/vnd.ms-excel, application/pdf, image/*" />
              </div>
            </div>
          </div>
          <div class="form-group">
            <label class="font-weight-bold">Insurance certificate</label>
            <br />
            <div v-if="!isEditCertificado3" class="input-group mb-3">
              <a v-if="documento_certificado3" ref="#" @click="downloadFile(documento_certificado3)" class="btn btn-secondary text-white mr-2">
                <font-awesome-icon icon="eye" /> 
              </a>
              <p v-else class="lead mr-2">No Diligenciado</p>
              <button @click="isEditCertificado3 = true" class="btn btn-primary text-white">
                <font-awesome-icon icon="edit" />
              </button>
            </div>
            <div v-else class="input-group mb-3">
              <div class="input-group-prepend" id="file0">
                <button @click="isEditCertificado3 = false" class="btn btn-danger" type="button"><font-awesome-icon icon="times" /></button>
                <button @click="uploadFile('documento_certificado3', 'isEditCertificado3')" class="btn btn-primary" type="button"><font-awesome-icon icon="upload" /></button>
              </div>
              <div class="custom-file">
                <input id="documento_certificado3" type="file" class="form-control" aria-describedby="file0" accept="application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/msword, application/vnd.ms-excel, application/pdf, image/*" />
              </div>
            </div>
          </div>
          <div class="form-group">
            <label class="font-weight-bold">Fumigation certificate</label>
            <br />
            <div v-if="!isEditCertificado4" class="input-group mb-3">
              <a v-if="documento_certificado4" ref="#" @click="downloadFile(documento_certificado4)" class="btn btn-secondary text-white mr-2">
                <font-awesome-icon icon="eye" /> 
              </a>
              <p v-else class="lead mr-2">No Diligenciado</p>
              <button @click="isEditCertificado4 = true" class="btn btn-primary text-white">
                <font-awesome-icon icon="edit" />
              </button>
            </div>
            <div v-else class="input-group mb-3">
              <div class="input-group-prepend" id="file0">
                <button @click="isEditCertificado4 = false" class="btn btn-danger" type="button"><font-awesome-icon icon="times" /></button>
                <button @click="uploadFile('documento_certificado4', 'isEditCertificado4')" class="btn btn-primary" type="button"><font-awesome-icon icon="upload" /></button>
              </div>
              <div class="custom-file">
                <input id="documento_certificado4" type="file" class="form-control" aria-describedby="file0" accept="application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/msword, application/vnd.ms-excel, application/pdf, image/*" />
              </div>
            </div>
          </div>
          <div class="form-group">
            <label class="font-weight-bold">Veterinaty health certificate</label>
            <br />
            <div v-if="!isEditCertificado5" class="input-group mb-3">
              <a v-if="documento_certificado5" ref="#" @click="downloadFile(documento_certificado5)" class="btn btn-secondary text-white mr-2">
                <font-awesome-icon icon="eye" /> 
              </a>
              <p v-else class="lead mr-2">No Diligenciado</p>
              <button @click="isEditCertificado5 = true" class="btn btn-primary text-white">
                <font-awesome-icon icon="edit" />
              </button>
            </div>
            <div v-else class="input-group mb-3">
              <div class="input-group-prepend" id="file0">
                <button @click="isEditCertificado5 = false" class="btn btn-danger" type="button"><font-awesome-icon icon="times" /></button>
                <button @click="uploadFile('documento_certificado5', 'isEditCertificado5')" class="btn btn-primary" type="button"><font-awesome-icon icon="upload" /></button>
              </div>
              <div class="custom-file">
                <input id="documento_certificado5" type="file" class="form-control" aria-describedby="file0" accept="application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/msword, application/vnd.ms-excel, application/pdf, image/*" />
              </div>
            </div>
          </div>
          <div class="form-group">
            <label class="font-weight-bold">Phitosanitary Health Certificate</label>
            <br />
            <div v-if="!isEditCertificado6" class="input-group mb-3">
              <a v-if="documento_certificado6" ref="#" @click="downloadFile(documento_certificado6)" class="btn btn-secondary text-white mr-2">
                <font-awesome-icon icon="eye" /> 
              </a>
              <p v-else class="lead mr-2">No Diligenciado</p>
              <button @click="isEditCertificado6 = true" class="btn btn-primary text-white">
                <font-awesome-icon icon="edit" />
              </button>
            </div>
            <div v-else class="input-group mb-3">
              <div class="input-group-prepend" id="file0">
                <button @click="isEditCertificado6 = false" class="btn btn-danger" type="button"><font-awesome-icon icon="times" /></button>
                <button @click="uploadFile('documento_certificado6', 'isEditCertificado6')" class="btn btn-primary" type="button"><font-awesome-icon icon="upload" /></button>
              </div>
              <div class="custom-file">
                <input id="documento_certificado6" type="file" class="form-control" aria-describedby="file0" accept="application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/msword, application/vnd.ms-excel, application/pdf, image/*" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="divSt4">
      <div class="row">
        <div class="col-md-12">
        </div>
      </div> 
    </div>
    <div id="divSt5">
      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label class="font-weight-bold">Fecha de Entregada</label>
            <div v-if="!isEditEntregada" class="input-group mb-3">
              <p v-if="fecha_entregada" class="lead mr-2">{{ fecha_entregada }}</p>
              <p v-else class="lead mr-2">No Diligenciado</p>
              <button @click="isEditEntregada = true" class="btn btn-primary text-white">
                <font-awesome-icon icon="edit" />
              </button>
            </div>
            <div v-else class="input-group mb-3">
              <div class="input-group-prepend">
                <button @click="isEditEntregada = false" class="btn btn-danger" type="button"><font-awesome-icon icon="times" /></button>
              </div>
              <input id="fecha_entregada" :value="fecha_entregada" type="date" class="form-control" />
              <div class="input-group-append">
                <button @click="editItem('fecha_entregada', 'isEditEntregada')" class="btn btn-primary" type="button"><font-awesome-icon icon="save" /></button>
              </div>
            </div>
          </div>
        </div>
      </div>  
    </div>  
    <div id="divSt6">
      <div class="row">
        <div v-for="(row, index) in observaciones" :key="index" class="col-md-6">
          <div class="form-group">
            <label class="font-weight-bold">Observación {{ status[index] }}</label>
            <div v-if="!isEditObservaciones[index]" class="input-group mb-3">
              <p v-if="row" class="lead mr-2">{{ row }}</p>
              <p v-else class="lead mr-2">No Diligenciada</p>
              <button @click="editStatus(index,true)" class="btn btn-primary text-white">
                <font-awesome-icon icon="edit" />
              </button>
            </div>
            <div v-else class="input-group mb-3">
              <div class="input-group-prepend">
                <button @click="editStatus(index,false)" class="btn btn-danger" type="button"><font-awesome-icon icon="times" /></button>
              </div>
              <input :id="'observaciones' + index" :value="row" type="text" class="form-control" />
              <div class="input-group-append">
                <button @click="editItem('observaciones' + index, 'isEditObservaciones[' + index + ']')" class="btn btn-primary" type="button"><font-awesome-icon icon="save" /></button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "orderdetailin",
  props: {
    dataOrder: Object,
  },
  data() {
    return {
      //object
      id: null,
      consecutivo: null,
      proveedor: null,
      bodega: null,
      fecha_estimada: null,
      detalle: [],
      cantidad: null,
      documento_orden: null,
      numero_carga: null,
      empresa: null,
      documento_factura: null,
      documento_pl: null,
      documento_bl: null,
      documento_mds: null,
      documento_bse: null,
      documento_certificado1: null,
      documento_certificado2: null,
      documento_certificado3: null,
      documento_certificado4: null,
      documento_certificado5: null,
      documento_certificado6: null,
      fecha_entregada: null,
      estado: null,
      observaciones: null,
      //aux
      isEditOrden: false,
      isEditNumero: false,
      isEditEmpresa: false,
      isEditFactura: false,
      isEditBl: false,
      isEditPl: false,
      isEditMds: false,
      isEditBse: false,
      isEditCertificado1: false,
      isEditCertificado2: false,
      isEditCertificado3: false,
      isEditCertificado4: false,
      isEditCertificado5: false,
      isEditCertificado6: false,
      isEditEntregada: false,
      isEditObservaciones: [
        false,
        false,
        false,
        false,
        false,
        false
      ],
      status: [
        "Generada",
        "Compra",
        "Embarcada",
        "Aduana",
        "Tránsito",
        "Traslado",
        "Entregada"
      ]
    };
  },
  watch: {
    dataOrder: function(value) {
      this.$data.id = value.id;
      this.$data.consecutivo = value.consecutivo;
      this.$data.proveedor = value.proveedor;
      this.$data.bodega = value.bodega;
      if(value.fecha_estimada) {
        this.$data.fecha_estimada = value.fecha_estimada.substr(0,10);
      }
      this.$data.cantidad = value.cantidad;
      this.$data.documento_orden = value.documento_orden;
      this.$data.numero_carga = value.numero_carga;
      this.$data.empresa = value.empresa;
      this.$data.documento_factura = value.documento_factura;
      this.$data.documento_pl = value.documento_pl;
      this.$data.documento_bl = value.documento_bl;
      this.$data.documento_mds = value.documento_mds;
      this.$data.documento_bse = value.documento_bse;
      this.$data.documento_certificado1 = value.documento_certificado1;
      this.$data.documento_certificado2 = value.documento_certificado2;
      this.$data.documento_certificado3 = value.documento_certificado3;
      this.$data.documento_certificado4 = value.documento_certificado4;
      this.$data.documento_certificado5 = value.documento_certificado5;
      this.$data.documento_certificado6 = value.documento_certificado6;
      if(value.fecha_entregada) {
        this.$data.fecha_entregada = value.fecha_entregada.substr(0,10);
      }
      this.$data.estado = value.estado;
      this.$data.observaciones = value.observaciones;
      this.$data.detalle = value.detalle;
    }
  },
  methods: {
    downloadFile: function(filename) {
      this.$emit("downloadFile", filename)
    },
    uploadFile: function(item, itemEdit) {
      let doc = document.getElementById(item);
      if(doc.files.length > 0) {        
        let data  = {}; 
        let formData = new FormData();
        formData.append(item, doc.files[0]);  
        data.files = formData;
        this[itemEdit] = false;
        let filename = doc.files[0].name;
        this.$data[item] = "in-" + this.id + "-" + item + "." + filename.substring(filename.lastIndexOf('.')+1);
        this.$emit("uploadOrderItem", data);
      }else{
        this.showAlert("Escoge el archivo a subir");
      }
    },
    editStatus: function(idx, val) {
      this.isEditObservaciones = this.isEditObservaciones.map((observacion, index) => {
        if(index === idx){
          observacion = val;
        }
        return observacion;
      });
    },
    editItem: function(item, itemEdit) {
      let value = document.getElementById(item).value;
      if(value !== "") {
        let data  = {};
        data[item] = value;
        if(item.includes("observaciones")){ 
          this.observaciones = this.observaciones.map((observacion, index) => {
            if(index == item.slice(-1)){
              observacion = value;
            }
            return observacion;
          });
          this.isEditObservaciones = this.isEditObservaciones.map((observacion, index) => {
            if(index == item.slice(-1)){
              observacion = false;
            }
            return observacion;
          });
        }else{
          this[item] = value;
          this[itemEdit] = false;
        }
        this.$emit("editOrderItem", data);
      }else{
        this.showAlert("Termina de escribir el dato");
      }
    },
    showAlert: function(message) {
      this.$swal({
        position: "top-end",
        icon: "error",
        title: message,
        showConfirmButton: false,
        timer: 1500
      });
    }
  }
};
</script>
