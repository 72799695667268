<template>
  <table class="table table-bordered table1">
    <thead>
      <tr>
        <th>Consecutivo</th>
        <th v-if="status === 0 || status === 3 || status === 4">Certificado de Ánalisis</th>
        <th v-if="status === 0 || status === 3 || status === 4">Orden de Compra</th>
        <th v-if="status === 1">Fecha Estimada</th>
        <th v-if="status === 1">Tracking Number</th>
        <th v-if="status === 1">Empresa Transportadora</th>
        <th v-if="status === 2 || status === 3 || status === 4">Fecha Entregada</th>
        <th v-if="status === 2 || status === 3 || status === 4">Factura</th>
        <th>Observaciones</th>
        <th>Acciones</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="row in dataOrder" :key="row.id">
        <td>{{ row.id.toString().padStart(4, "0") }}</td>
        <td v-if="status === 0 || status === 3 || status === 4">{{ !row.documento_certificado ? "No diligenciado" : "Diligenciado" }}</td>
        <td v-if="status === 0 || status === 3 || status === 4">{{ !row.documento_orden ? "No diligenciado" : "Diligenciado" }}</td>
        <td v-if="status === 1">{{ !row.fecha_estimada ? "No diligenciado" : row.fecha_estimada.substr(0,10) }}</td>
        <td v-if="status === 1">{{ !row.numero_tracking ? "No diligenciado" : row.numero_tracking }}</td>
        <td v-if="status === 1">{{ !row.empresa ? "No diligenciado" : row.empresa }}</td>
        <td v-if="status === 2 || status === 3 || status === 4">{{ !row.fecha_entregada ? "No diligenciado" : row.fecha_entregada.substr(0,10) }}</td>
        <td v-if="status === 2 || status === 3 || status === 4">{{ !row.id_factura ? "No diligenciado" : row.id_factura.toString().padStart(4, "0") }}</td>
        <td>{{ !row.observaciones ? "" : row.observaciones[status] }}</td>
        <td>
          <router-link class="btn btn-primary" :to="{ path: '/orden/salida/detail/' + row.id }">
            <font-awesome-icon icon="eye" /> Detalle
          </router-link>&nbsp;
          <router-link v-show="userType === 'Admin' || userType === 'Full Access' || userType === 'Comercial' || userType === 'Editor'" v-if="status !== 3 && status !== 4" class="btn btn-primary" :to="{ path: '/orden/salida/edit/' + row.id }">
            <font-awesome-icon icon="edit" /> Editar
          </router-link>&nbsp;
          <button v-show="userType === 'Admin' || userModules.includes('Salidas')" v-if="status !== 3 && status !== 4" class="btn btn-info" @click="statusOrder(row.id, true, false)">
            <div v-if="status === 0"><font-awesome-icon icon="clipboard-check" /> Pasar a Confirmada</div>
            <div v-if="status === 1"><font-awesome-icon icon="truck-loading" />  Pasar a Entregada</div>
            <div v-if="status === 2"><font-awesome-icon icon="truck-loading" />  Pasar a Facturada</div>
          </button>&nbsp;
          <button v-show="userType === 'Admin' || userModules.includes('Salidas')" v-if="status !== 0 && status !== 3 && status !== 4" class="btn btn-secondary" @click="statusOrder(row.id, false, false)">
            <div v-if="status === 1"><font-awesome-icon icon="clipboard-check" /> Devolver a Documentada</div>
            <div v-if="status === 2"><font-awesome-icon icon="truck-loading" />  Devolver a Confirmada</div>
          </button>&nbsp;
          <button v-show="userType === 'Admin' || userType === 'Full Access' || userType === 'Comercial'" v-if="status !== 3 && status !== 4" class="btn btn-danger" @click="deleteOrder(row.id)">
            <font-awesome-icon icon="trash-alt" /> Eliminar
          </button>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
const $ = require("jquery");
$.DataTable = require("datatables.net");
import "datatables.net-bs4/css/dataTables.bootstrap4.min.css";
import "datatables.net-bs4/js/dataTables.bootstrap4.min.js";
import "datatables.net-buttons/js/dataTables.buttons.min.js";
import "datatables.net-buttons/js/buttons.html5.min.js";
import "datatables.net-buttons/js/buttons.flash.min.js";
import "datatables.net-buttons/js/buttons.print.min.js";
import "datatables.net-buttons-bs4/css/buttons.bootstrap4.min.css";
import "datatables.net-buttons-bs4/js/buttons.bootstrap4.min.js";
window.JSZip = require( 'jszip' );
require( 'pdfmake' );

export default {
  name: "orderlistout",
  props: {
    status: Number,
    dataOrder: Array
  },
  data() {
    return {
      userType: "Admin",
      userModules: [],
    };
  },
  mounted: function() {
    this.$data.userType = localStorage.getItem("userType");
    this.$data.userModules = localStorage.getItem("userModules");
  },
  updated: function () {
    this.$nextTick(function () {
      $(".table1").DataTable({
        destroy     : true,
        paging      : true,
        lengthChange: true,
        searching   : true,
        ordering    : true,
        info        : true,
        autoWidth   : false,
        retrieve    : true,
        dom: 'Blfrtip', 
        buttons: [ 
          { 
            extend: 'excel', className: 'btn btn-secondary'
          },
        ],
        language: {
          "url": "//cdn.datatables.net/plug-ins/9dcbecd42ad/i18n/Spanish.json"
        },
      });
    })
  },
  methods: {
    deleteOrder: function(id) {
      let that = this;
      this.$swal({
        title: "Quieres Eliminar?",
        text: "Está seguro de querer eliminar esto",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si",
        cancelButtonText: "No"
      }).then(result => {
        if (result.value) {
          that.$emit("deleteOrder", id, that.status, "salida");
        }
      });
    },
    statusOrder: function(id, isNext, isCancel) {
      let that = this;
      this.$swal({
        title: "Quieres Confirmar?",
        text: "Está seguro de querer cambiar el estado de la orden",
        icon: "info",
        showCancelButton: true,
        confirmButtonText: "Si",
        cancelButtonText: "No"
      }).then(result => {
        if (result.value) {
          if (isCancel) {
            that.$emit("statusOrder", id, 4, that.status, "salida");
          }else{
            if(isNext){
              that.$emit("statusOrder", id, that.status + 1, that.status, "salida");
            }else{
              that.$emit("statusOrder", id, that.status - 1, that.status, "salida");
            }
          }
        }
      });
    }
  }
};
</script>
