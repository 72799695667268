<template>
  <AppLayout>
    <h2 class="mb-4">
      Listado de Proveedores
      <router-link
        v-if="(userType === 'Admin' || userModules.includes(moduleProvider)) && userType !== 'Invitado'"
        class="btn btn-primary float-right"
        :to="{ path: '/' + nodeProvider + '/register' }"
      >
        <font-awesome-icon icon="plus" /> Registrar
      </router-link>
    </h2>
    <div class="card mb-4">
      <div class="card-body">
        <CommonList
          :userType="userType" 
          :moduleCommon="moduleProvider" 
          :dataCommon="dataProvider"
          :nodeCommon="nodeProvider"
          @deleteCommon="deleteProvider"
        ></CommonList>
      </div>
    </div>
  </AppLayout>
</template>

<script>
import api from "@/api.js"
import AppLayout from "@/components/layout/AppLayout.vue"
import CommonList from "@/components/common/CommonList.vue"
export default {
  name: "providerlist",
  components: {
    AppLayout,
    CommonList
  },
  data() {
    return {
      dataProvider: [],
      nodeProvider: "proveedor",
      moduleProvider: "Proveedores",
      userType: "Admin",
      userModules: []
    };
  },
  created: function() {
    this.getProviders();
    this.$data.userType = localStorage.getItem("userType");
    this.$data.userModules = localStorage.getItem("userModules");
  },
  methods: {
    getProviders: function() {
      let that = this;
      let loader = this.$loading.show();
      this.dataProvider.length = 0;
      api
        .getProviders()
        .then((data) => {
          that.dataProvider = data.providers;
          loader.hide();
        })
        .catch((error) => {
          that.showAlert(error.response.data.message);
          loader.hide();
          if(error.response.status === 401){
            localStorage.removeItem("userToken");
            that.$router.push('/login');
          }
        });
    },
    deleteProvider: function(id) {
      let that = this;
      let loader = this.$loading.show();
      api
        .deleteProvider(id)
        .then(() => {
          that.dataProvider = that.dataProvider.filter(provider => provider.id != id);
          loader.hide();
        })
        .catch((error) => {
          that.showAlert(error.response.data.message);
          loader.hide();
          if(error.response.status === 401){
            localStorage.removeItem("userToken");
            that.$router.push('/login');
          }
        });
    },
    showAlert: function(message) {
      this.$swal({
        position: "top-end",
        icon: "error",
        title: message,
        showConfirmButton: false,
        timer: 1500
      });
    }
  }
};
</script>
