<template>
  <AppLayout>
    <h2 class="mb-4">Registro de Sucursal</h2>
    <div class="card mb-4">
      <div class="card-body">
        <CommonForm
          :nodeCommon="nodeSubsidiary"
          @registerCommon="registerSubsidiary"
        ></CommonForm>
      </div>
    </div>
  </AppLayout>
</template>

<script>
import api from "@/api.js"
import AppLayout from "@/components/layout/AppLayout.vue";
import CommonForm from "@/components/common/CommonForm.vue";
export default {
  name: "subsidiaryregister",
  components: {
    AppLayout,
    CommonForm
  },
  data() {
    return {
      nodeSubsidiary: "sucursal",
    };
  },
  methods: {
    registerSubsidiary: function(dataSubsidiary) {
      let that = this;
      let loader = this.$loading.show();
      api
        .registerSubsidiary(dataSubsidiary)
        .then(() => {
          loader.hide();
          that.$router.push('/' + that.nodeSubsidiary);
        })
        .catch((error) => {
          that.showAlert(error.response.data.message);
          loader.hide();
          if(error.response.status === 401){
            localStorage.removeItem("userToken");
            that.$router.push('/login');
          }
        });
    },
    showAlert: function(message) {
      this.$swal({
        position: "top-end",
        icon: "error",
        title: message,
        showConfirmButton: false,
        timer: 1500
      });
    }
  }
};
</script>
