<template>
  <AppLayout>
    <h2 class="mb-4">Registro de Usuario</h2>
    <div class="card mb-4">
      <div class="card-body">
        <form @submit.prevent="registerUser">
          <div class="form-group">
            <label>Nombre</label>
            <input v-model="nombre" type="text" class="form-control" />
          </div>
          <div class="form-group">
            <label>Apellido</label>
            <input v-model="apellido" type="text" class="form-control" />
          </div>
          <div class="form-group">
            <label>Email</label>
            <input v-model="email" type="text" class="form-control" />
          </div>
          <div class="form-group">
            <label>Contraseña</label>
            <input v-model="password" type="text" class="form-control" />
          </div>
          <div class="form-group">
            <label>Tipo</label>
            <select v-model="id_tipo" class="form-control">
              <option v-for="row in dataUsersType" :key="row.id" :value="row.id">{{ row.nombre }}</option>
            </select>
          </div>
          <div v-show="id_tipo != 1" class="form-group">
            <label>Módulos a Trabajar</label>
            <div class="form-check" v-for="row in dataModules" :key="row.id">
              <input class="form-check-input" type="checkbox" name="modulos" :value="row.id">
              <label class="form-check-label">
                {{ row.nombre}}
              </label>
            </div>
          </div>
          <button class="btn btn-primary" type="submit">
            <font-awesome-icon icon="save" /> Registrar
          </button>
        </form>
      </div>
    </div>
  </AppLayout>
</template>

<script>
import api from "@/api.js"
import AppLayout from "@/components/layout/AppLayout.vue";
export default {
  name: "userregister",
  components: {
    AppLayout
  },
  data() {
    return {
      nodeUser: "usuario",
      nombre: "",
      apellido: "",
      email: "",
      password: "",
      id_tipo: null,
      modulos: [],
      dataUsersType: [],
      dataModules: []
    };
  },
  mounted: function(){
    this.getUsersType();
    this.getModules();
  },
  methods: {
    registerUser: function() {
      let that = this;
      document.getElementsByName("modulos").forEach(modulo => {
        if(modulo.checked){
          that.modulos.push(modulo.value)
        }
      });
      let loader = this.$loading.show();
      api
        .registerUser(that.$data)
        .then(() => {
          loader.hide();
          that.$router.push('/' + that.nodeUser)
        })
        .catch((error) => {
          that.showAlert(error.response.data.message);
          loader.hide();
          if(error.response.status === 401){
            localStorage.removeItem("userToken");
            that.$router.push('/login');
          }
        });
    },
    getUsersType: function() {
      let that = this;
      let loader = this.$loading.show();
      this.dataUsersType.length = 0;
      api
        .getUsersType()
        .then((data) => {
          that.dataUsersType = data.userstype;
          loader.hide();
        })
        .catch((error) => {
          that.showAlert("error", error.response.data.message);
          loader.hide();
          if(error.response.status === 401){
            localStorage.removeItem("userToken");
            that.$router.push('/login');
          }
        });
    },
    getModules: function() {
      let that = this;
      let loader = this.$loading.show();
      this.dataModules.length = 0;
      api
        .getModules()
        .then((data) => {
          that.dataModules = data.modules;
          loader.hide();
        })
        .catch((error) => {
          that.showAlert("error", error.response.data.message);
          loader.hide();
          if(error.response.status === 401){
            localStorage.removeItem("userToken");
            that.$router.push('/login');
          }
        });
    },
    showAlert: function(message) {
      this.$swal({
        position: "top-end",
        icon: "error",
        title: message,
        showConfirmButton: false,
        timer: 1500
      });
    }
  }
};
</script>
