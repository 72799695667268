<template>
  <AppLayout>
    <h2 class="mb-4">Detalle de Orden de {{ type.charAt(0).toUpperCase() + type.slice(1) }}</h2>
    <div class="card mb-4">
      <div class="card-body">
        <OrderDetailIn v-if="type === 'entrada'" :dataOrder="dataOrder" @editOrderItem="editOrderItem" @uploadOrderItem="uploadOrderItem" @downloadFile="downloadFile"></OrderDetailIn>
        <OrderDetailOut v-else-if="type === 'salida'" :dataOrder="dataOrder" @editOrderItem="editOrderItem" @uploadOrderItem="uploadOrderItem" @downloadFile="downloadFile"></OrderDetailOut>
        <OrderDetailWarehouse v-else-if="type === 'bodega'" :dataOrder="dataOrder" @editOrderItem="editOrderItem" @uploadOrderItem="uploadOrderItem" @downloadFile="downloadFile"></OrderDetailWarehouse>
        <OrderDetailProduct v-else :dataOrder="dataOrder"></OrderDetailProduct>
      </div>
    </div>
  </AppLayout>
</template>

<script>
import api from "@/api.js";
import AppLayout from "@/components/layout/AppLayout.vue";
import OrderDetailIn from "@/components/order/OrderDetailIn.vue";
import OrderDetailOut from "@/components/order/OrderDetailOut.vue";
import OrderDetailWarehouse from "@/components/order/OrderDetailWarehouse.vue";
import OrderDetailProduct from "@/components/order/OrderDetailProduct.vue";
export default {
  name: "orderdetail",
  components: {
    AppLayout,
    OrderDetailIn,
    OrderDetailOut,
    OrderDetailWarehouse,
    OrderDetailProduct
  },
  data() {
    return {
      type: "",
      id: "",
      dataOrder: {}
    };
  },
  created: function() {
    this.type = this.$route.params.type;
    this.id = this.$route.params.id;
    this.getOrder();
  },
  methods: {
    getOrder: function() {
      let that = this;
      let loader = this.$loading.show();
      this.dataOrder.length = 0;
      if(that.type === "entrada") {
        api
          .getOrderIn(that.id)
          .then(data => {
            that.dataOrder = data.order;
            loader.hide();
          })
          .catch((error) => {
            that.showAlert("error", error.response.data.message);
            loader.hide();
            if(error.response.status === 401){
              localStorage.removeItem("userToken");
              that.$router.push('/login');
            }
          });
      }else if(that.type === "salida") {
        api
          .getOrderOut(that.id)
          .then(data => {
            that.dataOrder = data.order;
            loader.hide();
          })
          .catch((error) => {
            that.showAlert("error", error.response.data.message);
            loader.hide();
            if(error.response.status === 401){
              localStorage.removeItem("userToken");
              that.$router.push('/login');
            }
          });
      }else if(that.type === "bodega") {
        api
          .getOrderWarehouse(that.id)
          .then(data => {
            that.dataOrder = data.order;
            loader.hide();
          })
          .catch((error) => {
            that.showAlert("error", error.response.data.message);
            loader.hide();
            if(error.response.status === 401){
              localStorage.removeItem("userToken");
              that.$router.push('/login');
            }
          });
      }else{
        api
          .getOrderProduct(that.id)
          .then(data => {
            that.dataOrder = data.order;
            loader.hide();
          })
          .catch((error) => {
            that.showAlert("error", error.response.data.message);
            loader.hide();
            if(error.response.status === 401){
              localStorage.removeItem("userToken");
              that.$router.push('/login');
            }
          });
      }
    },
    editOrderItem: function(data) {
      let that = this;
      let loader = this.$loading.show();
      if(that.type === "entrada") {
        api
          .editOrderItemIn(that.id, data)
          .then(() => {
            that.showAlert("success", "Item editado");
            loader.hide();
          })
          .catch((error) => {
            that.showAlert("error", error.response.data.message);
            loader.hide();
            if(error.response.status === 401){
              localStorage.removeItem("userToken");
              that.$router.push('/login');
            }
          });
      }else if(that.type === "salida") {
        api
          .editOrderItemOut(that.id, data)
          .then(() => {
            that.showAlert("success", "Item editado");
            loader.hide();
          })
          .catch((error) => {
            that.showAlert("error", error.response.data.message);
            loader.hide();
            if(error.response.status === 401){
              localStorage.removeItem("userToken");
              that.$router.push('/login');
            }
          });
      }else{
        api
          .editOrderItemWarehouse(that.id, data)
          .then(() => {
            that.showAlert("success", "Item editado");
            loader.hide();
          })
          .catch((error) => {
            that.showAlert("error", error.response.data.message);
            loader.hide();
            if(error.response.status === 401){
              localStorage.removeItem("userToken");
              that.$router.push('/login');
            }
          });
      }
    },
    uploadOrderItem: function(data) {
      let that = this;
      let loader = this.$loading.show();
      let type;
      if(this.type === "entrada") {
        type = "in";
      }else if(this.type === "salida") {
        type = "out";
      }else if(this.type === "bodega") {
        type = "warehouse";
      }else{
        type = "product";
      }
      api
        .uploadFiles(type, that.id, data.files)
        .then(() => {
          that.showAlert("success", "Item editado");
          loader.hide();
        })
        .catch((error) => {
          that.showAlert("error", error.response.data.message);
          loader.hide();
          if(error.response.status === 401){
            localStorage.removeItem("userToken");
            that.$router.push('/login');
          }
        });
    },
    downloadFile: function(filename) {
      let that = this;
      let loader = this.$loading.show();
      let type;
      if(this.type === "entrada") {
        type = "in";
      }else if(this.type === "salida") {
        type = "out";
      }else{
        type = "warehouse";
      }
      api
        .downloadFile(type, this.id, filename)
        .then(data => {
          loader.hide();
          window.open(data.urlFile, '_blank')
        })
        .catch((error) => {
          that.showAlert("error", error.response.data.message);
          loader.hide();
          if(error.response.status === 401){
            localStorage.removeItem("userToken");
            that.$router.push('/login');
          }
        });
    },
    showAlert: function(type, message) {
      this.$swal({
        position: "top-end",
        icon: type,
        title: message,
        showConfirmButton: false,
        timer: 1500
      });
    }
  }
};
</script>
