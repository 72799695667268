<template>
  <table id="table2" class="table table-bordered">
    <thead>
      <tr>
        <th>Consecutivo</th>
        <th>Bodega Saliente</th>
        <th>Bodega Entrante</th>
        <th>Estado</th>
        <th>Acciones</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="row in dataOrder" :key="row.id">
        <td>{{ row.id.toString().padStart(4, "0") }}</td>
        <td>{{ row.bodega_saliente === "" ? "No diligenciado" : row.bodega_saliente }}</td>
        <td>{{ row.bodega_entrante === "" ? "No diligenciado" : row.bodega_entrante }}</td>
        <td>{{ row.estado }}</td>
        <td>
          <router-link class="btn btn-primary" :to="{ path: '/orden/bodega/detail/' + row.id }">
            <font-awesome-icon icon="eye" /> Detalle
          </router-link>&nbsp;
          <router-link v-show="userType === 'Admin' || userModules.includes('Traslado')" v-if=" row.estado !== '3'" class="btn btn-primary" :to="{ path: '/orden/bodega/edit/' + row.id }">
            <font-awesome-icon icon="edit" /> Editar
          </router-link>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
const $ = require("jquery");
$.DataTable = require("datatables.net");
import "datatables.net-bs4/css/dataTables.bootstrap4.min.css";
import "datatables.net-bs4/js/dataTables.bootstrap4.min.js";
import "datatables.net-buttons/js/dataTables.buttons.min.js";
import "datatables.net-buttons/js/buttons.html5.min.js";
import "datatables.net-buttons/js/buttons.flash.min.js";
import "datatables.net-buttons/js/buttons.print.min.js";
import "datatables.net-buttons-bs4/css/buttons.bootstrap4.min.css";
import "datatables.net-buttons-bs4/js/buttons.bootstrap4.min.js";
window.JSZip = require( 'jszip' );
require( 'pdfmake' );

export default {
  name: "orderlistgeneralwarehouse",
  props: {
    dataOrder: Array
  },
  data() {
    return {
      statusWarehouse: ["Solicitud de traslado", "Confirmada", "Recibida", "Cancelada"],
      userType: "Admin",
      userModules: [],
    };
  },
  mounted: function() {
    this.$data.userType = localStorage.getItem("userType");
    this.$data.userModules = localStorage.getItem("userModules");
  },
  watch: {
    dataOrder: function(value) {
      let that = this;  
      value.forEach((order, index) => {
        that.dataOrder[index].estado = that.statusWarehouse[Number(order.estado)];
      });
    }
  },
  updated: function () {
    $("#table2").DataTable({ 
      retrieve: true,
      dom: 'Blfrtip', 
      buttons: [ 
        { 
          extend: 'excel', className: 'btn btn-secondary'
        },
      ],
      language: {
        "url": "//cdn.datatables.net/plug-ins/9dcbecd42ad/i18n/Spanish.json"
      },
    });
  },
};
</script>
