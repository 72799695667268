<template>
  <AppLayout>
    <h2 class="mb-4">Editar Cliente</h2>
    <div class="card mb-4">
      <div class="card-body">
        <CommonForm
          :nodeCommon="nodeClient"
          :dataCommon="dataClient"
          @registerCommon="editClient"
        ></CommonForm>
      </div>
    </div>
  </AppLayout>
</template>

<script>
import api from "@/api.js"
import AppLayout from "@/components/layout/AppLayout.vue"
import CommonForm from "@/components/common/CommonForm.vue"
export default {
  name: "clientedit",
  components: {
    AppLayout,
    CommonForm
  },
  data() {
    return {
      id: "",
      nodeClient: "cliente",
      dataClient: {}
    };
  },
  mounted() {
    this.id = this.$route.params.id;
    this.getClient(this.id);
  },
  methods: {
    getClient: function(id) {
      let that = this;
      let loader = this.$loading.show();
      api
        .getClient(id)
        .then((data) => {
          that.dataClient = data.client;
          loader.hide();
        })
        .catch((error) => {
          that.showAlert(error.response.data.message);
          loader.hide();
          if(error.response.status === 401){
            localStorage.removeItem("userToken");
            that.$router.push('/login');
          }
        });
    },
    editClient: function(dataClient) {
      let that = this;
      let loader = this.$loading.show();
      api
        .editClient(that.id, dataClient)
        .then(() => {
          loader.hide();
          that.$router.push('/' + that.nodeClient);
        })
        .catch((error) => {
          that.showAlert(error.response.data.message);
          loader.hide();
          if(error.response.status === 401){
            localStorage.removeItem("userToken");
            that.$router.push('/login');
          }
        });      
    },
    showAlert: function(message) {
      this.$swal({
        position: "top-end",
        icon: "error",
        title: message,
        showConfirmButton: false,
        timer: 1500
      });
    }
  }
};
</script>
