<template>
  <table id="table" class="table table-bordered mt-3">
    <thead>
      <tr>
        <th>Nombre</th>
        <th v-if="nodeCommon !== 'producto'">Teléfono</th>
        <th v-else>Código</th>
        <th v-if="nodeCommon !== 'producto'">Dirección</th>
        <th v-else>Unidades</th>
        <th>Acciones</th>
      </tr>
    </thead>
    <tbody>
      <tr
        v-for="row in dataCommon"
        :key="row.id"
      >
        <td>{{ row.nombre }}</td>
        <td v-if="nodeCommon !== 'producto'">{{ row.telefono }}</td>
        <td v-else>{{ row.codigo }}</td>
        <td v-if="nodeCommon !== 'producto'">{{ row.direccion }}</td>
        <td v-else>{{ row.unidad + " " + row.medida }}</td>
        <td>
          <router-link
            v-if="nodeCommon === 'bodega'"
            class="btn btn-primary"
            :to="{ path: '/' + nodeCommon + '/stock/' + row.id }"
          >
            <font-awesome-icon icon="chart-bar" /> Inventario </router-link
          >&nbsp;
          <router-link
            v-if="userType === 'Admin' || userType === 'Full Access' || userType === 'Comercial' || userType === 'Editor'"
            class="btn btn-primary"
            :to="{ path: '/' + nodeCommon + '/edit/' + row.id }"
          >
            <font-awesome-icon icon="edit" /> Editar </router-link
          >&nbsp;
          <button
            v-if="userType === 'Admin' || userType === 'Full Access' || userType === 'Comercial'"
            class="btn btn-danger"
            @click="deleteCommon(row.id)"
          >
            <font-awesome-icon icon="trash-alt" /> Eliminar
          </button>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
const $ = require("jquery");
$.DataTable = require("datatables.net");
import "datatables.net-bs4/css/dataTables.bootstrap4.min.css";
import "datatables.net-bs4/js/dataTables.bootstrap4.min.js";
import "datatables.net-buttons/js/dataTables.buttons.min.js";
import "datatables.net-buttons/js/buttons.html5.min.js";
import "datatables.net-buttons/js/buttons.flash.min.js";
import "datatables.net-buttons/js/buttons.print.min.js";
import "datatables.net-buttons-bs4/css/buttons.bootstrap4.min.css";
import "datatables.net-buttons-bs4/js/buttons.bootstrap4.min.js";
window.JSZip = require( 'jszip' );
require( 'pdfmake' );

export default {
  name: "commonlist",
  props: {
    dataCommon: Array,
    nodeCommon: String,
    moduleCommon: String,
    userType: String,
  },
  data() {
    return {
      userModules: []
    };
  },
  mounted: function () {
    this.$data.userModules = localStorage.getItem("userModules")
  },
  updated: function () {
    this.$nextTick(function () {
      $("#table").DataTable({ 
        retrieve: true,
        dom: 'Blfrtip', 
        buttons: [ 
          { 
            extend: 'excel', className: 'btn btn-secondary'
          },
        ],
        language: {
          "url": "//cdn.datatables.net/plug-ins/9dcbecd42ad/i18n/Spanish.json"
        },
      });
    })
  },
  methods: {
    deleteCommon: function (id) {
      let that = this;
      this.$swal({
        title: "Quieres Eliminar?",
        text: "Está seguro de querer eliminar esto",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si",
        cancelButtonText: "No",
      }).then((result) => {
        if (result.value) {
          that.$emit("deleteCommon", id);
        }
      });
    },
  },
};
</script>
