<template>
    <AppLayout>
        <h2 class="mb-4">Campos y Tablas</h2>
        <div class="card mb-4">
          <div class="card-body">
              <div class="row mb-4">
                  <div class="col-md-12">
                      <h4>Registrar Campo</h4>
                      <form @submit.prevent="registerField">
                        <div class="form-group">
                            <label>Tabla <i class="small">*Puede seleccionar uno o más items*</i></label>
                            <select multiple v-model="table" class="form-control">
                                <option v-for="row in tableNames" :key="row">{{ row }}</option>
                            </select>
                        </div>
                        <div class="form-group">
                            <label>Tipo</label>
                            <select v-model="type" class="form-control">
                                <option>text</option>
                                <option>number</option>
                                <option>date</option>
                            </select>
                        </div>
                        <div class="form-group">
                            <label>Es requerido?</label>
                            <br>
                            <label class="radio-inline"><input type="radio" v-model="required" value="false" checked> No</label> <label class="radio-inline"><input type="radio" v-model="required" value="true"> Si</label>
                          </div>
                        <div class="form-group">
                            <label>Nombre</label>
                            <input v-model="name" type="text" class="form-control">
                        </div>
                        <button class="btn btn-primary" type="submit"><font-awesome-icon icon="save" /> Registrar</button>
                      </form>
                  </div>
              </div>
              <div class="row">
                  <div v-for="(row,index) in dataField" :key="index" class="col-md-4">
                      <h4>Tabla {{ tableNames[index] }}</h4>
                      <table class="table table-bordered">
                          <thead>
                              <tr>
                                  <th>Campo</th>
                                  <th>Tipo</th>
                                  <th>Acciones</th>
                              </tr>
                          </thead>
                          <tbody>
                            <tr v-for="row in row" :key="row.id">
                              <td>{{ row.nombre }}</td>
                              <td>{{ row.tipo }}</td>
                              <td>
                                  <button v-show="row.defecto === false" class="btn btn-danger" @click="deleteField(index,row.id)"><font-awesome-icon icon="trash-alt" /> Eliminar</button>
                              </td>
                            </tr>
                          </tbody>
                      </table>
                  </div>
              </div>    
          </div>
        </div> 
    </AppLayout>
</template>

<script>
import api from "@/api.js"
import AppLayout from '@/components/layout/AppLayout.vue'
export default {
  name: 'field',
  components: {
    AppLayout,
  },
  data() {
    return {
        tableNames: ['Bodega','Cliente','Producto','Proveedor','Sucursal'],
        table: [],
        dataField: [],
        nodeField: 'tabla',
        //data
        name: '',
        type: '',
        required: false,
        default: false,
    }
  },
  mounted: function () {
    this.getFields();
  },
  methods: {
    registerField: function () {
      let loader = this.$loading.show();
      if(this.nombre !== '' && this.tipo !== ''){
        const promises = [];
        this.table.forEach(tbl => {
          let nm = this.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/ /g, '_').toLowerCase();
          let field = { id_tabla: this.tableNames.findIndex(table => table === tbl) + 1, nombre: this.name, nombre_campo: nm, tipo: this.type, requerido: this.required, defecto: this.default }
          promises.push(api.registerField(field));
        });
        Promise.all(promises).then(() => {
          this.name = '';
          this.type = [];
          this.table = [];
          loader.hide();
          this.getFields();
        });
      }else{
        this.showAlert('Completa los campos');
      }
    },
    getFields: function ()
    {
      let that = this;
      let loader = this.$loading.show();
      this.dataField.length = 0;
      api
        .getFields(1)
        .then((data) => {
          that.dataField.push(data.fields);
          api
            .getFields(2)
            .then((data) => {
              that.dataField.push(data.fields)
              api
                .getFields(3)
                .then((data) => {
                  that.dataField.push(data.fields);
                  api
                    .getFields(4)
                    .then((data) => {
                      that.dataField.push(data.fields);
                      api
                        .getFields(5)
                        .then((data) => {
                          that.dataField.push(data.fields);
                          loader.hide();
                        })
                    })
                })
            })
        })
        .catch((error) => {
          that.showAlert(error.response.data.message);
          loader.hide();
          if(error.response.status === 401){
            localStorage.removeItem("userToken");
            that.$router.push('/login');
          }
        });
    },
    deleteField: function(table,id) {
      let that = this;
      this.$swal({
        title: 'Quieres Eliminar?',
        text: 'Está seguro de querer eliminar esto',
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Si',
        cancelButtonText: 'No'
      }).then((result) => {
        if (result.value) {
          let loader = this.$loading.show();
          api
            .deleteField(id)
            .then(() => {
              loader.hide();
              that.getFields()
            })
            .catch((error) => {
              that.showAlert(error.response.data.message);
              loader.hide();
              if(error.response.status === 401){
                localStorage.removeItem("userToken");
                that.$router.push('/login');
              }
            });
        }
      });
    },
    showAlert: function(message) {
      this.$swal({
        position: "top-end",
        icon: "error",
        title: message,
        showConfirmButton: false,
        timer: 1500
      });
    }
  }
}
</script>
