<template>
  <form @submit.prevent="registerOrder">
    <div id="divSt0">
      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label>Bodega Saliente</label>
            <select v-if="isEdit" v-model="id_bodega_saliente" @change="onChangeWarehouse($event)" readonly class="form-control">
              <option v-for="row in dataWarehouse" :key="row.id" :value="row.id">{{ row.nombre }}</option>
            </select>
            <select v-else v-model="id_bodega_saliente" @change="onChangeWarehouse($event)" class="form-control">
              <option v-for="row in dataWarehouse" :key="row.id" :value="row.id">{{ row.nombre }}</option>
            </select>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label>Bodega Entrante</label>
            <select v-model="id_bodega_entrante" class="form-control">
              <option v-for="row in dataWarehouse" :key="row.id" :value="row.id">{{ row.nombre }}</option>
            </select>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <p @click="addRow" class="btn btn-secondary float-right">
            <font-awesome-icon icon="plus" /> Agregar Producto
          </p>
          <div class="form-group">
            <table class="table table-hover">
              <thead>
                <tr>
                  <th>Número Lote</th>
                  <th>Fecha Lote</th>
                  <th>Fecha Vencimiento</th>
                  <th>Producto</th>
                  <th>Cantidad</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(row, index) in detalle" :key="row[index]">
                  <td>
                    <select v-model="row.id_lote" @change="onChangeLote($event, index)" class="form-control">
                      <option></option>
                      <option v-for="row2 in dataStock" :key="row2.lote_numero" :value="row2.id_lote">{{ row2.lote_numero }}</option>
                    </select>
                  </td>
                  <td>
                    <input v-model="row.lote_fecha" readonly type="date" class="form-control" />
                  </td>
                  <td>
                    <input v-model="row.lote_vencimiento" readonly type="date" class="form-control" />
                  </td>
                  <td>
                    <input v-model="row.producto" type="text" readonly class="form-control" />
                    <input v-model="row.id_producto" type="hidden" />
                  </td>
                  <td>
                    <input v-model="row.cantidad" type="number" min="1" :max="row.cantidad2" :placeholder="row.cantidad2" class="form-control" />
                  </td>
                  <td>
                    <p @click="deleteRow(index)" class="btn btn-danger">
                      <font-awesome-icon icon="trash" />
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div id="divSt1">
      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label>Fecha Entregada</label>
            <input v-model="fecha_entregada" type="date" class="form-control" />
          </div>
          <div class="form-group">
            <label>Tracking Number</label>
            <input v-model="numero_tracking" type="text" class="form-control" />
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label>Empresa</label>
            <input v-model="empresa" type="text" class="form-control" />
          </div>
        </div>
      </div>
    </div>   
    <div id="divSt2">
      <div class="row">
        <div class="col-md-12">
          <div class="form-group">
            <label>Remisións</label>
            <input id="documento_remision" type="file" accept="application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/msword, application/vnd.ms-excel, application/pdf, image/*" class="form-control" />
          </div>
          <div class="form-group">
            <a v-if="documento_remision" href="#" @click="downloadFile(documento_remision)" class="btn btn-primary"><font-awesome-icon icon="file" /> Ver Documento</a>
          </div>
        </div>
      </div>
    </div>
    <div v-if="isEdit" class="row">
      <div class="col-md-12">
        <div class="form-group">
          <label>Observaciones</label>
          <input v-model="observaciones" type="text" class="form-control" />
        </div>
      </div>
    </div> 
    <button class="btn btn-primary" type="submit">
      <font-awesome-icon icon="save" /> Guardar
    </button>
  </form>
</template>

<script>
import api from "@/api.js";
export default {
  name: "orderformwarehouse",
  props: {
    dataOrder: Object,
  },
  data() {
    return {
      //data
      dataWarehouse: [],
      dataStock: [],
      //object
      id: null,
      id_bodega_saliente: null,
      id_bodega_entrante: null,
      fecha_entregada: null,
      numero_tracking: null,
      empresa: null,
      documento_remision: null,
      estado: 0,
      detalle: [],
      detalle2: [],
      observaciones:[],
      //Aux
      isEdit: false,
    };
  },
  watch: {
    dataOrder: function(value) {
      this.$data.id_bodega_saliente = value.id_bodega_saliente;
      this.$data.id_bodega_entrante = value.id_bodega_entrante;
      if(value.fecha_entregada) {
        this.$data.fecha_entregada = value.fecha_entregada.substr(0,10);
      }
      this.$data.numero_tracking = value.numero_tracking;
      this.$data.empresa = value.empresa;
      this.$data.documento_remision = value.documento_remision;
      this.$data.estado = value.estado;
      this.$data.detalle = value.detalle;
      this.onChangeWarehouse(value.id_bodega_saliente);
      let that = this;
      this.$data.detalle.forEach(function(detalle, index){
        that.$data.detalle[index].id_lote = detalle.id_lote;
        that.$data.detalle[index].producto = detalle.nombre;
        that.$data.detalle[index].lote_fecha = detalle.lote_fecha.substr(0,10);
        that.$data.detalle[index].lote_vencimiento = detalle.lote_vencimiento.substr(0,10);
      });
      this.$data.detalle2 = this.copy(this.$data.detalle);
      this.$data.observaciones = value.observaciones[value.estado];
      this.$data.isEdit = true;
      this.showDivStatus();
    }
  },
  mounted: function(){
    this.showDivStatus();
    this.getWarehouses();
  },
  methods: {
    copy: function(o) {
      var output, v, key;
      output = Array.isArray(o) ? [] : {};
      for (key in o) {
        v = o[key];
        output[key] = (typeof v === "object") ? this.copy(v) : v;
      }
      return output;
    },
    showDivStatus: function() {
      let that = this;
      document.querySelectorAll("form > div").forEach(function(div, index){
        if(that.estado == index) {
          document.getElementById("divSt" + index).style.display = "block";
        }else{
          if(div.className === ""){
            document.getElementById("divSt" + index).style.display = "none";
          }
        }
      });
    },
    onChangeWarehouse(event) {
      let that = this;
      let loader = this.$loading.show();
      this.dataStock.length = 0;
      api
        .getStock(typeof event.target === "undefined" ? event : event.target.value)
        .then((data) => {
          that.dataStock = data.stock;
          loader.hide();
        })
        .catch((error) => {
          that.showAlert("error", error.response.data.message);
          loader.hide();
          if(error.response.status === 401){
            localStorage.removeItem("userToken");
            that.$router.push('/login');
          }
        });
    },
    onChangeLote: function(event, index) {
      let stock = this.dataStock.filter(stock => stock.id_lote == event.target.value)[0];
      this.detalle[index].id_lote = stock.id_lote;
      this.detalle[index].lote_fecha = stock.lote_fecha.substr(0,10);
      this.detalle[index].lote_vencimiento = stock.lote_vencimiento.substr(0,10);
      this.detalle[index].producto = stock.nombre + " | " + stock.codigo;
      this.detalle[index].id_producto = stock.id_producto;
      this.detalle[index].cantidad2 = stock.cantidad;
    },
    addRow: function() {
      this.detalle.push({ id_lote: null, lote_numero: null, lote_fecha: null, lote_vencimiento: null, id_producto: null, cantidad: null });
    },
    deleteRow: function(index) {
      this.detalle.splice(index, 1);
    },
    getWarehouses: function() {
      let that = this;
      let loader = this.$loading.show();
      this.dataWarehouse.length = 0;
      api
        .getWarehouses()
        .then(data => {
          that.dataWarehouse = data.warehouses;
          loader.hide();
        })
        .catch((error) => {
          that.showAlert(error.response.data.message);
          loader.hide();
          if(error.response.status === 401){
            localStorage.removeItem("userToken");
            that.$router.push('/login');
          }
        });
    },
    registerOrder: function() {
      let that = this;
      let dataOrder = this.$data;
      switch(dataOrder.estado){
        case 0: {
          if(that.id_bodega_saliente && that.id_bodega_entrante) {
            if(that.detalle.length > 0) {
              dataOrder.cantidad = that.detalle.length;
              that.emitOrder(dataOrder);
            }else{
              that.showAlert("Completa el detalle");
            }
          }else{
            that.showAlert("Completa los campos");
          }
          break;
        }
        case 1: {
          if(that.fecha_entregada && that.numero_tracking && that.empresa) {
            that.emitOrder(dataOrder);
          }else{
            that.showAlert("Completa los campos");
          }
          break;
        }
        case 2: {
          let doc1 = document.getElementById("documento_remision");
          let formData = new FormData();
          if(doc1.files.length > 0) {
            formData.append('documento_remision', doc1.files[0]);
            dataOrder.files = formData;
            that.emitOrder(dataOrder);
          }else{
            that.showAlert("Completa los campos");
          }
          break;
        }
      }
    },
    downloadFile: function(filename) {
      this.$emit("downloadFile", filename)
    },
    emitOrder: function(dataOrder) {
      delete dataOrder.dataInvoice;
      this.$emit("registerOrder", dataOrder);
    },
    showAlert: function(message) {
      this.$swal({
        position: "top-end",
        icon: "error",
        title: message,
        showConfirmButton: false,
        timer: 1500
      });
    }
  }
};
</script>
