<template>
    <AppLayout>
        <h2 class="mb-4">Medidas</h2>
        <div class="card mb-4">
          <div class="card-body">
              <div class="row">
                  <div class="col-md-4">
                      <h4>Registro</h4>
                      <ListForm @registerList="registerMeasure"></ListForm>
                  </div>
                  <div class="col-md-8">
                      <h4>Listado</h4>
                      <ListList :userType="userType" :dataList="dataMeasure" @deleteList="deleteMeasure"></ListList>
                  </div>
              </div>    
          </div>
        </div> 
    </AppLayout>
</template>

<script>
import api from "@/api.js"
import AppLayout from '@/components/layout/AppLayout.vue'
import ListForm from '@/components/list/ListForm.vue'
import ListList from '@/components/list/ListList.vue'
export default {
  name: 'measure',
  components: {
    AppLayout,
    ListForm,
    ListList,
  },
  data() {
    return {
        dataMeasure: [],
        nodeMeasure: "medida",
        userType: "Admin",
    }
  },
  created: function() {
    this.$data.userType = localStorage.getItem("userType");
  },
  mounted: function () {
    this.getMeasures();
  },
  methods: {
    registerMeasure: function (name) {
      let that = this;
      let loader = this.$loading.show();
      let data = { nombre: name };
      api
        .registerMeasure(data)
        .then(() => {
          loader.hide();
          that.getMeasures();
        })
        .catch((error) => {
          that.showAlert(error.response.data.message);
          loader.hide();
          if(error.response.status === 401){
            localStorage.removeItem("userToken");
            that.$router.push('/login');
          }
        });
    },
    getMeasures: function () {
      let that = this;
      let loader = this.$loading.show();
      this.dataMeasure.length = 0;
      api
        .getMeasures()
        .then((data) => {
          that.dataMeasure = data.measures;
          loader.hide();
        })
        .catch((error) => {
          that.showAlert(error.response.data.message);
          loader.hide();
          if(error.response.status === 401){
            localStorage.removeItem("userToken");
            that.$router.push('/login');
          }
        });
    },
    deleteMeasure: function(id) {
      let that = this;
      let loader = this.$loading.show();
      api
        .deleteMeasure(id)
        .then(() => {
          that.dataMeasure = that.dataMeasure.filter(measure => measure.id != id);
          loader.hide();
        })
        .catch((error) => {
          that.showAlert(error.response.data.message);
          loader.hide();
          if(error.response.status === 401){
            localStorage.removeItem("userToken");
            that.$router.push('/login');
          }
        });
    },
    showAlert: function(message) {
      this.$swal({
        position: "top-end",
        icon: "error",
        title: message,
        showConfirmButton: false,
        timer: 1500
      });
    }
  }
}
</script>
