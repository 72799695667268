<template>
  <form @submit.prevent="registerList">
    <div class="form-group">
      <label>Nombre</label>
      <input v-model="nombre" type="text" class="form-control" />
    </div>
    <button class="btn btn-primary" type="submit">
      <font-awesome-icon icon="save" /> Registrar
    </button>
  </form>
</template>

<script>
export default {
  name: "listform",
  data() {
    return {
      nombre: ""
    };
  },
  methods: {
    registerList: function() {
      if (this.nombre !== "") {
        this.$emit("registerList", this.nombre);
        this.nombre = "";
      } else {
        this.$swal({
          position: "top-end",
         icon: "error",
          title: "Completa los campos",
          showConfirmButton: false,
          timer: 1500
        });
      }
    }
  }
};
</script>
