<template>
  <AppLayout>
    <h2 class="mb-4">
      Listado de Clientes
      <router-link
        v-if="(userType === 'Admin' || userModules.includes(moduleClient)) && userType !== 'Invitado'"
        class="btn btn-primary float-right"
        :to="{ path: '/' + nodeClient + '/register' }"
      >
        <font-awesome-icon icon="plus" /> Registrar
      </router-link>
    </h2>
    <div class="card mb-4">
      <div class="card-body">
        <CommonList :userType="userType" :moduleCommon="moduleClient" :dataCommon="dataClient" :nodeCommon="nodeClient" @deleteCommon="deleteClient"></CommonList>
      </div>
    </div>
  </AppLayout>
</template>

<script>
import api from "@/api.js"
import AppLayout from "@/components/layout/AppLayout.vue"
import CommonList from "@/components/common/CommonList.vue"
export default {
  name: "clientlist",
  components: {
    AppLayout,
    CommonList
  },
  data() {
    return {
      dataClient: [],
      nodeClient: "cliente",
      moduleClient: "Clientes",
      userType: "Admin",
      userModules: []
    };
  },
  created: function() {
    this.getClients();
    this.$data.userType = localStorage.getItem("userType");
    this.$data.userModules = localStorage.getItem("userModules");
  },
  methods: {
    getClients: function() {
      let loader = this.$loading.show();
      let that = this;
      this.dataClient.length = 0;
      api
        .getClients()
        .then((data) => {
          that.dataClient = data.clients;
          loader.hide();
        })
        .catch((error) => {
          that.showAlert(error.response.data.message);
          loader.hide();
          if(error.response.status === 401){
            localStorage.removeItem("userToken");
            that.$router.push('/login');
          }
        });
    },
    deleteClient: function(id) {
      let that = this;
      let loader = this.$loading.show();
      api
        .deleteClient(id)
        .then(() => {
          that.dataClient = that.dataClient.filter(client => client.id != id);
          loader.hide();
        })
        .catch((error) => {
          that.showAlert(error.response.data.message);
          loader.hide();
          if(error.response.status === 401){
            localStorage.removeItem("userToken");
            that.$router.push('/login');
          }
        });
    },
    showAlert: function(message) {
      this.$swal({
        position: "top-end",
        icon: "error",
        title: message,
        showConfirmButton: false,
        timer: 1500
      });
    }
  }
};
</script>
