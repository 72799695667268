<template>
  <form @submit.prevent="registerOrder">
    <div id="divSt0">
      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label>Consecutivo (NUP)</label>
            <input v-model="consecutivo" type="text" class="form-control" />
          </div>
          <div class="form-group">
            <label>Fecha Estimada</label>
            <input v-model="fecha_estimada" type="date" class="form-control" />
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label>Proveedor</label>
            <select v-model="id_proveedor" @change="onChangeProvider($event)" class="form-control">
              <option v-for="(row, index) in dataProvider" :key="index" :value="row.id">{{ row.nombre }}</option>
            </select>
          </div>
          <div class="form-group">
            <label>Bodega</label>
            <select v-model="id_bodega" class="form-control">
              <option v-for="(row, index) in dataWarehouse" :key="index" :value="row.id">{{ row.nombre }}</option>
            </select>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <p @click="addRow" class="btn btn-secondary float-right">
            <font-awesome-icon icon="plus" /> Agregar Producto
          </p>
          <div class="form-group">
            <table class="table table-hover">
              <thead>
                <tr>
                  <th>Producto</th>
                  <th>Cantidad</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(row, index) in detalle" :key="index">
                  <td>
                    <select v-model="row.id_producto" class="form-control">
                      <option v-for="(row2, index2) in dataProduct" :key="index2" :value="row2.id">{{ row2.codigo + " | " + row2.nombre }}</option>
                    </select>
                  </td>
                  <td>
                    <input v-model="row.cantidad" type="number" class="form-control" />
                  </td>
                  <td>
                    <p @click="deleteRow(index)" class="btn btn-danger">
                      <font-awesome-icon icon="trash" />
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div id="divSt1">
      <div class="row">
        <div class="col-md-12">
          <div class="form-group">
            <label>Proforma</label>
            <input id="documento_orden" type="file" accept="application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/msword, application/vnd.ms-excel, application/pdf, image/*" class="form-control" />
          </div>
          <div class="form-group">
            <a v-if="documento_orden" href="#" @click="downloadFile(documento_orden)" class="btn btn-primary"><font-awesome-icon icon="file" /> Ver Documento</a>
          </div>
        </div>
      </div>
    </div>   
    <div id="divSt2">
      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label>B/L Number</label>
            <input v-model="numero_carga" type="text" class="form-control" />
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label>Empresa</label>
            <input v-model="empresa" type="text" class="form-control" />
          </div>
        </div>
      </div>
    </div>
    <div id="divSt3">
      <div class="row">
        <div class="col-md-3">          
          <div class="form-group">
            <label>B/L</label>
            <input id="documento_bl" type="file" accept="application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/msword, application/vnd.ms-excel, application/pdf, image/*" class="form-control" />
          </div>
          <div class="form-group">
            <a v-if="documento_bl" href="#" @click="downloadFile(documento_bl)" class="btn btn-primary"><font-awesome-icon icon="file" /> Ver Documento</a>
          </div>
          <div class="form-group">
            <label>Commercial Invoice</label>
            <input id="documento_factura" type="file" accept="application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/msword, application/vnd.ms-excel, application/pdf, image/*" class="form-control" />
          </div>
          <div class="form-group">
            <a v-if="documento_factura" href="#" @click="downloadFile(documento_factura)" class="btn btn-primary"><font-awesome-icon icon="file" /> Ver Documento</a>
          </div>
          <div class="form-group">
            <label>Packing List</label>
            <input id="documento_pl" type="file" accept="application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/msword, application/vnd.ms-excel, application/pdf, image/*" class="form-control" />
          </div>
          <div class="form-group">
            <a v-if="documento_pl" href="#" @click="downloadFile(documento_pl)" class="btn btn-primary"><font-awesome-icon icon="file" /> Ver Documento</a>
          </div>
        </div>
        <div class="col-md-3">
          <div class="form-group">
            <label>Certificate of analysis origin</label>
            <input id="documento_certificado1" type="file" accept="application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/msword, application/vnd.ms-excel, application/pdf, image/*" class="form-control" />
          </div>
          <div class="form-group">
            <a v-if="documento_certificado1" href="#" @click="downloadFile(documento_certificado1)" class="btn btn-primary"><font-awesome-icon icon="file" /> Ver Documento</a>
          </div>
          <div class="form-group">
            <label>Certificate of analysis for each lot shipped</label>
            <input id="documento_certificado2" type="file" accept="application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/msword, application/vnd.ms-excel, application/pdf, image/*" class="form-control" />
          </div>
          <div class="form-group">
            <a v-if="documento_certificado2" href="#" @click="downloadFile(documento_certificado2)" class="btn btn-primary"><font-awesome-icon icon="file" /> Ver Documento</a>
          </div>
          <div class="form-group">
            <label>Insurance certificate</label>
            <input id="documento_certificado3" type="file" accept="application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/msword, application/vnd.ms-excel, application/pdf, image/*" class="form-control" />
          </div>
          <div class="form-group">
            <a v-if="documento_certificado3" href="#" @click="downloadFile(documento_certificado3)" class="btn btn-primary"><font-awesome-icon icon="file" /> Ver Documento</a>
          </div>
        </div>
        <div class="col-md-3">
          <div class="form-group">
            <label>Fumigation certificate</label>
            <input id="documento_certificado4" type="file" accept="application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/msword, application/vnd.ms-excel, application/pdf, image/*" class="form-control" />
          </div>
          <div class="form-group">
            <a v-if="documento_certificado4" href="#" @click="downloadFile(documento_certificado4)" class="btn btn-primary"><font-awesome-icon icon="file" /> Ver Documento</a>
          </div>
          <div class="form-group">
            <label>Veterinaty health certificate</label>
            <input id="documento_certificado5" type="file" accept="application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/msword, application/vnd.ms-excel, application/pdf, image/*" class="form-control" />
          </div>
          <div class="form-group">
            <a v-if="documento_certificado5" href="#" @click="downloadFile(documento_certificado5)" class="btn btn-primary"><font-awesome-icon icon="file" /> Ver Documento</a>
          </div>
          <div class="form-group">
            <label>MSDS (Material Safety data sheet)</label>
            <input id="documento_mds" type="file" accept="application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/msword, application/vnd.ms-excel, application/pdf, image/*" class="form-control" />
          </div>
          <div class="form-group">
            <a v-if="documento_mds" href="#" @click="downloadFile(documento_mds)" class="btn btn-primary"><font-awesome-icon icon="file" /> Ver Documento</a>
          </div>
        </div>
        <div class="col-md-3">
          <div class="form-group">
            <label>Phitosanitary Health Certificate</label>
            <input id="documento_certificado6" type="file" accept="application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/msword, application/vnd.ms-excel, application/pdf, image/*" class="form-control" />
          </div>
          <div class="form-group">
            <a v-if="documento_certificado6" href="#" @click="downloadFile(documento_certificado6)" class="btn btn-primary"><font-awesome-icon icon="file" /> Ver Documento</a>
          </div>
          <div class="form-group">
            <label>BSE Free Declaration</label>
            <input id="documento_bse" type="file" accept="application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/msword, application/vnd.ms-excel, application/pdf, image/*" class="form-control" />
          </div>
          <div class="form-group">
            <a v-if="documento_bse" href="#" @click="downloadFile(documento_bse)" class="btn btn-primary"><font-awesome-icon icon="file" /> Ver Documento</a>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <button type="button" @click="addRow" class="btn btn-secondary float-right">
            <font-awesome-icon icon="plus" /> Agregar Lote
          </button>
          <div class="mt-5 form-group">
            <span class="float-right mb-2 mr-2" v-for="(row, index) in dataProduct" :key="index"><b>{{ row.codigo + " | " + row.nombre }}</b>: <span v-if="row.cantidad2 >= 0">{{ row.cantidad2 }}</span><span class="text-danger" v-else>{{ row.cantidad2 }}</span></span>
            <table class="table table-hover">
              <thead>
                <tr>
                  <th>Número Lote</th>
                  <th>Fecha Lote</th>
                  <th>Fecha Vencimiento</th>
                  <th>Producto</th>
                  <th>Cantidad</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(row, index) in detalle" :key="index">
                  <td>
                    <input v-model="row.lote_numero" type="text" class="form-control" />
                  </td>
                  <td>
                    <input v-model="row.lote_fecha" :id="'dateStart' + index" @change="onChangeDateEnd(index)"  type="date" class="form-control" />
                  </td>
                  <td>
                    <input v-model="row.lote_vencimiento" :id="'dateEnd' + index" @change="onChangeDateEnd(index)" type="date" class="form-control" />
                  </td>
                  <td>
                    <select v-model="row.id_producto" name="producto" @change="onChangeCant(index)" class="form-control">
                      <option v-for="(row2, index2) in dataProduct" :data-key="index2"  :key="row2.id_producto + row2.cantidad + 'index' + index2" :value="row2.id_producto">{{ row2.codigo + " | " + row2.nombre }}</option>
                    </select>
                  </td>
                  <td>
                    <input v-model="row.cantidad" name="cantidad" type="number" required @keyup="onChangeCant(index)" min="0" class="form-control" />
                  </td>
                  <td>
                    <p @click="deleteRow(index)" class="btn btn-danger">
                      <font-awesome-icon icon="trash" />
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div id="divSt4">
      <div class="row">
        <div class="col-md-12">
        </div>
      </div> 
    </div>
    <div id="divSt5">
      <div class="row">
        <div class="col-md-12">
          <div class="form-group">
            <label>Fecha de Entregada</label>
            <input v-model="fecha_entregada" type="date" class="form-control" />
          </div>
        </div>
      </div>  
    </div>
    <div id="divSt6">
      <div class="row">
        <div class="col-md-12">
        </div>
      </div> 
    </div>
    <div v-if="isEdit" class="row">
      <div class="col-md-12">
        <div class="form-group">
          <label>Observaciones</label>
          <input v-model="observaciones" type="text" class="form-control" />
        </div>
      </div>
    </div> 
    <button class="btn btn-primary" type="submit">
      <font-awesome-icon icon="save" /> Guardar
    </button>
  </form>
</template>

<script>
import api from "@/api.js";
export default {
  name: "orderformin",
  props: {
    idOrder: String,
    dataOrder: Object,
  },
  data() {
    return {
      //data
      dataWarehouse: [],
      dataProvider: [],
      dataProduct: [],
      //object
      consecutivo: null,
      id_proveedor: null,
      id_bodega: null,
      fecha_estimada: null,
      detalle: [],
      cantidad: null,
      documento_orden: null,
      numero_carga: null,
      empresa: null,
      documento_factura: null,
      documento_pl: null,
      documento_bl: null,
      documento_bse: null,
      documento_mds: null,
      documento_certificado1: null,
      documento_certificado2: null,
      documento_certificado3: null,
      documento_certificado4: null,
      documento_certificado5: null,
      documento_certificado6: null,
      fecha_entregada: null,
      estado: 0,
      observaciones:[],
      lotes_ingresados: false,
      //Aux
      isEdit: false,
    };
  },
  watch: {
    dataOrder: function(value) {
      this.$data.consecutivo = value.consecutivo;
      this.$data.id_proveedor = value.id_proveedor;
      this.$data.id_bodega = value.id_bodega;
      this.$data.fecha_estimada = value.fecha_estimada.substr(0,10);
      this.$data.cantidad = value.cantidad;
      this.$data.documento_orden = value.documento_orden;
      this.$data.numero_carga = value.numero_carga;
      this.$data.empresa = value.empresa;
      this.$data.documento_factura = value.documento_factura;
      this.$data.documento_pl = value.documento_pl;
      this.$data.documento_bl = value.documento_bl;
      this.$data.documento_bse = value.documento_bse;
      this.$data.documento_mds = value.documento_mds;
      this.$data.documento_certificado1 = value.documento_certificado1;
      this.$data.documento_certificado2 = value.documento_certificado2;
      this.$data.documento_certificado3 = value.documento_certificado3;
      this.$data.documento_certificado4 = value.documento_certificado4;
      this.$data.documento_certificado5 = value.documento_certificado5;
      this.$data.documento_certificado6 = value.documento_certificado6;
      this.$data.observaciones = value.observaciones[value.estado];
      if(value.fecha_entregada) {
        this.$data.fecha_entregada = value.fecha_entregada.substr(0,10);
      }
      this.$data.estado = value.estado;
      if(value.estado === 0){
        this.onChangeProvider(value.id_proveedor);
        this.$data.detalle = value.detalle;
      }else if(value.estado === 3){
        this.$data.detalle = value.detalle;
        this.$data.dataProduct = this.copy(value.detalle);
        this.$data.dataProduct.forEach((detalle, index) => {
          this.$data.dataProduct[index].cantidad2 = detalle.cantidad
          this.$data.dataProduct[index].cantidad3 = detalle.cantidad
          this.onChangeCant(index)
        })
        this.$data.detalle.forEach((detalle, index) => {
          if(detalle.lote_fecha){
            this.$data.detalle[index].lote_fecha = detalle.lote_fecha.substr(0,10);
          }
          if(detalle.lote_vencimiento){
            this.$data.detalle[index].lote_vencimiento = detalle.lote_vencimiento.substr(0,10);
          }
          this.onChangeCant(index)
        })
      }
      this.$data.isEdit = true;
      this.showDivStatus();
    }
  },
  mounted: function(){
    this.showDivStatus();
    this.getWarehouses();
    this.getProviders();
  },
  methods: {
    copy: function(o) {
      var output, v, key;
      output = Array.isArray(o) ? [] : {};
      for (key in o) {
        v = o[key];
        output[key] = (typeof v === "object") ? this.copy(v) : v;
      }
      return output;
    },
    showDivStatus: function() {
      let that = this;
      document.querySelectorAll("form > div").forEach(function(div, index){
        if(that.estado === index) {
          document.getElementById("divSt" + index).style.display = "block";
        }else{
          if(div.className === ""){
            document.getElementById("divSt" + index).style.display = "none";
          }
        }
      });
    },
    addRow: function() {
      if(this.estado === 0){
        this.detalle.push({ lote_numero: null, lote_fecha: null, lote_vencimiento: null, id_producto: null, cantidad: 0 });
      }else{
        this.detalle.push({ lote_numero: null, lote_fecha: null, lote_vencimiento: null, id_producto: null, cantidad: 0 });
      }
    },
    deleteRow: function(index) {
      this.detalle.splice(index, 1);
    },
    getWarehouses: function() {
      let that = this;
      let loader = this.$loading.show();
      this.dataWarehouse.length = 0;
      api
        .getWarehouses()
        .then(data => {
          that.dataWarehouse = data.warehouses;
          loader.hide();
        })
        .catch((error) => {
          that.showAlert(error.response.data.message);
          loader.hide();
          if(error.response.status === 401){
            localStorage.removeItem("userToken");
            that.$router.push('/login');
          }
        });
    },
    getProviders: function() {
      let that = this;
      let loader = this.$loading.show();
      this.dataProvider.length = 0;
      api
        .getProviders()
        .then(data => {
          that.dataProvider = data.providers;
          loader.hide();
        })
        .catch((error) => {
          that.showAlert(error.response.data.message);
          loader.hide();
          if(error.response.status === 401){
            localStorage.removeItem("userToken");
            that.$router.push('/login');
          }
        });
    },
    onChangeProvider(event) {
      let that = this;
      let loader = this.$loading.show();
      this.dataProduct.length = 0;
      api
        .getProductsByProvider(typeof event.target === "undefined" ? event : event.target.value)
        .then(data => {
          that.dataProduct = data.products;
          loader.hide();
        })
        .catch((error) => {
          that.showAlert(error.response.data.message);
          loader.hide();
          if(error.response.status === 401){
            localStorage.removeItem("userToken");
            that.$router.push('/login');
          }
        });
    },
    /*
    onChangeProduct(event, index) {
      let cant = this.dataProduct.find(product => product.id_producto == event.target.value).cantidad;
      this.detalle.forEach(item => {
        if(item.id_producto == event.target.value){
          cant = cant - item.cantidad;
        }
      });
      this.detalle[index].cantidad2 = cant;
    },
    */
   /*
    onChangeCant(event, index) {
      let cant = this.dataProduct.find(product => product.id_producto == this.detalle[index].id_producto).cantidad;
      this.detalle.forEach(item => {
        if(item.id_producto == this.detalle[index].id_producto){
          cant = cant - item.cantidad;
        }
      });
      this.dataProduct = this.dataProduct.map(product => {
        if(this.detalle[index].id_producto === product.id_producto){
          product.cantidad2 = cant;
        }
        return product;
      });
    },
    */
    onChangeCant(index) {
      let totalValue = 0;
      let defaultValue = 0;

      /*
      let valueArr = this.dataProduct.map(function(item){ return item.id_producto });
      var isDuplicate = valueArr.some(function(item, idx){ 
          return valueArr.indexOf(item) != idx 
      });

      if(isDuplicate){
        defaultValue = this.dataProduct.find(product => product.id_producto == this.detalle[index].id_producto).cantidad3;
      }else{
        defaultValue = this.dataProduct.find(product => product.id_producto == this.detalle[index].id_producto).cantidad3;
      }
      */
      //defaultValue = this.dataProduct.find(product => product.id_producto == this.detalle[index].id_producto).cantidad3;
      //console.log(this.dataProduct.find(product => product.id_producto == this.detalle[index].id_producto))
      this.dataProduct.forEach(item => {
        if(item.id_producto == this.detalle[index].id_producto){
          if(item.cantidad != ''){
            defaultValue = defaultValue + parseInt(item.cantidad);
          }else{
            defaultValue = defaultValue + 0;
          }
        }
      });
      this.detalle.forEach(item => {
        if(item.id_producto == this.detalle[index].id_producto){
          if(item.cantidad != ''){
            totalValue = totalValue + parseInt(item.cantidad);
          }else{
            totalValue = totalValue + 0;
          }
        }
      });
      this.dataProduct = this.dataProduct.map(product => {
        if(this.detalle[index].id_producto === product.id_producto){
          product.cantidad2 = defaultValue - totalValue;
        }
        return product;
      });
    },
    isNumeric: function(str) {
      if (typeof str != "string") return false // we only process strings!  
      return !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
            !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
    },
    onChangeDateEnd(index) {
      let dateStart = document.getElementById('dateStart' + index).value;
      let dateEnd = document.getElementById('dateEnd' + index).value;
      if(new Date(dateEnd).getTime() <= new Date(dateStart)) {
        this.showAlert("La fecha de vencimiento no puede ser menor que la fecha de lote")
        this.detalle[index].lote_fecha = null
        this.detalle[index].lote_vencimiento = null
        return false;
      }
    },
    registerOrder: function() {
      let that = this;
      let dataOrder = this.$data;
      switch(dataOrder.estado){
        case 0: {
          if(that.fecha_estimada && that.id_proveedor && that.id_bodega) {
            if(that.detalle.length > 0) {
              dataOrder.cantidad = that.detalle.length;
              that.emitOrder(dataOrder);
            }else{
              that.showAlert("Completa el detalle");
            }
          }else{
            that.showAlert("Completa los campos");
          }
          break;
        }
        case 1: {
          let doc = document.getElementById("documento_orden");
          if(doc.files.length > 0) {            
            let formData = new FormData();
            formData.append('documento_orden', doc.files[0]);
            dataOrder.files = formData;
            that.emitOrder(dataOrder);
          }else{
            that.showAlert("Completa los campos");
          }
          break;
        }
        case 2: {
          if(dataOrder.numero_carga && dataOrder.empresa) {
            that.emitOrder(dataOrder);
          }else{
            that.showAlert("Completa los campos");
          }
          break;
        }
        case 3: {
          if(dataOrder.detalle.length > 0){
            let isCompleted = true;
            dataOrder.dataProduct.forEach(item => {
              if(item.cantidad2 != 0){
                isCompleted = false
              }
            })
            dataOrder.detalle.forEach(item => {
              if(item.lote_numero == null){
                isCompleted = false
              }
              if(item.lote_fecha == null){
                isCompleted = false
              }
              if(item.lote_vencimiento == null){
                isCompleted = false
              }
            })
            if(isCompleted){
              let doc1 = document.getElementById("documento_factura");
              let doc2 = document.getElementById("documento_bl");
              let doc3 = document.getElementById("documento_pl");
              let doc4 = document.getElementById("documento_mds");
              let doc5 = document.getElementById("documento_bse");
              let doc6 = document.getElementById("documento_certificado1");
              let doc7 = document.getElementById("documento_certificado2");
              let doc8 = document.getElementById("documento_certificado3");
              let doc9 = document.getElementById("documento_certificado4");
              let doc10 = document.getElementById("documento_certificado5");
              let doc11 = document.getElementById("documento_certificado6");
              let formData = new FormData();
              if(doc1.files.length > 0) {
                formData.append('documento_factura', doc1.files[0]);
              }
              if(doc2.files.length > 0) {
                formData.append('documento_bl', doc2.files[0]);
              }
              if(doc3.files.length > 0) {
                formData.append('documento_pl', doc3.files[0]);
              }
              if(doc4.files.length > 0) {
                formData.append('documento_mds', doc4.files[0]);
              }
              if(doc5.files.length > 0) {
                formData.append('documento_bse', doc5.files[0]);
              }
              if(doc6.files.length > 0) {
                formData.append('documento_certificado1', doc6.files[0]);
              }
              if(doc7.files.length > 0) {
                formData.append('documento_certificado2', doc7.files[0]);
              }
              if(doc8.files.length > 0) {
                formData.append('documento_certificado3', doc8.files[0]);
              }
              if(doc9.files.length > 0) {
                formData.append('documento_certificado4', doc9.files[0]);
              }
              if(doc10.files.length > 0) {
                formData.append('documento_certificado5', doc10.files[0]);
              }
              if(doc11.files.length > 0) {
                formData.append('documento_certificado6', doc11.files[0]);
              }
              dataOrder.files = formData;
              dataOrder.lotes_ingresados = true;
              that.emitOrder(dataOrder);
            }else{
              that.showAlert("Diligencia bien las cantidades de lotes");
            }
          }else{
            that.showAlert("Completa los campos");
          }
            break;
        }
        case 4: {
          that.emitOrder(dataOrder);
          break;
        }
        case 5: {
          if(dataOrder.fecha_entregada) {
            that.emitOrder(dataOrder);
          }else{
            that.showAlert("Completa los campos");
          }
          break;
        }
      }
    },
    downloadFile: function(filename) {
      this.$emit("downloadFile", filename)
    },
    emitOrder: function(dataOrder) {
      delete dataOrder.dataWarehouse;
      delete dataOrder.dataProvider;
      delete dataOrder.dataProduct;
      this.$emit("registerOrder", dataOrder);
    },
    showAlert: function(message) {
      this.$swal({
        position: "top-end",
        icon: "error",
        title: message,
        showConfirmButton: false,
        timer: 1500
      });
    }
  }
};
</script>
