<template>
    <AppLayout>
        <h2 class="mb-4">Departamentos</h2>
        <div class="card mb-4">
          <div class="card-body">
              <div class="row">
                  <div class="col-md-4">
                    <h4>Registro</h4>
                    <form @submit.prevent="registerState">
                      <div class="form-group">
                        <label>Nombre</label>
                        <input v-model="nombre" type="text" class="form-control" />
                      </div>
                      <div class="form-group">
                        <label>Pais</label>
                        <select v-model="id_pais" class="form-control">
                          <option v-for="row in dataCountries" :key="row.id" :value="row.id">{{ row.nombre }}</option>
                        </select>
                      </div>
                      <button class="btn btn-primary" type="submit">
                        <font-awesome-icon icon="save" /> Registrar
                      </button>
                    </form>
                  </div>
                  <div class="col-md-8">
                      <h4>Listado</h4>
                      <ListList :userType="userType" :dataList="dataState" @deleteList="deleteState"></ListList>
                  </div>
              </div>    
          </div>
        </div> 
    </AppLayout>
</template>

<script>
import api from "@/api.js"
import AppLayout from '@/components/layout/AppLayout.vue'
import ListList from '@/components/list/ListList.vue'
export default {
  name: 'State',
  components: {
    AppLayout,
    ListList,
  },
  data() {
    return {
        nombre: null, 
        id_pais: null,
        dataState: [],
        dataCountries: [],
        nodeState: "departamento",
        userType: "Admin",
    }
  },
  created: function() {
    this.$data.userType = localStorage.getItem("userType");
  },
  mounted: function () {
    this.getStates();
    this.getCountries()
  },
  methods: {
    registerState: function () {
      let that = this;
      let loader = this.$loading.show();
      let data = { nombre: this.nombre, id_pais: this.id_pais };
      api
        .registerState(data)
        .then(() => {
          loader.hide();
          that.getStates();
        })
        .catch((error) => {
          that.showAlert(error.response.data.message);
          loader.hide();
          if(error.response.status === 401){
            localStorage.removeItem("userToken");
            that.$router.push('/login');
          }
        });
    },
    getStates: function () {
      let that = this;
      let loader = this.$loading.show();
      this.dataState.length = 0;
      api
        .getStates()
        .then((data) => {
          that.dataState = data.states;
          loader.hide();
        })
        .catch((error) => {
          that.showAlert(error.response.data.message);
          loader.hide();
          if(error.response.status === 401){
            localStorage.removeItem("userToken");
            that.$router.push('/login');
          }
        });
    },
    getCountries: function () {
      let that = this;
      let loader = this.$loading.show();
      this.dataCountries.length = 0;
      api
        .getCountries()
        .then((data) => {
          that.dataCountries = data.countries;
          loader.hide();
        })
        .catch((error) => {
          that.showAlert(error.response.data.message);
          loader.hide();
          if(error.response.status === 401){
            localStorage.removeItem("userToken");
            that.$router.push('/login');
          }
        });
    },
    deleteState: function(id) {
      let that = this;
      let loader = this.$loading.show();
      api
        .deleteState(id)
        .then(() => {
          that.dataState = that.dataState.filter(State => State.id != id);
          loader.hide();
        })
        .catch((error) => {
          that.showAlert(error.response.data.message);
          loader.hide();
          if(error.response.status === 401){
            localStorage.removeItem("userToken");
            that.$router.push('/login');
          }
        });
    },
    showAlert: function(message) {
      this.$swal({
        position: "top-end",
        icon: "error",
        title: message,
        showConfirmButton: false,
        timer: 1500
      });
    }
  }
}
</script>
