<template>
  <div>
    <nav class="navbar navbar-expand navbar-dark bg-primary">
      <a class="sidebar-toggle mr-3" href="#">
        <i class="fa fa-bars"></i>
      </a>
      <router-link class="navbar-brand" to="home">Panel Admin</router-link>
      <div class="navbar-collapse collapse">
        <ul class="navbar-nav ml-auto">
          <li class="nav-item dropdown">
            <a href="#" id="dd_user" class="nav-link dropdown-toggle" data-toggle="dropdown">
              <font-awesome-icon icon="user" />
              {{ user }}
            </a>
            <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dd_user">
              <router-link class="dropdown-item" to="/perfil">
              Mi Perfil
              </router-link>
              <a href="#" @click="logout" class="dropdown-item">Cerrar Sesión</a>
            </div>
          </li>
        </ul>
      </div>
    </nav>
    <div class="d-flex">
      <div class="sidebar sidebar-dark bg-dark">
        <!--<img src="../../assets/logo.png" class="mt-3" style="padding: .75rem 1rem" width="180" />-->
        <h1 class="title-header">Nux-Data</h1>
        <ul class="list-unstyled">
          <li>
            <router-link to="/home">
              <font-awesome-icon icon="tachometer-alt" /> Home
            </router-link>
          </li>
          <li v-if="userType === 'Admin'">
            <a href="#sm_expand_0" data-toggle="collapse">
              <font-awesome-icon icon="user-tie" /> Usuarios
              <span class="expand">
                <font-awesome-icon icon="caret-down" />
              </span>
            </a>
            <ul id="sm_expand_0" class="list-unstyled collapse">
              <li>
                <router-link to="/usuario/register">Registrar</router-link>
              </li>
              <li>
                <router-link to="/usuario">Listado</router-link>
              </li>
              <li>
                <router-link to="/usuario/solicitudes">Usuarios Solicitudes</router-link>
              </li>
            </ul>
          </li>
          <li>
            <a href="#sm_expand_11" data-toggle="collapse">
              <font-awesome-icon icon="chart-line" /> Pronosticos
              <span class="expand">
                <font-awesome-icon icon="caret-down" />
              </span>
            </a>
            <ul id="sm_expand_11" class="list-unstyled collapse">
              <li v-if="(userType === 'Admin' || userModules.includes('Pronosticos')) && userType !== 'Invitado'">
                <router-link to="/pronostico/register">Registrar</router-link>
              </li>
              <li>
                <router-link to="/pronostico">Listado</router-link>
              </li>
              <li>
                <router-link to="/pronostico/resumen">Resumen</router-link>
              </li>
            </ul>
          </li>
          <li>
            <a href="#sm_expand_1" data-toggle="collapse">
              <font-awesome-icon icon="user" /> Clientes
              <span class="expand">
                <font-awesome-icon icon="caret-down" />
              </span>
            </a>
            <ul id="sm_expand_1" class="list-unstyled collapse">
              <li v-if="(userType === 'Admin' || userModules.includes('Clientes')) && userType !== 'Invitado'">
                <router-link to="/cliente/register">Registrar</router-link>
              </li>
              <li>
                <router-link to="/cliente">Listado</router-link>
              </li>
            </ul>
          </li>
          <li>
            <a href="#sm_expand_2" data-toggle="collapse">
              <font-awesome-icon icon="building" /> Sucursales
              <span class="expand">
                <font-awesome-icon icon="caret-down" />
              </span>
            </a>
            <ul id="sm_expand_2" class="list-unstyled collapse">
              <li v-if="(userType === 'Admin' || userModules.includes('Sucursales')) && userType !== 'Invitado'">
                <router-link to="/sucursal/register">Registrar</router-link>
              </li>
              <li>
                <router-link to="/sucursal">Listado</router-link>
              </li>
            </ul>
          </li>
          <li>
            <a href="#sm_expand_3" data-toggle="collapse">
              <font-awesome-icon icon="user-tie" /> Proveedores
              <span class="expand">
                <font-awesome-icon icon="caret-down" />
              </span>
            </a>
            <ul id="sm_expand_3" class="list-unstyled collapse">
              <li v-if="(userType === 'Admin' || userModules.includes('Proveedores')) && userType !== 'Invitado'">
                <router-link to="/proveedor/register">Registrar</router-link>
              </li>
              <li>
                <router-link to="/proveedor">Listado</router-link>
              </li>
            </ul>
          </li>
          <li>
            <a href="#sm_expand_4" data-toggle="collapse">
              <font-awesome-icon icon="boxes" /> Productos
              <span class="expand">
                <font-awesome-icon icon="caret-down" />
              </span>
            </a>
            <ul id="sm_expand_4" class="list-unstyled collapse">
              <li v-if="(userType === 'Admin' || userModules.includes('Productos')) && userType !== 'Invitado'">
                <router-link to="/producto/register">Registrar</router-link>
              </li>
              <li>
                <router-link to="/producto">Listado</router-link>
              </li>
            </ul>
          </li>
          <li>
            <a href="#sm_expand_5" data-toggle="collapse">
              <font-awesome-icon icon="truck-loading" /> Bodegas
              <span class="expand">
                <font-awesome-icon icon="caret-down" />
              </span>
            </a>
            <ul id="sm_expand_5" class="list-unstyled collapse">
              <li v-if="(userType === 'Admin' || userModules.includes('Bodegas')) && userType !== 'Invitado'">
                <router-link to="/bodega/register">Registrar</router-link>
              </li>
              <li>
                <router-link to="/bodega">Listado</router-link>
              </li>
            </ul>
          </li>
          <li>
            <a href="#sm_expand_6" data-toggle="collapse">
              <font-awesome-icon icon="file-invoice-dollar" /> Facturas
              <span class="expand">
                <font-awesome-icon icon="caret-down" />
              </span>
            </a>
            <ul id="sm_expand_6" class="list-unstyled collapse">
              <li v-if="(userType === 'Admin' || userModules.includes('Facturas')) && userType !== 'Invitado'">
                <router-link to="/factura/register">Registrar</router-link>
              </li>
              <li>
                <router-link to="/factura">Listado</router-link>
              </li>
            </ul>
          </li>
          <li>
            <a href="#sm_expand_9" data-toggle="collapse">
              <font-awesome-icon icon="file-alt" /> Pedidos
              <span class="expand">
                <font-awesome-icon icon="caret-down" />
              </span>
            </a>
            <ul id="sm_expand_9" class="list-unstyled collapse">
              <li v-if="(userType === 'Admin' || userModules.includes('Pedidos')) && userType !== 'Invitado'">
                <router-link to="/pedido/register">Registrar</router-link>
              </li>
              <li>
                <router-link to="/pedido">Listado</router-link>
              </li>
              <li v-if="userType === 'Admin' || userModules.includes('Pedidos')">
                <router-link to="/pedido/requests">Solicitudes Pendientes</router-link>
              </li>
            </ul>
          </li>
          <li>
            <a href="#sm_expand_10" data-toggle="collapse">
              <font-awesome-icon icon="exchange-alt" /> Devoluciones
              <span class="expand">
                <font-awesome-icon icon="caret-down" />
              </span>
            </a>
            <ul id="sm_expand_10" class="list-unstyled collapse">
              <li v-if="(userType === 'Admin' || userModules.includes('Devoluciones')) && userType !== 'Invitado'">
                <router-link to="/devolucion/register">Registrar</router-link>
              </li>
              <li v-if="userType === 'Admin' || userModules.includes('Devoluciones')">
                <router-link to="/devolucion">Listado</router-link>
              </li>
            </ul>
          </li>
          <li>
            <a href="#sm_expand_7" data-toggle="collapse">
              <font-awesome-icon icon="clipboard-list" /> Ordenes
              <span class="expand">
                <font-awesome-icon icon="caret-down" />
              </span>
            </a>
            <ul id="sm_expand_7" class="list-unstyled collapse">
              <li v-if="(userType === 'Admin' || userModules.includes('Entradas')) && userType !== 'Invitado'">
                <router-link to="/orden/entrada/register">Registrar Entrada</router-link>
              </li>
              <li v-if="(userType === 'Admin' || userModules.includes('Salidas')) && userType !== 'Invitado'">
                <router-link to="/orden/salida/register">Registrar Salida</router-link>
              </li>
              <li v-if="(userType === 'Admin' || userModules.includes('Traslados')) && userType !== 'Invitado'">
                <router-link to="/orden/bodega/register">Traslado Bodega</router-link>
              </li>
              <li v-if="(userType === 'Admin' || userModules.includes('Transformaciones')) && userType !== 'Invitado'">
                <router-link to="/orden/producto/register">Transformación Producto</router-link>
              </li>
              <li>
                <router-link to="/orden">Listado</router-link>
              </li>
              <li>
                <router-link to="/orden/general">Listado General</router-link>
              </li>
            </ul>
          </li>
          <li v-if="userType === 'Admin'">
            <a href="#sm_expand_8" data-toggle="collapse">
              <font-awesome-icon icon="cog" /> Configuración
              <span class="expand">
                <font-awesome-icon icon="caret-down" />
              </span>
            </a>
            <ul id="sm_expand_8" class="list-unstyled collapse">
              <li>
                <router-link to="/pais">Países</router-link>
              </li>
              <li>
                <router-link to="/departamento">Departamentos</router-link>
              </li>
              <li>
                <router-link to="/ciudad">Ciudades</router-link>
              </li>
              <li>
                <router-link to="/medida">Medidas</router-link>
              </li>
              <li>
                <router-link to="/tabla">Campos</router-link>
              </li>
            </ul>
          </li>
        </ul>
      </div>
      <div class="content p-4">
        <slot></slot>
      </div>
    </div>
  </div>
</template>
<style scoped>
</style>
<script>
export default {
  name: "AppLayout",
  data() {
    return {
      user: localStorage.getItem("userName"),
      userType: "Admin",
      userModules: []
    };
  },
  created: function() {
    document.body.classList.remove("bg-dark");
  },
  mounted: function() {
    this.$data.userType = localStorage.getItem("userType");
    if(localStorage.getItem("userModules").includes(',')){
      var arr = localStorage.getItem("userModules").split(',')
      for(var i = 0; i < arr.length; i++){
        this.userModules.push(arr[i])
      }
      console.log(this.userModules)
    }
  },
  methods: {
    logout: function() {
      let that = this;
      this.$swal({
        title: "Quieres Salir?",
        text: "Está seguro de querer cerrar sesión",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si",
        cancelButtonText: "No"
      }).then(result => {
        if (result.value) {
          localStorage.removeItem("userId");
          localStorage.removeItem("userName");
          localStorage.removeItem("userToken");
          that.$router.push('/login');
        }
      });
    }
  }
};
</script>