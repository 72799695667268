<template>
  <table id="table3" class="table table-bordered">
    <thead>
      <tr>
        <th>Consecutivo</th>
        <th>Bodega</th>
        <th>Producto Saliente</th>
        <th>Producto Entrante</th>
        <th>Cantidad Saliente</th>
        <th>Cantidad Entrante</th>
        <th>Acciones</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="row in dataOrder" :key="row.id">
        <td>{{ row.id.toString().padStart(4, "0") }}</td>
        <td>{{ row.bodega }}</td>
        <td>{{ row.producto_saliente }}</td>
        <td>{{ row.producto_entrante }}</td>
        <td>{{ row.cantidad_saliente }}</td>
        <td>{{ row.cantidad_entrante }}</td>
        <td>
          <router-link class="btn btn-primary" :to="{ path: '/orden/producto/detail/' + row.id }">
            <font-awesome-icon icon="eye" /> Detalle
          </router-link>&nbsp;
          <button v-if="userType === 'Admin' || userType === 'Full Access' || userType === 'Comercial'" class="btn btn-danger" @click="deleteOrder(row.id)">
            <font-awesome-icon icon="trash-alt" /> Eliminar
          </button>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
const $ = require("jquery");
$.DataTable = require("datatables.net");
import "datatables.net-bs4/css/dataTables.bootstrap4.min.css";
import "datatables.net-bs4/js/dataTables.bootstrap4.min.js";
import "datatables.net-buttons/js/dataTables.buttons.min.js";
import "datatables.net-buttons/js/buttons.html5.min.js";
import "datatables.net-buttons/js/buttons.flash.min.js";
import "datatables.net-buttons/js/buttons.print.min.js";
import "datatables.net-buttons-bs4/css/buttons.bootstrap4.min.css";
import "datatables.net-buttons-bs4/js/buttons.bootstrap4.min.js";
window.JSZip = require( 'jszip' );
require( 'pdfmake' );

export default {
  name: "orderlistproduct",
  props: {
    dataOrder: Array
  },
  data() {
    return {
      userType: "Admin",
      userModules: [],
    };
  },
  mounted: function() {
    this.$data.userType = localStorage.getItem("userType");
    this.$data.userModules = localStorage.getItem("userModules");
  },
  updated: function () {
    $("#table3").DataTable({ 
      retrieve: true,
      dom: 'Blfrtip', 
      buttons: [ 
        { 
          extend: 'excel', className: 'btn btn-secondary'
        },
      ],
      language: {
        "url": "//cdn.datatables.net/plug-ins/9dcbecd42ad/i18n/Spanish.json"
      },
    });
  },
  methods: {
    deleteOrder: function(id) {
      let that = this;
      this.$swal({
        title: "Quieres Eliminar?",
        text: "Está seguro de querer eliminar esto",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si",
        cancelButtonText: "No"
      }).then(result => {
        if (result.value) {
          that.$emit("deleteOrder", id, 0, "producto");
        }
      });
    },
  }
};
</script>
