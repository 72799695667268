<template>
  <form @submit.prevent="registerOrder">
    <div id="divSt0">
      <div class="row">
        <div class="col-md-3">
          <div class="form-group">
            <label>Bodega</label>
            <select v-model="id_bodega" @change="onChangeWarehouse($event)" class="form-control">
              <option v-for="row in dataWarehouse" :key="row.id" :value="row.id">{{ row.nombre }}</option>
            </select>
          </div>
        </div>
        <div class="col-md-3">
          <div class="form-group">
            <label>Producto Saliente</label>
            <select v-model="id_producto_saliente" @change="onChangeProduct($event)" class="form-control">
              <option v-for="row in dataProductOutMin" :key="row.id" :value="row.id_producto">{{ row.nombre + " | " + row.codigo }}</option>
            </select>
          </div>
        </div>
        <div class="col-md-3">
          <div class="form-group">
            <label>Número Lote Saliente</label>
            <select v-model="lote_numero" @change="onChangeLote($event)" class="form-control">
              <option v-for="row in dataLote" :key="row.id" :value="row.lote_numero">{{ row.lote_numero }}</option>
            </select>
          </div>
        </div>
        <div class="col-md-3">
          <div class="form-group">
            <label>Cantidad Saliente</label>
            <input v-model="cantidad1" type="number" min="1" required :max="cantidad2" :placeholder="cantidad2" class="form-control" />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-3">
          <div class="form-group">
            <label>Producto Entrante</label>
            <select v-model="id_producto_entrante" class="form-control">
              <option v-for="row in dataProductIn" :key="row.id" :value="row.id">{{ row.nombre + " | " + row.codigo + " (" + row.unidad + " " + row.medida + ")" }}</option>
            </select>
          </div>
        </div>
        <div class="col-md-3">
          <div class="form-group">
            <label>Cantidad Entrante</label>
            <input v-model="cantidad" type="number" required class="form-control" />
          </div>
        </div>
        <div class="col-md-3">
          <div class="form-group">
            <label>Fecha Lote</label>
            <input v-model="lote_fecha" readonly type="date" class="form-control" />
          </div>
        </div>
        <div class="col-md-3">
          <div class="form-group">
            <label>Fecha Vencimiento</label>
            <input v-model="lote_vencimiento" readonly type="date" class="form-control" />
          </div>
        </div>
      </div>
    </div>
    <button class="btn btn-primary" type="submit">
      <font-awesome-icon icon="save" /> Guardar
    </button>
  </form>
</template>

<script>
import api from "@/api.js";
export default {
  name: "orderformwarehouse",
  props: {
    dataOrder: Object,
  },
  data() {
    return {
      //data
      dataWarehouse: [],
      dataProductOut: [],
      dataProductOutMin: [],
      dataProductIn: [],
      dataLote: [],
      //object
      id: null,
      id_bodega: null,
      id_producto_saliente: null,
      id_producto_entrante: null,
      id_lote: null,
      lote_numero: null,
      lote_fecha: null,
      lote_vencimiento: null,
      cantidad: null,
      cantidad1: null,
      cantidad2: null,
    };
  },
  mounted: function(){
    this.getWarehouses();
    this.getProducts();
  },
  methods: {
    onChangeWarehouse(event) {
      let that = this;
      let loader = this.$loading.show();
      this.dataProductOut.length = 0;
      this.dataProductOutMin.length = 0;
      api
        .getStock(typeof event.target === "undefined" ? event : event.target.value)
        .then((data) => {
          that.dataProductOut = data.stock;
          that.dataProductOutMin = Object.values(
            data.stock.reduce( (c, e) => {
              if (!c[e.id_producto]) c[e.id_producto] = e;
              return c;
            }, {})
          );
          loader.hide();
        })
        .catch((error) => {
          that.showAlert("error", error.response.data.message);
          loader.hide();
          if(error.response.status === 401){
            localStorage.removeItem("userToken");
            that.$router.push('/login');
          }
        });
    },
    onChangeProduct: function(event) {
      this.dataLote = this.dataProductOut.filter(stock => stock.id_producto == event.target.value);
    },
    onChangeLote: function(event) {
      let stock = this.dataProductOut.filter(stock => stock.lote_numero == event.target.value);
      console.log(stock)
      this.id_lote = stock[0].id_lote;
      this.lote_numero = stock[0].lote_numero.substr(0,10);
      this.lote_fecha = stock[0].lote_fecha.substr(0,10);
      this.lote_vencimiento = stock[0].lote_vencimiento.substr(0,10);
      this.id_producto = stock[0].id_producto;
      this.cantidad2 = stock[0].cantidad;
    },
    getWarehouses: function() {
      let that = this;
      let loader = this.$loading.show();
      this.dataWarehouse.length = 0;
      api
        .getWarehouses()
        .then(data => {
          that.dataWarehouse = data.warehouses;
          loader.hide();
        })
        .catch((error) => {
          that.showAlert(error.response.data.message);
          loader.hide();
          if(error.response.status === 401){
            localStorage.removeItem("userToken");
            that.$router.push('/login');
          }
        });
    },
    getProducts: function() {
      let that = this;
      let loader = this.$loading.show();
      this.dataProductIn.length = 0;
      api
        .getProducts()
        .then(data => {
          that.dataProductIn = data.products;
          loader.hide();
        })
        .catch((error) => {
          that.showAlert(error.response.data.message);
          loader.hide();
          if(error.response.status === 401){
            localStorage.removeItem("userToken");
            that.$router.push('/login');
          }
        });
    },
    registerOrder: function() {
      let dataOrder = this.$data;
      delete dataOrder.dataWarehouse;
      delete dataOrder.dataProductOut;
      delete dataOrder.dataProductOutMin;
      delete dataOrder.dataProductIn;
      delete dataOrder.dataLote;
      this.$emit("registerOrder", dataOrder);
    },
    showAlert: function(message) {
      this.$swal({
        position: "top-end",
        icon: "error",
        title: message,
        showConfirmButton: false,
        timer: 1500
      });
    }
  }
};
</script>
