<template>
  <form @submit.prevent="registerOrder">
    <div id="divSt0">
      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label>Certificado de Ánalisis</label>
            <input id="documento_certificado" type="file" accept="application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/msword, application/vnd.ms-excel, application/pdf, image/*" class="form-control" />
          </div>
          <div class="form-group">
            <a v-if="documento_certificado" href="#" @click="downloadFile(documento_certificado)" class="btn btn-primary"><font-awesome-icon icon="file" /> Ver Documento</a>
          </div>
          <div class="form-group">
            <label>Orden de Compra</label>
            <input id="documento_orden" type="file" accept="application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/msword, application/vnd.ms-excel, application/pdf, image/*" class="form-control" />
          </div>
          <div class="form-group">
            <a v-if="documento_orden" href="#" @click="downloadFile(documento_orden)" class="btn btn-primary"><font-awesome-icon icon="file" /> Ver Documento</a>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label>Solicitud de factura/Remisión</label>
            <input id="documento_factura_remision" type="file" accept="application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/msword, application/vnd.ms-excel, application/pdf, image/*" class="form-control" />
          </div>
          <div class="form-group">
            <a v-if="documento_factura_remision" href="#" @click="downloadFile(documento_factura_remision)" class="btn btn-primary"><font-awesome-icon icon="file" /> Ver Documento</a>
          </div>  
          <div class="form-group">
            <label>Pedido <span v-if="id_pedido"># {{ !id_pedido2 ? "" : id_pedido2.toString().padStart(4, '0') }}</span></label>
            <select v-model="id_pedido" class="form-control" @change="onChangePreorder($event)" >
              <option v-for="row in dataPreorder" :key="row.id" :value="row.id">{{ row.id.toString().padStart(4, '0') }}</option>
            </select>
          </div>
        </div>
      </div>
    </div>
    <div id="divSt1">
      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label>Tracking Number</label>
            <input v-model="numero_tracking" type="text" class="form-control" />
          </div>
          <div class="form-group">
            <label>Empresa</label>
            <input v-model="empresa" type="text" class="form-control" />
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label>Fecha Entregada</label>
            <input v-model="fecha_entregada" type="date" class="form-control" />
          </div>
        </div>
      </div>
    </div>   
    <div id="divSt2">
      <div class="row">
        <div class="col-md-12">    
          <div class="form-group">
            <label>Factura</label>
            <select v-model="id_factura"  @change="onChangeInvoice($event)" class="form-control">
              <option v-for="row in dataInvoice" :key="row.id" :value="row.id">{{ row.prefijo + row.id.toString().padStart(4, '0') + " - " + row.consecutivo }}</option>
            </select>
          </div>
        </div>
      </div>
    </div>
    <div v-if="isEdit" class="row">
      <div class="col-md-12">
        <div class="form-group">
          <label>Observaciones</label>
          <input v-model="observaciones" type="text" class="form-control" />
        </div>
      </div>
    </div>
    <div v-if="dataPreorderDetail.length > 0" class="row mt-2">
      <div class="col-md-12">
        <p class="lead">Detalle Pedido</p>
        <div class="form-group">
          <table class="table table-hover">
            <thead>
              <tr>
                <th>Número Lote</th>
                <th v-if="dataPreorderDetail[0].precio">Fecha Lote</th>
                <th v-if="dataPreorderDetail[0].precio">Fecha Vencimiento</th>
                <th>Producto</th>
                <th>Cantidad</th>
                <th v-if="dataPreorderDetail[0].precio">Precio Unitario</th>
                <th v-if="dataPreorderDetail[0].iva">% Iva</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(row, index) in dataPreorderDetail" :key="row[index]">
                <td>{{ row.lote_numero }}</td>
                <td v-if="row.precio">{{ row.lote_fecha.substr(0,10) }}</td> 
                <td v-if="row.precio">{{ row.lote_vencimiento.substr(0,10) }}</td>
                <td>{{ row.producto }}</td>
                <td>{{ row.cantidad }}</td> 
                <td v-if="row.precio">{{ row.precio }}</td> 
                <td v-if="row.iva">{{ row.iva }}</td>                      
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td colspan="5"></td>
                <td><b>Subtotal:</b> {{ subtotal }}</td>
                <td><b>Total:</b> {{ total }}</td>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </div> 
    <button class="btn btn-primary" type="submit">
      <font-awesome-icon icon="save" /> Guardar
    </button>
  </form>
</template>

<script>
import api from "@/api.js";
export default {
  name: "orderformout",
  props: {
    dataOrder: Object,
  },
  data() {
    return {
      //data
      dataInvoice: [],
      dataPreorder: [],
      dataPreorderDetail: [],
      //object
      id: null,
      id_pedido: null,
      id_pedido2: null,
      id_factura: null,
      documento_orden: null,
      documento_certificado: null,
      documento_factura_remision: null,
      numero_tracking: null,
      empresa: null,
      fecha_entregada: null,
      estado: 0,
      observaciones:[],
      subtotal: 0,
      total: 0,
      //Aux
      isEdit: false,
    };
  },
  watch: {
    dataOrder: function(value) {
      this.$data.id = value.id;
      this.$data.id_factura = value.id_factura;
      this.$data.id_pedido = value.id_pedido;
      this.$data.id_pedido2 = value.id_pedido;
      this.$data.documento_orden = value.documento_orden;
      this.$data.documento_certificado = value.documento_certificado;
      this.$data.documento_factura_remision = value.documento_factura_remision;
      this.$data.numero_tracking = value.numero_tracking;
      this.$data.empresa = value.empresa;
      if(value.fecha_entregada) {
        this.$data.fecha_entregada = value.fecha_entregada.substr(0,10);
      }
      this.$data.estado = value.estado;
      if(value.observaciones != null){
        this.$data.observaciones = value.observaciones[value.estado];
      }
      this.$data.isEdit = true;
      this.showDivStatus();
      console.log(value.estado)
      if(value.estado == 2){
        this.getInvoice();
        this.onChangePreorder(value.id_pedido);
      }
    }
  },
  mounted: function(){
    this.showDivStatus();
    this.getPreorders();
  },
  methods: {
    showDivStatus: function() {
      let that = this;
      document.querySelectorAll("form > div").forEach(function(div, index){
        if(that.estado == index) {
          document.getElementById("divSt" + index).style.display = "block";
        }else{
          if(div.className === ""){
            document.getElementById("divSt" + index).style.display = "none";
          }
        }
      });
    },
    getPreorders: function() {
      let that = this;
      let loader = this.$loading.show();
      this.dataPreorder.length = 0;
      api
        .getPreordersWithout('order')
        .then(data => {
          that.dataPreorder = data.preorders;
          loader.hide();
        })
        .catch((error) => {
          that.showAlert(error.response.data.message);
          loader.hide();
          if(error.response.status === 401){
            localStorage.removeItem("userToken");
            that.$router.push('/login');
          }
        });
    },
    onChangePreorder: function(event) {
      let preorderId = event.target ? event.target.value : event
      let that = this;
      let loader = this.$loading.show();
      this.dataPreorderDetail.length = 0;
      api
        .getPreorder(preorderId)
        .then(data => {
          that.dataPreorderDetail = data.preorder.detalle;
          data.preorder.detalle.forEach(detalle => {
            that.subtotal += Number(detalle.precio * detalle.cantidad);
            that.total += Number(detalle.precio * detalle.cantidad) + Number((detalle.iva / 100) * (detalle.precio * detalle.cantidad));
          });
          loader.hide();
        })
        .catch((error) => {
          that.showAlert("error", error.response.data.message);
          loader.hide();
          if(error.response.status === 401){
            localStorage.removeItem("userToken");
            that.$router.push('/login');
          }
        });
    },
    onChangeInvoice: function(event) {
      let that = this;
      let loader = this.$loading.show();
      this.dataPreorderDetail.length = 0;
      api
        .getInvoice(event.target.value)
        .then(data => {
          that.dataPreorderDetail = data.invoice.detalle;
          loader.hide();
        })
        .catch((error) => {
          that.showAlert("error", error.response.data.message);
          loader.hide();
          if(error.response.status === 401){
            localStorage.removeItem("userToken");
            that.$router.push('/login');
          }
        });
    },
    getInvoice: function() {
      let that = this;
      let loader = this.$loading.show();
      this.dataInvoice.length = 0;
      api
        .getInvoicePreorder(this.$data.id_pedido)
        .then(data => {
          that.dataInvoice.push(data.invoice);
          loader.hide();
        })
        .catch((error) => {
          console.log(error)
          that.showAlert(error.response.data.message);
          loader.hide();
          if(error.response.status === 401){
            localStorage.removeItem("userToken");
            that.$router.push('/login');
          }
        });
    },
    registerOrder: function() {
      let that = this;
      let dataOrder = this.$data;
      switch(dataOrder.estado){
        case 0: {
          if(that.id_pedido) {
            let hasFiles = false;
            let doc1 = document.getElementById("documento_certificado");
            let doc2 = document.getElementById("documento_orden");
            let doc3 = document.getElementById("documento_factura_remision");
            let formData = new FormData();
            if(doc1.files.length > 0) {
              formData.append('documento_certificado', doc1.files[0]);
              hasFiles = true;
            }
            if(doc2.files.length > 0) {
              formData.append('documento_orden', doc2.files[0]);
              hasFiles = true;
            }
            if(doc3.files.length > 0) {
              formData.append('documento_factura_remision', doc3.files[0]);
              hasFiles = true;
            }
            if(hasFiles) {
              dataOrder.files = formData;
            }
            console.log(dataOrder)
            that.emitOrder(dataOrder);
          }else{
            that.showAlert("Completa los campos");
          }
          break;
        }
        case 1: {
          if(that.numero_tracking && that.empresa && dataOrder.fecha_entregada) {
            that.emitOrder(dataOrder);
          }else{
            that.showAlert("Completa los campos");
          }
          break;
        }
        case 2: {
          if(that.id_factura) {
            that.emitOrder(dataOrder);
          }else{
            that.showAlert("Completa los campos");
          }
        }
      }
    },
    downloadFile: function(filename) {
      this.$emit("downloadFile", filename)
    },
    emitOrder: function(dataOrder) {
      delete dataOrder.dataInvoice;
      this.$emit("registerOrder", dataOrder);
    },
    showAlert: function(message) {
      this.$swal({
        position: "top-end",
        icon: "error",
        title: message,
        showConfirmButton: false,
        timer: 1500
      });
    }
  }
};
</script>
