<template>
  <AppLayout>
    <h2 class="mb-4">Editar Orden de {{ type.charAt(0).toUpperCase() + type.slice(1) }}</h2>
    <div class="card mb-4">
      <div class="card-body">
        <OrderFormIn v-if="type === 'entrada'" :idOrder="id" :dataOrder="dataOrder" @registerOrder="editOrder" @downloadFile="downloadFile"></OrderFormIn>
        <OrderFormOut v-else-if="type === 'salida'" :idOrder="id" :dataOrder="dataOrder" @registerOrder="editOrder" @downloadFile="downloadFile"></OrderFormOut>
        <OrderFormWarehouse v-else :idOrder="id" :dataOrder="dataOrder" @registerOrder="editOrder" @downloadFile="downloadFile" :isEdit="true"></OrderFormWarehouse>
      </div>
    </div>
  </AppLayout>
</template>

<script>
import api from "@/api.js";
import AppLayout from "@/components/layout/AppLayout.vue";
import OrderFormIn from "@/components/order/OrderFormIn.vue";
import OrderFormOut from "@/components/order/OrderFormOut.vue";
import OrderFormWarehouse from "@/components/order/OrderFormWarehouse.vue";
export default {
  name: "orderedit",
  components: {
    AppLayout,
    OrderFormIn,
    OrderFormOut,
    OrderFormWarehouse
  },
  data() {
    return {
      type: "",
      id: "",
      dataOrder: {}
    };
  },
  created: function() {
    this.type = this.$route.params.type;
    this.id = this.$route.params.id;
    this.getOrder();
  },
  methods: {
    getOrder: function() {
      let that = this;
      let loader = this.$loading.show();
      this.dataOrder.length = 0;
      if(that.type === "entrada") {
        api
          .getOrderIn(that.id)
          .then((data) => {
            that.dataOrder = data.order;
            loader.hide();
          })
          .catch((error) => {
            that.showAlert("error", error.response.data.message);
            loader.hide();
            if(error.response.status === 401){
              localStorage.removeItem("userToken");
              that.$router.push('/login');
            }
          });
      }else if(that.type === "salida") {
        api
          .getOrderOut(that.id)
          .then((data) => {
            that.dataOrder = data.order;
            loader.hide();
          })
          .catch((error) => {
            that.showAlert("error", error.response.data.message);
            loader.hide();
            if(error.response.status === 401){
              localStorage.removeItem("userToken");
              that.$router.push('/login');
            }
          });
      }else{
        api
          .getOrderWarehouse(that.id)
          .then((data) => {
            that.dataOrder = data.order;
            loader.hide();
          })
          .catch((error) => {
            that.showAlert("error", error.response.data.message);
            loader.hide();
            if(error.response.status === 401){
              localStorage.removeItem("userToken");
              that.$router.push('/login');
            }
          });
      }      
    },
    editOrder: function(dataOrder) {
      let that = this;
      let loader = this.$loading.show();
      let formData = dataOrder.files;
      delete dataOrder.formData;
      if(that.type === "entrada") {
        api
          .editOrderIn(that.id, dataOrder)
          .then(() => {
            if(dataOrder.estado === 1 || dataOrder.estado === 3){
              that.uploadFiles("in", formData, loader);
            }else{
              loader.hide();
              that.showAlert("success", "Orden editada");
              that.$router.push("/orden");
            }
          })
          .catch((error) => {
            that.showAlert("error", error.response.data.message);
            loader.hide();
            if(error.response.status === 401){
              localStorage.removeItem("userToken");
              that.$router.push('/login');
            }
          });
      }else if(that.type === "salida") {
        api
          .editOrderOut(that.id, dataOrder)
          .then(() => {
            if(dataOrder.estado === 0) {
              if(formData){
                that.uploadFiles("out", formData, loader);
              }else{
                loader.hide();
                that.showAlert("success", "Orden editada");
                that.$router.push("/orden");
              }
            }else{
              loader.hide();
              that.showAlert("success", "Orden editada");
              that.$router.push("/orden");
            }
          })
          .catch((error) => {
            that.showAlert("error", error.response.data.message);
            loader.hide();
            if(error.response.status === 401){
              localStorage.removeItem("userToken");
              that.$router.push('/login');
            }
          });
      }else{
        api
          .editOrderWarehouse(that.id, dataOrder)
          .then(() => {
            if(dataOrder.estado === 2) {
              that.uploadFiles("warehouse", formData, loader);
            }else{
              loader.hide();
              that.showAlert("success", "Orden editada");
              that.$router.push("/orden");
            }
          })
          .catch((error) => {
            that.showAlert("error", error.response.data.message);
            loader.hide();
            if(error.response.status === 401){
              localStorage.removeItem("userToken");
              that.$router.push('/login');
            }
          });
      }
    },
    downloadFile: function(filename) {
      let that = this;
      let loader = this.$loading.show();
      let type = this.type === "entrada" ? "in" : "out"
      api
        .downloadFile(type, this.id, filename)
        .then(data => {
          loader.hide();
          window.open(data.urlFile, '_blank')
        })
        .catch((error) => {
          that.showAlert(error.response.data.message);
          loader.hide();
          if(error.response.status === 401){
            localStorage.removeItem("userToken");
            that.$router.push('/login');
          }
        });
    },
    uploadFiles: function(type, formData, loader) {
      let that = this;
      api
        .uploadFiles(type, that.id, formData)
        .then(() => {
          that.showAlert("success", "Orden editada");
          loader.hide();
          that.$router.push("/orden");
        })
        .catch((error) => {
          that.showAlert("error", error.response.data.message);
          loader.hide();
          if(error.response.status === 401){
            localStorage.removeItem("userToken");
            that.$router.push('/login');
          }
        });
    },
    showAlert: function(type, message) {
      this.$swal({
        position: "top-end",
        icon: type,
        title: message,
        showConfirmButton: false,
        timer: 1500
      });
    }
  }
};
</script>
