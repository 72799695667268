<template>
  <AppLayout>
    <h2 class="mb-4">Registro de Pronostico</h2>
    <div class="card mb-4">
      <div class="card-body">
        <form @submit.prevent="registerForecast">
          <div class="form-group">
            <label>Escoge un Producto</label>
            <v-select :searchable="true" :options="dataProduct" v-model="id_producto" />
          </div>
          <div class="form-group">
            <label>Fecha</label>
            <input v-model="fecha" type="date" class="form-control" />
          </div>
          <div class="form-group">
            <label>Valor</label>
            <input v-model="valor" type="text" class="form-control" />
          </div>
          <div class="form-group">
            <label>Tipo</label>
            <select v-model="tipo" class="form-control">
              <option value="1">Porcentaje</option>
              <option value="2">Cantidad</option>
            </select>
          </div>
          <div class="form-group">
            <label>Observaciones</label>
            <input v-model="observaciones" type="text" class="form-control" />
          </div>
          <button class="btn btn-primary" type="submit">
            <font-awesome-icon icon="save" /> Registrar
          </button>
        </form>
      </div>
    </div>
  </AppLayout>
</template>

<script>
import api from "@/api.js"
import AppLayout from "@/components/layout/AppLayout.vue";
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';
export default {
  name: "forecastregister",
  components: {
    AppLayout,
    vSelect
  },
  data() {
    return {
      nodeForecast: "pronostico",
      dataProduct: [],
      fecha: "",
      valor: "",
      observaciones: "",
      tipo: "",
      id_producto: "",
      id_usuario: ""
    };
  },
  mounted: function () {
    this.$data.id_usuario = localStorage.getItem("userId");
    this.getProducts();
  },
  methods: {
    getProducts: function() {
      let that = this;
      api
        .getProducts()
        .then((data) => {
          data.products.forEach(product => {
            that.dataProduct.push({code: product.id, label: product.nombre});
          })
        })
        .catch((error) => {
          that.showAlert(error.response.data.message);
          if(error.response.status === 401){
            localStorage.removeItem("userToken");
            that.$router.push('/login');
          }
        });
    },
    registerForecast: function() {
      if(this.$data.id_producto != ""){
        let that = this;
        let loader = this.$loading.show();
        api
          .registerForecast(that.$data)
          .then(() => {
            loader.hide();
            that.$router.push('/' + that.nodeForecast)
          })
          .catch((error) => {
            that.showAlert(error.response.data.message);
            loader.hide();
            if(error.response.status === 401){
              localStorage.removeItem("forecastToken");
              that.$router.push('/login');
            }
          });
      }else{
        this.showAlert("Escoge un producto");
      }
    },
    showAlert: function(message) {
      this.$swal({
        position: "top-end",
        icon: "error",
        title: message,
        showConfirmButton: false,
        timer: 1500
      });
    }
  }
};
</script>
