<template>
  <AppLayout>
    <h2 class="mb-4">Editar Pronostico</h2>
    <div class="card mb-4">
      <div class="card-body">
        <form @submit.prevent="editForecast">
          <div class="form-group">
            <label>Escoge un Producto</label>
            <select  v-model="dataForecast.id_producto" class="form-control">
              <option v-for="row in dataProduct" :key="row.id" :value="row.id">{{ row.nombre }}</option>
            </select>
          </div>
          <div class="form-group">
            <label>Fecha</label>
            <input v-model="dataForecast.fecha" type="date" class="form-control" />
          </div>
          <div class="form-group">
            <label>Valor</label>
            <input v-model="dataForecast.valor" type="text" class="form-control" />
          </div>          
          <div class="form-group">
            <label>Tipo</label>
            <select v-model="dataForecast.tipo" class="form-control">
              <option value="1">Porcentaje</option>
              <option value="2">Cantidad</option>
            </select>
          </div>
          <div class="form-group">
            <label>Observaciones</label>
            <input v-model="dataForecast.observaciones" type="text" class="form-control" />
          </div>   
          <button class="btn btn-primary" type="submit">
            <font-awesome-icon icon="save" /> Guardar
          </button>
        </form>
      </div>
    </div>
  </AppLayout>
</template>

<script>
import api from "@/api.js"
import AppLayout from "@/components/layout/AppLayout.vue"
export default {
  name: "forecastedit",
  components: {
    AppLayout,
  },
  data() {
    return {
      id: "",
      nodeForecast: "pronostico",
      dataForecast: {},
      dataProduct: []
    };
  },
  mounted() {
    this.id = this.$route.params.id;
    this.$data.dataForecast.id_usuario = localStorage.getItem("userId");
    this.getProducts();
  },
  methods: {
    getProducts: function() {
      let that = this;
      api
        .getProducts()
        .then((data) => {
          that.dataProduct = data.products
          that.getForecast(that.id);
        })
        .catch((error) => {
          that.showAlert(error.response.data.message);
          if(error.response.status === 401){
            localStorage.removeItem("userToken");
            that.$router.push('/login');
          }
        });
    },
    getForecast: function(id) {
      let that = this;
      let loader = this.$loading.show();
      api
        .getForecast(id)
        .then((data) => {
          that.dataForecast = data.forecast;
          that.$data.dataForecast.fecha = data.forecast.fecha.substr(0,10)
          loader.hide();
        })
        .catch((error) => {
          that.showAlert(error.response.data.message);
          loader.hide();
          if(error.response.status === 401){
            localStorage.removeItem("forecastToken");
            that.$router.push('/login');
          }
        });
    },
    editForecast: function() {
      if(this.$data.id_producto != ""){
        let that = this;
        that.dataForecast.modulos = [];
        document.getElementsByName("modulos").forEach(modulo => {
          if(modulo.checked){
            that.dataForecast.modulos.push(modulo.value)
          }
        });
        let loader = this.$loading.show();
        api
          .editForecast(that.id, that.$data.dataForecast)
          .then(() => {
            loader.hide();
            that.$router.push('/' + that.nodeForecast);
          })
          .catch((error) => {
            that.showAlert(error.response.data.message);
            loader.hide();
            if(error.response.status === 401){
              localStorage.removeItem("forecastToken");
              that.$router.push('/login');
            }
          });
      }else{
        this.showAlert("Escoge un producto");
      }   
    },
    showAlert: function(message) {
      this.$swal({
        position: "top-end",
        icon: "error",
        title: message,
        showConfirmButton: false,
        timer: 1500
      });
    }
  }
};
</script>
