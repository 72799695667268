<template>
  <AppLayout>
    <h2 class="mb-4">
      Listado de Usuarios
      <router-link
        class="btn btn-primary float-right"
        :to="{ path: '/' + nodeUser + '/register' }"
      >
        <font-awesome-icon icon="plus" /> Registrar
      </router-link>
    </h2>
    <div class="card mb-4">
      <div class="card-body">
        <table id="table"  class="table table-bordered">
          <thead>
            <tr>
              <th>Nombre</th>
              <th>Email</th>
              <th>Tipo</th>
              <th>Acciones</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="row in dataUser" :key="row.id">
              <td>{{ row.nombre }}</td>
              <td>{{ row.email }}</td>
              <td>{{ row.tipo }}</td>
              <td>
                <router-link
                  class="btn btn-primary"
                  :to="{ path: '/' + nodeUser + '/edit/' + row.id }"
                >
                  <font-awesome-icon icon="edit" /> Editar
                </router-link>&nbsp;
                <button class="btn btn-danger" @click="deleteUser(row.id)">
                  <font-awesome-icon icon="trash-alt" /> Eliminar
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </AppLayout>
</template>

<script>
const $ = require("jquery");
$.DataTable = require("datatables.net");
import "datatables.net-bs4/css/dataTables.bootstrap4.min.css";
import "datatables.net-bs4/js/dataTables.bootstrap4.min.js";
import "datatables.net-buttons/js/dataTables.buttons.min.js";
import "datatables.net-buttons/js/buttons.html5.min.js";
import "datatables.net-buttons/js/buttons.flash.min.js";
import "datatables.net-buttons/js/buttons.print.min.js";
import "datatables.net-buttons-bs4/css/buttons.bootstrap4.min.css";
import "datatables.net-buttons-bs4/js/buttons.bootstrap4.min.js";
window.JSZip = require( 'jszip' );
require( 'pdfmake' );

import api from "@/api.js"
import AppLayout from "@/components/layout/AppLayout.vue"
export default {
  name: "userlist",
  components: {
    AppLayout
  },
  data() {
    return {
      dataUser: [],
      nodeUser: "usuario"
    };
  },
  created: function() {
    this.getUsers();
  },
  updated: function () {
    $("#table").DataTable({ 
      retrieve: true,
      dom: 'Blfrtip', 
      buttons: [ 
        { 
          extend: 'excel', className: 'btn btn-secondary'
        },
      ],
      language: {
        "url": "//cdn.datatables.net/plug-ins/9dcbecd42ad/i18n/Spanish.json"
      },
    });
  },
  methods: {
    getUsers: function() {
      let loader = this.$loading.show();
      let that = this;
      this.dataUser.length = 0;
      api
        .getUsers()
        .then((data) => {
          that.dataUser = data.users;
          loader.hide();
        })
        .catch((error) => {
          that.showAlert(error.response.data.message);
          loader.hide();
          if(error.response.status === 401){
            localStorage.removeItem("userToken");
            that.$router.push('/login');
          }
        });
    },
    deleteUser: function(id) {
      let that = this;
      this.$swal({
        title: "Quieres Eliminar?",
        text: "Está seguro de querer eliminar esto",
       icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si",
        cancelButtonText: "No"
      }).then(result => {
        if (result.value) {
          let loader = that.$loading.show();
          api
            .deleteUser(id)
            .then(() => {
              that.dataUser = that.dataUser.filter(user => user.id != id);
              loader.hide();
            })
            .catch((error) => {
              that.showAlert(error.response.data.message);
              loader.hide();
              if(error.response.status === 401){
                localStorage.removeItem("userToken");
                that.$router.push('/login');
              }
            });
        }
      });
    },
    showAlert: function(message) {
      this.$swal({
        position: "top-end",
        icon: "error",
        title: message,
        showConfirmButton: false,
        timer: 1500
      });
    }
  }
};
</script>
