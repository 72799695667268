<template>
  <AppLayout>
    <h2 class="mb-4">
      Resumen de Pronosticos
      <button
        class="btn btn-success float-right" @click="download()"
      >
        <font-awesome-icon icon="file-excel" /> Descargar
      </button>
    </h2>
    <div class="card mb-4">
      <div class="card-body">
        <div class="row mb-3">
          <div class="col-md-4 offset-md-8">
            <label>Filtrar por prducto</label>
            <select class="form-control" v-model="idProduct" @change="filterResume($event)">
              <option v-for="row in dataProduct" :key="row.id" :value="row.id">{{ row.name }}</option>
            </select>
          </div>
        </div>
        <table id="table"  class="table table-bordered">
          <thead>
            <tr>
              <th>Producto</th>
              <th>Semana</th>
              <th>Demanda</th>
              <th>Entradas</th>
              <th>Pronostico</th>
              <th>Pronostico Ajustado</th>
              <th>Inventario Esperado</th>
              <th>Stock Seguridad</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="row in dataForecast" :key="row.id">
              <td v-if="(row.inventario_esperado != null) && (row.stock_seguridad_leadtime > row.inventario_esperado) && (row.inventario_esperado > 0)" style="background-color:#fff4b4;">{{ row.nombre }}</td>
              <td v-else-if="(row.inventario_esperado != null) && (row.inventario_esperado <= 0)" style="background-color:#ffc2b4;">{{ row.nombre }}</td>
              <td v-else>{{ row.nombre }}</td>
              <td v-if="(row.inventario_esperado != null) && (row.stock_seguridad_leadtime > row.inventario_esperado) && (row.inventario_esperado > 0)" style="background-color:#fff4b4;">{{ row.dia.substr(0,10) }}</td>
              <td v-else-if="(row.inventario_esperado != null) && (row.inventario_esperado <= 0)" style="background-color:#ffc2b4;">{{ row.dia.substr(0,10) }}</td>
              <td v-else>{{ row.dia.substr(0,10) }}</td>
              <td v-if="(row.inventario_esperado != null) && (row.stock_seguridad_leadtime > row.inventario_esperado) && (row.inventario_esperado > 0)" style="background-color:#fff4b4;">{{ row.demanda }}</td>
              <td v-else-if="(row.inventario_esperado != null) && (row.inventario_esperado <= 0)" style="background-color:#ffc2b4;">{{ row.demanda }}</td>
              <td v-else>{{ row.demanda }}</td>
              <td v-if="(row.inventario_esperado != null) && (row.stock_seguridad_leadtime > row.inventario_esperado) && (row.inventario_esperado > 0)" style="background-color:#fff4b4;">{{ row.entradas }}</td>
              <td v-else-if="(row.inventario_esperado != null) && (row.inventario_esperado <= 0)" style="background-color:#ffc2b4;">{{ row.entradas }}</td>
              <td v-else>{{ row.entradas }}</td>
              <td v-if="(row.inventario_esperado != null) && (row.stock_seguridad_leadtime > row.inventario_esperado) && (row.inventario_esperado > 0)" style="background-color:#fff4b4;">{{ row.forecast }}</td>
              <td v-else-if="(row.inventario_esperado != null) && (row.inventario_esperado <= 0)" style="background-color:#ffc2b4;">{{ row.forecast }}</td>
              <td v-else>{{ row.forecast }}</td>
              <td v-if="(row.inventario_esperado != null) && (row.stock_seguridad_leadtime > row.inventario_esperado) && (row.inventario_esperado > 0)" style="background-color:#fff4b4;">{{ row.forecast_adjusted }}</td>
              <td v-else-if="(row.inventario_esperado != null) && (row.inventario_esperado <= 0)" style="background-color:#ffc2b4;">{{ row.forecast_adjusted }}</td>
              <td v-else>{{ row.forecast_adjusted }}</td>
              <td v-if="(row.inventario_esperado != null) && (row.stock_seguridad_leadtime > row.inventario_esperado) && (row.inventario_esperado > 0)" style="background-color:#fff4b4;">{{ row.inventario_esperado }}</td>
              <td v-else-if="(row.inventario_esperado != null) && (row.inventario_esperado <= 0)" style="background-color:#ffc2b4;">{{ row.inventario_esperado }}</td>
              <td v-else>{{ row.inventario_esperado }}</td>
              <td v-if="(row.inventario_esperado != null) && (row.stock_seguridad_leadtime > row.inventario_esperado) && (row.inventario_esperado > 0)" style="background-color:#fff4b4;">{{ row.stock_seguridad_leadtime }}</td>
              <td v-else-if="(row.inventario_esperado != null) && (row.inventario_esperado <= 0)" style="background-color:#ffc2b4;">{{ row.stock_seguridad_leadtime }}</td>
              <td v-else>{{ row.stock_seguridad_leadtime }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </AppLayout>
</template>

<script>
import api from "@/api.js"
import AppLayout from "@/components/layout/AppLayout.vue"
export default {
  name: "forecastresume",
  components: {
    AppLayout,
  },
  data() {
    return {
      idProduct: null,
      dataProduct: [],
      dataForecast: [],
      dataForecasts: [],
      nodeForecast: "pronostico",
      moduleForecast: "Pronosticos",
      userType: "Admin",
      userModules: []
    };
  },
  created: function() {
    this.getForecastsResume();
    this.$data.userType = localStorage.getItem("userType");
    this.$data.userModules = localStorage.getItem("userModules");
  },
  methods: {
    download: function(){
      var tmpElemento = document.createElement('a');
      var data_type = 'data:application/vnd.ms-excel';
      var tabla_div = document.getElementById('table');
      var tabla_html = tabla_div.outerHTML.replace(/ /g, '%20');
      tmpElemento.href = data_type + ', ' + tabla_html;
      tmpElemento.download = 'Pronostico_Resumen.xls';
      tmpElemento.click();
    },
    filterResume: function (event){
      this.dataForecast = this.dataForecasts.filter(forecast => forecast.id_producto == event.target.value);
    },
    getForecastsResume: function() {
      let loader = this.$loading.show();
      let that = this;
      this.dataForecast.length = 0;
      this.dataProduct.length = 0;
      api
        .getForecastsResume()
        .then((data) => {
          that.dataForecast = data.forecasts;
          that.dataForecasts = data.forecasts;
          let products = []
          data.forecasts.forEach(forecast => {
            products.push({id: forecast.id_producto, name: forecast.nombre})
          })
          var setObj = new Set()
          var result = products.reduce((acc,item)=>{
            if(!setObj.has(item.id)){
              setObj.add(item.id,item)
              acc.push(item)
            }
            return acc;
          },[]);
          that.dataProduct = result
          loader.hide();
        })
        .catch((error) => {
          that.showAlert(error.response.data.message);
          loader.hide();
          if(error.response.status === 401){
            localStorage.removeItem("userToken");
            that.$router.push('/login');
          }
        });
    },
    showAlert: function(message) {
      this.$swal({
        position: "top-end",
        icon: "error",
        title: message,
        showConfirmButton: false,
        timer: 1500
      });
    }
  }
};
</script>
