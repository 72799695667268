<template>
  <AppLayout>
    <h2 class="mb-4">Editar Usuario</h2>
    <div class="card mb-4">
      <div class="card-body">
        <form @submit.prevent="editUser">
          <div class="form-group">
            <label>Nombre</label>
            <input v-model="dataUser.nombre" type="text" class="form-control" />
          </div>
          <div class="form-group">
            <label>Apellido</label>
            <input v-model="dataUser.apellido" type="text" class="form-control" />
          </div>
          <div class="form-group">
            <label>Email</label>
            <input v-model="dataUser.email" type="text" class="form-control" />
          </div>
          <div class="form-group">
            <label>Tipo</label>
            <select v-model="dataUser.id_tipo" class="form-control">
              <option v-for="row in dataUsersType" :key="row.id" :value="row.id">{{ row.nombre }}</option>
            </select>
          </div>
          <div v-show="dataUser.id_tipo != 1" class="form-group">
            <label>Módulos a Trabajar</label>
            <div class="form-check" v-for="row in dataModules" :key="row.id">              
              <input class="form-check-input" type="checkbox" name="modulos" v-if="dataUser.modulos && dataUser.modulos.includes(row.nombre)" :value="row.id" checked>
              <input class="form-check-input" type="checkbox" name="modulos" v-else :value="row.id">
              <label class="form-check-label">
                {{ row.nombre}}
              </label>
            </div>
          </div>
          <button class="btn btn-primary" type="submit">
            <font-awesome-icon icon="save" /> Guardar
          </button>
        </form>
      </div>
    </div>
  </AppLayout>
</template>

<script>
import api from "@/api.js"
import AppLayout from "@/components/layout/AppLayout.vue"
export default {
  name: "useredit",
  components: {
    AppLayout
  },
  data() {
    return {
      id: "",
      nodeUser: "usuario",
      dataUser: {},
      dataUsersType: [],
      dataModules: []
    };
  },
  mounted() {
    this.getUsersType();
    this.getModules();
    this.id = this.$route.params.id;
    this.getUser(this.id);
  },
  methods: {
    getUsersType: function() {
      let that = this;
      let loader = this.$loading.show();
      this.dataUsersType.length = 0;
      api
        .getUsersType()
        .then((data) => {
          that.dataUsersType = data.userstype;
          loader.hide();
        })
        .catch((error) => {
          that.showAlert("error", error.response.data.message);
          loader.hide();
          if(error.response.status === 401){
            localStorage.removeItem("userToken");
            that.$router.push('/login');
          }
        });
    },
    getModules: function() {
      let that = this;
      let loader = this.$loading.show();
      this.dataModules.length = 0;
      api
        .getModules()
        .then((data) => {
          that.dataModules = data.modules;
          loader.hide();
        })
        .catch((error) => {
          that.showAlert("error", error.response.data.message);
          loader.hide();
          if(error.response.status === 401){
            localStorage.removeItem("userToken");
            that.$router.push('/login');
          }
        });
    },
    getUser: function(id) {
      let that = this;
      let loader = this.$loading.show();
      api
        .getUser(id)
        .then((data) => {
          that.dataUser = data.user;
          loader.hide();
        })
        .catch((error) => {
          that.showAlert(error.response.data.message);
          loader.hide();
          if(error.response.status === 401){
            localStorage.removeItem("userToken");
            that.$router.push('/login');
          }
        });
    },
    editUser: function() {
      let that = this;
      that.dataUser.modulos = [];
      document.getElementsByName("modulos").forEach(modulo => {
        if(modulo.checked){
          that.dataUser.modulos.push(modulo.value)
          modulo.checked = true
        }
      });
      let loader = this.$loading.show();
      api
        .editUser(that.id, that.$data.dataUser)
        .then(() => {
          loader.hide();
          that.$router.push('/' + that.nodeUser);
        })
        .catch((error) => {
          that.showAlert(error.response.data.message);
          loader.hide();
          if(error.response.status === 401){
            localStorage.removeItem("userToken");
            that.$router.push('/login');
          }
        });   
    },
    showAlert: function(message) {
      this.$swal({
        position: "top-end",
        icon: "error",
        title: message,
        showConfirmButton: false,
        timer: 1500
      });
    }
  }
};
</script>
