<template>
  <AppLayout>
    <h2 class="mb-4">Registro de Devolucion</h2>
    <div class="card mb-4">
      <div class="card-body">
        <form @submit.prevent="registerRefund">
          <div id="divSt0">
            <div class="row">
              <div class="col-md-3">
                <div class="form-group">
                  <label>Factura</label>
                  <v-select :searchable="true" :options="dataInvoice2" v-model="id_factura" @input="onChangeInvoice($event)" />
                  <!--
                  <select v-model="id_factura" @change="onChangeInvoice($event)" class="select form-control">
                    <option v-for="row in dataInvoice" :key="row.id" :value="row.id">{{ row.id.toString().padStart(4, '0') + " - orden " + row.id_orden }}</option>
                  </select>
                  -->
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <label>Bodega Destino</label>
                  <select v-model="id_bodega" class="form-control">
                    <option v-for="row in dataWarehouse" :key="row.id" :value="row.id">{{ row.nombre }}</option>
                  </select>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label>Bodega Origen</label>
                  <input id="warehouseOrigin" type="text" disabled class="form-control" />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-3">
                <div class="form-group">
                  <label>Quien autoriza</label>
                  <select v-model="autorizante" class="form-control">
                    <option v-for="row in dataUser" :key="row.id" :value="row.id">{{ row.nombre }}</option>
                  </select>
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <label>Fecha de Devolucion</label>
                  <input v-model="fecha_devolucion" type="date" class="form-control" />
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label>Observación</label>
                  <input v-model="observacion" type="text" class="form-control" />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <div class="form-group">
                  <table class="table table-hover">
                    <thead>
                      <tr>
                        <th>Número Lote</th>
                        <th>Producto</th>
                        <th>Cantidad</th>
                        <th>Costo</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(row, index) in detalle" :key="row[index]">
                        <td>{{ row.lote_numero }}</td>
                        <td>
                          {{ row.producto }}
                          <input v-model="row.id_producto" type="hidden" />
                        </td>
                        <td>
                          <input v-model="row.cantidad" type="number" min="0" :max="row.cantidad2" :placeholder="row.cantidad2" class="form-control" />
                        </td>
                        <td>
                          <input v-model="row.costo"  type="number" class="form-control" />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <button class="btn btn-primary" type="submit">
              <font-awesome-icon icon="save" /> Guardar
            </button>
          </div>
        </form>
      </div>      
    </div>
  </AppLayout>
</template>

<script>
import api from "@/api.js"
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';
import AppLayout from "@/components/layout/AppLayout.vue";

export default {
  name: "refundregister",
  components: {
    AppLayout,
    vSelect
  },
  data() {
    return {
      nodeRefund: "factura",
      dataInvoice: [],
      dataInvoice2: [],
      dataWarehouse: [],
      dataUser: [],
      //data
      id: null,
      id_orden: null,
      id_factura: null,
      id_bodega: null,
      cantidad: null,
      autorizante: null,
      id_autorizante: null,
      fecha_devolucion: null,
      observacion: null,
      detalle: [],
    };
  },
  mounted: function(){
    this.getInvoices();
    this.getWarehouses();
    this.getUsers();
  },
  methods: {
    getInvoices: function() {
      let that = this;
      let loader = this.$loading.show();
      this.dataInvoice.length = 0;
      api
        .getInvoicesWith()
        .then(data => {
          that.dataInvoice = data.invoices;
          that.dataInvoice2 = [];
          data.invoices.forEach(invoice => {
            that.dataInvoice2.push({code: invoice.id, label: invoice.id.toString().padStart(4, '0') + " - orden " + invoice.id_orden});
          })
          loader.hide();
        })
        .catch((error) => {
          that.showAlert("error", error.response.data.message);
          loader.hide();
          if(error.response.status === 401){
            localStorage.removeItem("userToken");
            that.$router.push('/login');
          }
        });
    },
    getWarehouses: function() {
      let that = this;
      let loader = this.$loading.show();
      this.dataWarehouse.length = 0;
      api
        .getWarehouses()
        .then((data) => {
          that.dataWarehouse = data.warehouses;
          loader.hide();
        })
        .catch((error) => {
          that.showAlert("error", error.response.data.message);
          loader.hide();
          if(error.response.status === 401){
            localStorage.removeItem("userToken");
            that.$router.push('/login');
          }
        });
    },
    getUsers: function() {
      let that = this;
      let loader = this.$loading.show();
      this.dataUser.length = 0;
      api
        .getUsers()
        .then((data) => {
          that.dataUser = data.users;
          loader.hide();
        })
        .catch((error) => {
          that.showAlert("error", error.response.data.message);
          loader.hide();
          if(error.response.status === 401){
            localStorage.removeItem("userToken");
            that.$router.push('/login');
          }
        });
    },
    registerRefund: function() {
      let that = this;
      let loader = this.$loading.show();
      let dataRefund = this.$data;
      if(that.fecha_devolucion && that.id_factura && that.id_bodega) {
        if(this.detalle.length > 0) {
          dataRefund.cantidad = this.detalle.length;
          delete dataRefund.dataInvoice;
          delete dataRefund.dataWarehouse;
          delete dataRefund.dataUser;
          dataRefund.id_autorizante = localStorage.getItem("userId")
          dataRefund.id_factura = dataRefund.id_factura.code
          api
            .registerRefund(dataRefund)
            .then(data => {
              that.showAlert("success", "Devolución generada con el consecutivo: " + data.id.toString().padStart(4, "0"));
              loader.hide();
              that.$router.push("/devolucion");
            })
            .catch((error) => {
              that.showAlert("error", error.response.data.message);
              loader.hide();
              if(error.response.status === 401){
                localStorage.removeItem("userToken");
                that.$router.push('/login');
              }
            });
        }else{
          loader.hide();
          that.showAlert("error", "Completa el detalle");
        }
      }else{
          loader.hide();
        that.showAlert("error", "Completa los campos");
      }
    },
    onChangeInvoice: function(event) {
      console.log(event.code)
      let that = this;
      let loader = this.$loading.show();
      this.detalle = [];
      api
        .getInvoice(event.code)
        .then(data => {
          data.invoice.detalle.forEach(detalle => {
            that.detalle.push({ id_lote: detalle.id_lote, lote_numero: detalle.lote_numero, producto: detalle.producto, cantidad2: detalle.cantidad, id_producto: detalle.id_producto, cantidad: 0, costo: 0 })
          });
          document.getElementById("warehouseOrigin").value = data.invoice.bodega;
          that.id_orden = data.invoice.id_orden;
          loader.hide();
        })
        .catch((error) => {
          that.showAlert("error", error.response.data.message);
          loader.hide();
          if(error.response.status === 401){
            localStorage.removeItem("userToken");
            that.$router.push('/login');
          }
        });
    },
    showAlert: function(type, message) {
      this.$swal({
        position: "top-end",
        icon: type,
        title: message,
        showConfirmButton: false,
        timer: 1500
      });
    }
  }
};
</script>
