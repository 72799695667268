<template>
  <AppLayout>
    <h2 class="mb-4">Registrar Orden de {{ type.charAt(0).toUpperCase() + type.slice(1) }}</h2>
    <div class="card mb-4">
      <div class="card-body">
        <OrderFormIn v-if="type === 'entrada'" @registerOrder="registerOrder"></OrderFormIn>
        <OrderFormOut v-else-if="type === 'salida'" @registerOrder="registerOrder"></OrderFormOut>
        <OrderFormWarehouse v-else-if="type === 'bodega'" @registerOrder="registerOrder"></OrderFormWarehouse>
        <OrderFormProduct v-else @registerOrder="registerOrder"></OrderFormProduct>
      </div>
    </div>
  </AppLayout>
</template>

<script>
import api from "@/api.js";
import AppLayout from "@/components/layout/AppLayout.vue";
import OrderFormIn from "@/components/order/OrderFormIn.vue";
import OrderFormOut from "@/components/order/OrderFormOut.vue";
import OrderFormWarehouse from "@/components/order/OrderFormWarehouse.vue";
import OrderFormProduct from "@/components/order/OrderFormProduct.vue";
export default {
  name: "orderregister",
  components: {
    AppLayout,
    OrderFormIn,
    OrderFormOut,
    OrderFormWarehouse,
    OrderFormProduct
  },
  data() {
    return {
      type: "",
    };
  },
  mounted: function() {
    this.type = this.$route.params.type;
  },
  watch: {
    '$route' (to, from) {
      console.log("to: " + to + " from: " + from)
      this.type = this.$route.params.type;
    }
  },
  methods: {
    registerOrder: function(dataOrder) {
      let that = this;
      let loader = this.$loading.show();
      let formData = dataOrder.files;
      delete dataOrder.formData;
      if(that.type === "entrada") {
        api
          .registerOrderIn(dataOrder)
          .then(order => {
            that.showAlert("success", "Orden generada con el consecutivo: " + order.id.toString().padStart(4, "0"));
            loader.hide();
            that.$router.push("/orden");
          })
          .catch((error) => {
            that.showAlert("error", error.response.data.message);
            loader.hide();
            if(error.response.status === 401){
              localStorage.removeItem("userToken");
              that.$router.push('/login');
            }
          });
      }else if(that.type === "salida") {
        api
          .registerOrderOut(dataOrder)
          .then(order => {
            if(formData){
              api
                .uploadFiles("out", order.id, formData)
                .then(() => {
                  that.showAlert("success", "Orden generada con el consecutivo: " + order.id.toString().padStart(4, "0"));
                  loader.hide();
                  that.$router.push("/orden");
                })
                .catch((error) => {
                  that.showAlert(error.response.data.message);
                  loader.hide();
                  if(error.response.status === 401){
                    localStorage.removeItem("userToken");
                    that.$router.push('/login');
                  }
                });
            }else{
              that.showAlert("success", "Orden generada con el consecutivo: " + order.id.toString().padStart(4, "0"));
              loader.hide();
              that.$router.push("/orden");
            }
          })
          .catch((error) => {
            that.showAlert("error", error.response.data.message);
            loader.hide();
            if(error.response.status === 401){
              localStorage.removeItem("userToken");
              that.$router.push('/login');
            }
          });
      }else if(that.type === "bodega") {
        api
          .registerOrderWarehouse(dataOrder)
          .then(order => {
            that.showAlert("success", "Orden generada con el consecutivo: " + order.id.toString().padStart(4, "0"));
            loader.hide();
            that.$router.push("/orden");
          })
          .catch((error) => {
            that.showAlert("error", error.response.data.message);
            loader.hide();
            if(error.response.status === 401){
              localStorage.removeItem("userToken");
              that.$router.push('/login');
            }
          });
      }else{
        api
          .registerOrderProduct(dataOrder)
          .then(order => {
            that.showAlert("success", "Orden generada con el consecutivo: " + order.id.toString().padStart(4, "0"));
            loader.hide();
            that.$router.push("/orden");
          })
          .catch((error) => {
            that.showAlert("error", error.response.data.message);
            loader.hide();
            if(error.response.status === 401){
              localStorage.removeItem("userToken");
              that.$router.push('/login');
            }
          });
      }
    },
    showAlert: function(type, message) {
      this.$swal({
        position: "top-end",
        icon: type,
        title: message,
        showConfirmButton: false,
        timer: 1500
      });
    }
  }
};
</script>
