<template>
  <AppLayout>
    <h2 class="mb-4">Home</h2>
    <div class="row">
      <div class="col-md-3">
        <div class="card mb-4">
          <router-link to="/cliente">
            <div class="card-body text-center">
              <font-awesome-icon icon="user" class="text-secondary fa-3x mb-2" />
              <h2 class="text-secondary text-bold-700 mt-1 mb-2">{{ countClient }}</h2>
              <p class="mb-2 text-secondary">Clientes</p>
            </div>
          </router-link>
        </div>
      </div>
      <div class="col-md-3">
        <div class="card mb-4">
          <router-link to="/bodega">
            <div class="card-body text-center">
              <font-awesome-icon icon="truck-loading" class="text-secondary fa-3x mb-2" />
              <h2 class="text-secondary text-bold-700 mt-1 mb-2">{{ countWarehouse }}</h2>
              <p class="text-secondary mb-2">Bodegas</p>
            </div>
          </router-link>
        </div>
      </div>
      <div class="col-md-3">
        <div class="card mb-4">
          <router-link to="/producto">
            <div class="card-body text-center">
              <font-awesome-icon icon="boxes" class="text-secondary fa-3x mb-2" />
              <h2 class="text-secondary text-bold-700 mt-1 mb-2">{{ countProduct }}</h2>
              <p class="text-secondary mb-2">Productos</p>
            </div>
          </router-link>
        </div>
      </div>
      <div class="col-md-3">
        <div class="card mb-4">
          <router-link to="/orden">
            <div class="card-body text-center">
              <font-awesome-icon icon="truck-loading" class="text-secondary fa-3x mb-2" />
              <h2 class="text-secondary text-bold-700 mt-1 mb-2">{{ countOrder }}</h2>
              <p class="text-secondary mb-2">Ordenes</p>
            </div>
          </router-link>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="row">
          <div class="col-md-2">
            <label>Escoge un tipo de informe</label>
            <select class="form-control" v-model="type" @change="getMovementsType($event)">
              <option value="weekly">Semanales</option>
              <option value="monthly">Mensuales</option>
            </select>
          </div>
          <div class="col-md-2">
            <label>Fecha Inicial</label>
            <input type="date" v-model="dateStart" class="form-control" @change="getMovementsDate()" />
          </div>
          <div class="col-md-2">
            <label>Fecha Final</label>
            <input type="date" v-model="dateEnd" class="form-control" @change="getMovementsDate()" />
          </div>
          <div class="col-md-3">
            <label>Escoge un Producto</label>
            <v-select style="width:250px" :searchable="true" :options="dataProduct0" v-model="idProduct" @input="getMovementsProduct($event)" />
          </div>
          <div class="col-md-3">
            <label>Escoge una Bodega</label>
            <v-select style="width:250px" :searchable="true" :options="dataWarehouse0" v-model="idWarehouse" @input="getMovementsWarehouse($event)" />
          </div>
        </div>
        <GChart class="mt-3" v-if="loaded" type="LineChart" :data="chartData" :options="chartOptions" />
      </div>
    </div>
    <div class="row mt-5">
      <div class="col-md-12">
        <h3>Inventario Actual</h3>
        <div class="table-responsive" style="height:400px">
          <table id="table" class="table table-bordered">
            <thead>
              <tr>
                <th>Producto</th>
                <th>Fecha Ult Movimiento</th>
                <th>Total</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="row in stock" :key="row.id">
                <td>{{ row.producto }}</td>
                <td>{{ row.fecha_ultimo_movimiento != null ? row.fecha_ultimo_movimiento.substr(0,10) : "" }}</td>
                <td>{{ row.sum }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </AppLayout>
</template>

<script>
import api from "@/api.js"
//import LineChart from '@/components/Chart.vue'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';
import AppLayout from "@/components/layout/AppLayout.vue";
import { GChart } from 'vue-google-charts'
export default {
  name: "home",
  components: {
    //LineChart,
    vSelect,
    GChart,
    AppLayout,
  },
  data() {
    return {
        countClient: '0',
        countWarehouse: '0',
        countProduct: '0',
        countOrder: '0',
        stock: [],
        icon: "weekly",
        typeName: "Semanales",
        idProduct: null,
        idWarehouse: null,
        dateStart: "",
        dateEnd: "",
        dataProduct: [],
        dataWarehouse: [],
        dataProduct0: [],
        dataWarehouse0: [],
        chartData: null,
        chartOptions: {
          title: null,
          legend: {
            position: 'top',
            alignment: 'start',
          },
          height: 450,
          curveType: 'function',
          pointSize: 5,
          hAxis: {
            direction:-1,
            slantedTextAngle:90 
          },
          vAxis: {
            minorGridlines: {
              count: 0
            },
            gridlines: {
              color: '#f2f2f2'
            }
          },
        },
        loaded: false,
    }
  },
  mounted: function () {
    this.getCounts();
    this.getStock();
    this.getProducts();
    this.getWarehouses();
  },
  methods: {
    getCounts: function() {
      let that = this;
      api
        .getClients()
        .then((data) => {
          that.countClient = data.clients.length;
          api
            .getWarehouses()
            .then((data) => {
              that.countWarehouse = data.warehouses.length;
              api
                .getProducts()
                .then((data) => {
                  that.countProduct = data.products.length
                  api
                    .getOrders()
                    .then((data) => that.countOrder = data.total.total);
                });
            });
        })
        .catch((error) => {
          that.showAlert(error.response.data.message);
          if(error.response.status === 401){
            localStorage.removeItem("userToken");
            that.$router.push('/login');
          }
        });
    },
    getStock: function() {
      let that = this;
      api
        .getAllStock()
        .then((data) => {
          that.stock = data.stock;
        })
        .catch((error) => {
          that.showAlert(error.response.data.message);
          if(error.response.status === 401){
            localStorage.removeItem("userToken");
            that.$router.push('/login');
          }
        });
    },
    getMovementsType: function(event) {
      var index = event.target.selectedIndex
      this.typeName = event.target[index].text;
      if(this.idProduct != null && this.idWarehouse != null) {
        this.getMovementsWarehouse(this.idWarehouse)
      }else if(this.idProduct != null){
        this.getMovementsProduct(this.idProduct)
      }
    },
    getMovementsDate: function() {
      if(this.idProduct != null && this.idWarehouse != null) {
        this.getMovementsWarehouse(this.idWarehouse)
      }else if(this.idProduct != null){
        this.getMovementsProduct(this.idProduct)
      }
    },
    getMovementsProduct: function(product) {
      if(product != null){
        this.chartdata = null
        this.loaded = false
        let that = this;
        api
          .getMovementsProduct(that.type, product.code, that.dateStart, that.dateEnd)
          .then((data) => {
            let dataChart = [['Día', 'Movimiento', 'Inventario']];
            data.movements.reverse();
            data.movements.forEach(movement => {
              if(that.type == "weekly"){
                dataChart.push([movement.dia.substr(0,10), Number(movement.movimiento_dia), Number(movement.inventario)]);
              }else if(that.type == "monthly"){
                dataChart.push([movement.dia.substr(0,10), Number(movement.movimiento_mes), Number(movement.inventario)]);
              }else if(that.type == "daily"){
                dataChart.push([movement.fecha.substr(0,10), Number(movement.movimiento_dia), Number(movement.inventario)]);
              }
            })
            that.chartData = dataChart;
            that.chartOptions.title = "Movimientos " + that.typeName + " por " + product.label
            that.loaded = true
          })
          .catch((error) => {
            that.showAlert(error.response.data.message);
            if(error.response.status === 401){
              localStorage.removeItem("userToken");
              that.$router.push('/login');
            }
          });
      }else{
        this.loaded = false
      }
    },
    getMovementsWarehouse: function(warehouse) {
      if(warehouse != null){
        if(this.idProduct != null){  
          this.chartdata = null
          this.loaded = false
          let that = this;
          api
            .getMovementsWarehouse(that.type, that.idProduct.code, warehouse.code, that.dateStart, that.dateEnd)
            .then((data) => {
              let dataChart = [['Semana', 'Movimiento', 'Inventario']];
              data.movements.reverse();
              data.movements.forEach(movement => {
                if(that.type == "weekly"){
                  dataChart.push([movement.semana.substr(0,10) + " Dia: " + movement.dia.substr(5,2), Number(movement.movimiento_dia), Number(movement.inventario)]);
                }else if(that.type == "monthly"){
                  dataChart.push([movement.dia.substr(0,10), Number(movement.movimiento_dia), Number(movement.inventario)]);
                }else if(that.type == "daily"){
                  dataChart.push([movement.fecha.substr(0,10), Number(movement.movimiento_dia), Number(movement.inventario)]);
                }
              })
              that.chartData = dataChart;
              that.chartOptions.title = "Movimientos " + that.typeName + " por " + that.idProduct.label + " y Bodega: " + warehouse.label
              that.loaded = true
            })
            .catch((error) => {
              that.showAlert(error.response.data.message);
              if(error.response.status === 401){
                localStorage.removeItem("userToken");
                that.$router.push('/login');
              }
            });
        }
      }else{
        this.getMovementsProduct(this.idProduct)
      }
    },
    getProducts: function() {
      let that = this;
      api
        .getProducts()
        .then((data) => {
          that.dataProduct = data.products;
          data.products.forEach(product => {
            that.dataProduct0.push({code: product.id, label: product.nombre});
          })
        })
        .catch((error) => {
          that.showAlert(error.response.data.message);
          if(error.response.status === 401){
            localStorage.removeItem("userToken");
            that.$router.push('/login');
          }
        });
    },
    getWarehouses: function() {
      let that = this;
      api
        .getWarehouses()
        .then((data) => {
          that.dataWarehouse = data.warehouses;
          data.warehouses.forEach(warehouse => {
            that.dataWarehouse0.push({code: warehouse.id, label: warehouse.nombre});
          })
        })
        .catch((error) => {
          that.showAlert(error.response.data.message);
          if(error.response.status === 401){
            localStorage.removeItem("userToken");
            that.$router.push('/login');
          }
        });
    },
    showAlert: function(message) {
      this.$swal({
        position: "top-end",
        icon: "error",
        title: message,
        showConfirmButton: false,
        timer: 1500
      });
    }
  }
};
</script>
