<template>
  <AppLayout>
    <h2 class="mb-4">Detalle Devolución</h2>
    <div class="card mb-4">
      <div class="card-body">
        <div id="divSt0">
            <div class="row">
              <div class="col-md-3">
                <div class="form-group">
                  <label>Factura</label>
                  <p class="lead">{{id_factura}}</p>
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <label>Bodega Destino</label>
                  <p class="lead">{{bodega_destino}}</p>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-3">
                <div class="form-group">
                  <label>Quien autoriza</label>
                  <p class="lead">{{autorizante}}</p>
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <label>Fecha de Devolucion</label>
                  <p class="lead">{{fecha_devolucion}}</p>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label>Observación</label>
                  <p class="lead">{{observacion}}</p>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <div class="form-group">
                  <table class="table table-hover">
                    <thead>
                      <tr>
                        <th>Número Lote</th>
                        <th>Producto</th>
                        <th>Cantidad</th>
                        <th>Costo</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(row, index) in detalle" :key="row[index]">
                        <td>{{ row.lote_numero }}</td>
                        <td>
                          {{ row.producto }}
                        </td>
                        <td>
                          {{row.cantidad}}
                        </td>
                        <td>
                          {{row.costo}}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
      </div>
    </div>
  </AppLayout>
</template>

<script>
import api from "@/api.js";
import AppLayout from "@/components/layout/AppLayout.vue";
export default {
  name: "preorderdetail",
  components: {
    AppLayout,
  },
  data() {
    return {
      id: null,
      id_factura: null,
      bodega_destino: null,
      autorizante: null,
      fecha_devolucion: null,
      observacion: null,
      detalle: [],
    };
  },
  mounted: function () {
    this.id = this.$route.params.id;
    this.getRefund();
  },
  methods: {
    getRefund: function () {
      let that = this;
      let loader = this.$loading.show();
      api
        .getRefund(that.id)
        .then((data) => {
          that.id_factura = data.refund.id_factura;
          that.bodega_destino = data.refund.bodega;
          that.autorizante = data.refund.autorizante;
          that.fecha_devolucion = data.refund.fecha_devolucion;
          that.observacion = data.refund.observacion;
          that.detalle = data.refund.detalle;
          loader.hide();
        })
        .catch((error) => {
          that.showAlert("error", error.response.data.message);
          loader.hide();
          if (error.response.status === 401) {
            localStorage.removeItem("userToken");
            that.$router.push("/login");
          }
        });
    },
    showAlert: function (type, message) {
      this.$swal({
        position: "top-end",
        icon: type,
        title: message,
        showConfirmButton: false,
        timer: 1500,
      });
    },
  },
};
</script>
