<template>
  <AppLayout>
    <h2 class="mb-4">Detalle de Factura</h2>
    <div class="card mb-4">
      <div class="card-body">
        <div id="divSt0">
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label>Consecutivo Nux</label>
                  <p class="lead">NUX {{ id }}</p>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label>Consecutivo</label>
                  <p class="lead">NPX {{ (!prefijo ? "NPX" : prefijo) + consecutivo }}</p>
                </div>
              </div>
             </div>
            <div class="row">
              <div class="col-md-3">
                <div class="form-group">
                  <label>Cliente</label>
                  <p class="lead">{{ cliente }}</p>
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <label>Sucursal</label>
                  <p class="lead">{{ sucursal }}</p>
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <label>Dirección</label>
                  <p class="lead">{{ direccion }}</p>
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <label>Contacto</label>
                  <p class="lead">{{ contacto }}</p>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-3">
                <div class="form-group">
                  <label>Bodega</label>
                  <p class="lead">{{ bodega }}</p>
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <label>Concepto</label>
                  <p class="lead">{{ concepto }}</p>
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <label>Forma de Pago</label>
                  <p class="lead">{{ forma_pago }}</p>
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <label>Fecha Entregada</label>
                  <p class="lead">{{ fecha_entregada === null ? "" : fecha_entregada.substr(0,10) }}</p>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <div class="form-group">
                  <table class="table table-hover">
                    <thead>
                      <tr>
                        <th>Número Lote</th>
                        <th>Fecha Lote</th>
                        <th>Fecha Vencimiento</th>
                        <th>Producto</th>
                        <th>Cantidad</th>
                        <th>Precio Unitario</th>
                        <th>% Iva</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(row, index) in detalle" :key="row[index]">
                        <td>
                          {{ row.lote_numero }}
                        </td>
                        <td>
                          {{ row.lote_fecha.substr(0,10) }}
                        </td>
                        <td>
                          {{ row.lote_vencimiento.substr(0,10) }}
                        </td>
                        <td>
                          {{ row.producto }}
                        </td>
                        <td>
                          {{ row.cantidad }}
                        </td>
                        <td>
                          {{ row.precio }}
                        </td>
                        <td>
                          {{ row.iva }}
                        </td>
                      </tr>
                    </tbody>
                    <tfoot>
                      <tr>
                        <td colspan="5"></td>
                        <td><b>Subtotal:</b> {{ subtotal }}</td>
                        <td><b>Total:</b> {{ total }}</td>
                      </tr>
                    </tfoot>
                  </table>
                </div>
              </div>
            </div>
          </div>
      </div>
    </div>
  </AppLayout>
</template>

<script>
import api from "@/api.js"
import AppLayout from "@/components/layout/AppLayout.vue";
export default {
  name: "invoicedetail",
  components: {
    AppLayout,
  },
  data() {
    return {
      dataInvoice: [],
      //data
      prefijo: null,
      id: null,
      consecutivo: null,
      cliente: null,
      sucursal: null,
      direccion: null,
      contacto: null,
      bodega: null,
      concepto: null,
      forma_pago: null,
      fecha_entregada: null,
      detalle: [],
      subtotal: 0,
      total: 0
    };
  },
  mounted: function(){
    this.id = this.$route.params.id;
    this.getInvoice();
  },
  methods: {
    getInvoice: function() {
      let that = this;
      let loader = this.$loading.show();
      this.dataInvoice.length = 0;
      api
        .getInvoice(that.id)
        .then(data => {
          that.prefijo = data.invoice.prefijo;
          that.id = data.invoice.id.toString().padStart(4, "0");
          that.consecutivo = data.invoice.consecutivo;
          that.cliente = data.invoice.cliente;
          that.sucursal = data.invoice.sucursal;
          that.direccion = data.invoice.direccion;
          that.contacto = data.invoice.contacto;
          that.bodega = data.invoice.bodega;
          that.concepto = data.invoice.concepto;
          that.forma_pago = data.invoice.forma_pago;
          that.fecha_entregada = data.invoice.fecha_entregada;
          that.detalle = data.invoice.detalle;
          that.detalle.forEach(detalle => {
            that.subtotal += Number(detalle.precio * detalle.cantidad);
            that.total += Number(detalle.precio * detalle.cantidad) + Number((detalle.iva / 100) * (detalle.precio * detalle.cantidad));
          });
          loader.hide();
        })
        .catch((error) => {
          that.showAlert("error", error.response.data.message);
          loader.hide();
          if(error.response.status === 401){
            localStorage.removeItem("userToken");
            that.$router.push('/login');
          }
        });
    }
  }
};
</script>
