<template>
    <AppLayout>
        <h2 class="mb-4">Usuarios Solicitudes</h2>
        <div class="card mb-4">
          <div class="card-body">
              <div class="row">
                  <div class="col-md-4">
                    <h4>Registro</h4>
                    <p>Aqui puede registrar un usuario para que le lleguen las notificaciones de cuando se crean solicitudes desde la app</p>
                    <form @submit.prevent="registerUserRequests">
                      <div class="form-group">
                        <label>Usuario</label>
                        <select v-model="id_usuario" class="form-control">
                          <option v-for="row in dataUsers" :key="row.id" :value="row.id">{{ row.nombre }} - {{ row.email }}</option>
                        </select>
                      </div>
                      <button class="btn btn-primary" type="submit">
                        <font-awesome-icon icon="save" /> Registrar
                      </button>
                    </form>
                  </div>
                  <div class="col-md-8">
                      <h4>Listado</h4>
                      <ListList :userType="userType" :dataList="dataUser" @deleteList="deletetUserRequests"></ListList>
                  </div>
              </div>    
          </div>
        </div> 
    </AppLayout>
</template>

<script>
import api from "@/api.js"
import AppLayout from '@/components/layout/AppLayout.vue'
import ListList from '@/components/list/ListList.vue'
export default {
  name: 'State',
  components: {
    AppLayout,
    ListList,
  },
  data() {
    return {
        nombre: null, 
        id_usuario: null,
        dataUser: [],
        dataUsers: [],
        userType: "Admin",
    }
  },
  created: function() {
    this.$data.userType = localStorage.getItem("userType");
  },
  mounted: function () {
    this.getUserRequests();
    this.getUsers()
  },
  methods: {
    registerUserRequests: function () {
      let that = this;
      let loader = this.$loading.show();
      let data = { id_usuario: this.id_usuario };
      api
        .registerUserRequests(data)
        .then(() => {
          loader.hide();
          that.getUserRequests();
        })
        .catch((error) => {
          that.showAlert(error.response.data.message);
          loader.hide();
          if(error.response.status === 401){
            localStorage.removeItem("userToken");
            that.$router.push('/login');
          }
        });
    },
    getUserRequests: function () {
      let that = this;
      let loader = this.$loading.show();
      this.dataUser.length = 0;
      api
        .getUserRequests()
        .then((data) => {
          that.dataUser = data.users;
          loader.hide();
        })
        .catch((error) => {
          that.showAlert(error.response.data.message);
          loader.hide();
          if(error.response.status === 401){
            localStorage.removeItem("userToken");
            that.$router.push('/login');
          }
        });
    },
    getUsers: function () {
      let that = this;
      let loader = this.$loading.show();
      this.dataUsers.length = 0;
      api
        .getUsers()
        .then((data) => {
          that.dataUsers = data.users;
          loader.hide();
        })
        .catch((error) => {
          that.showAlert(error.response.data.message);
          loader.hide();
          if(error.response.status === 401){
            localStorage.removeItem("userToken");
            that.$router.push('/login');
          }
        });
    },
    deletetUserRequests: function(id) {
      let that = this;
      let loader = this.$loading.show();
      api
        .deletetUserRequests(id)
        .then(() => {
          that.dataUser = that.dataUser.filter(user => user.id != id);
          loader.hide();
        })
        .catch((error) => {
          that.showAlert(error.response.data.message);
          loader.hide();
          if(error.response.status === 401){
            localStorage.removeItem("userToken");
            that.$router.push('/login');
          }
        });
    },
    showAlert: function(message) {
      this.$swal({
        position: "top-end",
        icon: "error",
        title: message,
        showConfirmButton: false,
        timer: 1500
      });
    }
  }
}
</script>
