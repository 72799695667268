<template>
  <div>
    <div id="divSt0">
      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label class="font-weight-bold">Orden de Compra</label>
            <br/>
            <a v-if="documento_orden" ref="#" @click="downloadFile(documento_orden)" class="btn btn-primary"><font-awesome-icon icon="file" /> Ver Documento</a>
            <p v-else class="lead mr-2">No Diligenciado</p>
          </div>
          <div class="form-group">
            <label class="font-weight-bold">Solicitud de factura/Remisión</label>
            <br/>
            <a v-if="documento_factura_remision" ref="#" @click="downloadFile(documento_factura_remision)" class="btn btn-primary"><font-awesome-icon icon="file" /> Ver Documento</a>
            <p v-else class="lead mr-2">No Diligenciado</p>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label class="font-weight-bold">Certificado de Ánalisis</label>
            <br/>
            <a v-if="documento_certificado" ref="#" @click="downloadFile(documento_certificado)" class="btn btn-primary"><font-awesome-icon icon="file" /> Ver Documento</a>
            <p v-else class="lead mr-2">No Diligenciado</p>
          </div>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-md-6">
          <div class="form-group">
            <label class="font-weight-bold">Factura</label>
            <br/>
            <router-link v-if="id_factura" class="btn btn-primary" :to="{ path: '/factura/detail/' + id_factura }">
              <font-awesome-icon icon="eye" /> {{ id_factura.toString().padStart(4, "0")}}
            </router-link>
            <p v-else class="lead mr-2">No Diligenciado</p>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label class="font-weight-bold">Pedido</label>
            <br/>
            <router-link class="btn btn-primary" :to="{ path: '/pedido/detail/' + id_pedido }">
              <font-awesome-icon icon="eye" /> {{ !id_pedido ? "" : id_pedido.toString().padStart(4, "0") }}
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <div id="divSt1">
      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label class="font-weight-bold">Fecha Estimada</label>
            <div v-if="!isEditEstimada" class="input-group mb-3">
              <p v-if="fecha_estimada" class="lead mr-2">{{ fecha_estimada }}</p>
              <p v-else class="lead mr-2">No Diligenciado</p>
              <button @click="isEditEstimada = true" class="btn btn-primary text-white">
                <font-awesome-icon icon="edit" />
              </button>
            </div>
            <div v-else class="input-group mb-3">
              <div class="input-group-prepend">
                <button @click="isEditEstimada = false" class="btn btn-danger" type="button"><font-awesome-icon icon="times" /></button>
              </div>
              <input id="fecha_estimada" :value="fecha_estimada" type="date" class="form-control" />
              <div class="input-group-append">
                <button @click="editItem('fecha_estimada', 'isEditEstimada')" class="btn btn-primary" type="button"><font-awesome-icon icon="save" /></button>
              </div>
            </div>
          </div>
          <div class="form-group">
            <label class="font-weight-bold">Tracking Number</label>
            <div v-if="!isEditNumero" class="input-group mb-3">
              <p v-if="numero_tracking" class="lead mr-2">{{ numero_tracking }}</p>
              <p v-else class="lead mr-2">No Diligenciado</p>
              <button @click="isEditNumero = true" class="btn btn-primary text-white">
                <font-awesome-icon icon="edit" />
              </button>
            </div>
            <div v-else class="input-group mb-3">
              <div class="input-group-prepend">
                <button @click="isEditNumero = false" class="btn btn-danger" type="button"><font-awesome-icon icon="times" /></button>
              </div>
              <input id="numero_tracking" type="text" :value="numero_tracking" class="form-control" />
              <div class="input-group-append">
                <button @click="editItem('numero_tracking', 'isEditNumero')" class="btn btn-primary" type="button"><font-awesome-icon icon="save" /></button>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label class="font-weight-bold">Empresa</label>
            <div v-if="!isEditEmpresa" class="input-group mb-3">
              <p v-if="empresa" class="lead mr-2">{{ empresa }}</p>
              <p v-else class="lead mr-2">No Diligenciado</p>
              <button @click="isEditEmpresa = true" class="btn btn-primary text-white">
                <font-awesome-icon icon="edit" />
              </button>
            </div>
            <div v-else class="input-group mb-3">
              <div class="input-group-prepend">
                <button @click="isEditEmpresa = false" class="btn btn-danger" type="button"><font-awesome-icon icon="times" /></button>
              </div>
              <input id="empresa" :value="empresa" type="text" class="form-control" />
              <div class="input-group-append">
                <button @click="editItem('empresa', 'isEditEmpresa')" class="btn btn-primary" type="button"><font-awesome-icon icon="save" /></button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>   
    <div id="divSt2">
      <div class="row">
        <div class="col-md-12">
          <div class="form-group">
            <label class="font-weight-bold">Fecha de Entregada</label>
            <div v-if="!isEditEntregada" class="input-group mb-3">
              <p v-if="fecha_entregada" class="lead mr-2">{{ fecha_entregada }}</p>
              <p v-else class="lead mr-2">No Diligenciado</p>
              <button @click="isEditEntregada = true" class="btn btn-primary text-white">
                <font-awesome-icon icon="edit" />
              </button>
            </div>
            <div v-else class="input-group mb-3">
              <div class="input-group-prepend">
                <button @click="isEditEntregada = false" class="btn btn-danger" type="button"><font-awesome-icon icon="times" /></button>
              </div>
              <input id="fecha_entregada" :value="fecha_entregada" type="date" class="form-control" />
              <div class="input-group-append">
                <button @click="editItem('fecha_entregada', 'isEditEntregada')" class="btn btn-primary" type="button"><font-awesome-icon icon="save" /></button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="divSt3">
      <div class="row">
        <div v-for="(row, index) in observaciones" :key="index" class="col-md-6">
          <div class="form-group">
            <label class="font-weight-bold">Observación {{ status[index] }}</label>
            <div v-if="!isEditObservaciones[index]" class="input-group mb-3">
              <p v-if="row" class="lead mr-2">{{ row }}</p>
              <p v-else class="lead mr-2">No Diligenciada</p>
              <button @click="editStatus(index,true)" class="btn btn-primary text-white">
                <font-awesome-icon icon="edit" />
              </button>
            </div>
            <div v-else class="input-group mb-3">
              <div class="input-group-prepend">
                <button @click="editStatus(index,false)" class="btn btn-danger" type="button"><font-awesome-icon icon="times" /></button>
              </div>
              <input :id="'observaciones' + index" :value="row" type="text" class="form-control" />
              <div class="input-group-append">
                <button @click="editItem('observaciones' + index, 'isEditObservaciones[' + index + ']')" class="btn btn-primary" type="button"><font-awesome-icon icon="save" /></button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "orderdetailout",
  props: {
    dataOrder: Object,
  },
  data() {
    return {
      //object
      id_factura: null,
      id_pedido: null,
      prefijo: null,
      sucursal: null,
      documento_orden: null,
      documento_certificado: null,
      documento_factura_remision: null,
      fecha_estimada: null,
      numero_tracking: null,
      empresa: null,
      fecha_entregada: null,
      estado: null,
      observaciones: null,
      //aux
      isEditEstimada: false,
      isEditNumero: false,
      isEditEmpresa: false,
      isEditEntregada: false,
      isEditObservaciones: [
        false,
        false,
        false,
      ],
      status: ["Documentada", "Confirmada", "Entregada"]
    };
  },
  watch: {
    dataOrder: function(value) {
      this.$data.id_factura = value.id_factura;
      this.$data.id_pedido = value.id_pedido;
      this.$data.sucursal = value.sucursal;
      this.$data.prefijo = value.prefijo;
      this.$data.documento_certificado = value.documento_certificado;
      this.$data.documento_factura_remision = value.documento_factura_remision;
      this.$data.documento_orden = value.documento_orden;
      if(value.fecha_estimada) {
        this.$data.fecha_estimada = value.fecha_estimada.substr(0,10);
      }
      this.$data.numero_tracking = value.numero_tracking;
      this.$data.empresa = value.empresa;
      if(value.fecha_entregada) {
        this.$data.fecha_entregada = value.fecha_entregada.substr(0,10);
      }
      this.$data.observaciones = value.observaciones;
      this.$data.estado = value.estado;
    }
  },
  methods: {
    downloadFile: function(filename) {
      this.$emit("downloadFile", filename)
    },
    uploadFile: function(item, itemEdit) {
      let doc = document.getElementById(item);
      if(doc.files.length > 0) {            
        let formData = new FormData();
        formData.append(item, doc.files[0]);
        let data  = {};
        data.files = formData;
        this[itemEdit] = false;
        this.$emit("uploadOrderItem", data);
      }else{
        this.showAlert("Escoge el archivo a subir");
      }
    },
    editStatus: function(idx, val) {
      this.isEditObservaciones = this.isEditObservaciones.map((observacion, index) => {
        if(index === idx){
          observacion = val;
        }
        return observacion;
      });
    },
    editItem: function(item, itemEdit) {
      let value = document.getElementById(item).value;
      if(value !== "") {
        let data  = {};
        data[item] = value;
        if(item.includes("observaciones")){ 
          this.observaciones = this.observaciones.map((observacion, index) => {
            if(index == item.slice(-1)){
              observacion = value;
            }
            return observacion;
          });
          this.isEditObservaciones = this.isEditObservaciones.map((observacion, index) => {
            if(index == item.slice(-1)){
              observacion = false;
            }
            return observacion;
          });
        }else{
          this[item] = value;
          this[itemEdit] = false;
        }
        this.$emit("editOrderItem", data);
      }else{
        this.showAlert("Termina de escribir el dato");
      }
    },
    showAlert: function(message) {
      this.$swal({
        position: "top-end",
        icon: "error",
        title: message,
        showConfirmButton: false,
        timer: 1500
      });
    }
  } 
};
</script>
