<template>
  <AppLayout>
    <h2 class="mb-4">
      Listado de Ordenes
    </h2>
    <div class="card mb-4">
      <div class="card-header">
        <h5>
          Entrada
          <router-link
            v-if="(userType === 'Admin' || userModules.includes('Entradas')) && userType !== 'Invitado'"
            class="btn btn-primary float-right"
            :to="{ path: '/' + nodeOrder + '/entrada/register' }"
          >
            <font-awesome-icon icon="plus" /> Registrar
          </router-link>
        </h5>
      </div>
      <div class="card-body">
        <ul class="nav nav-tabs">
          <li v-for="(row,idx) in statusIn" :key="row" class="nav-item">
            <a class="nav-link" data-toggle="tab" :href="'#tabStIn' + idx" :id="'navStIn' + idx" role="tab">
              {{ row }}
              <span class="badge badge-primary">{{ $data["dataOrderIn" + idx].length }}</span>
            </a>
          </li>
        </ul>
        <div class="tab-content">
          <div
            v-for="(row, idx) in statusIn" :key="row"
            class="tab-pane"
            :id="'tabStIn' + idx"
            role="tabpanel"
          >
            <OrderListIn
              :status="idx"
              :dataOrder="$data['dataOrderIn' + idx]"
              @deleteOrder="deleteOrder"
              @statusOrder="statusOrder"
            ></OrderListIn>
          </div>
        </div>
      </div>
    </div>
    <div class="card mb-4">
      <div class="card-header">
        <h5>
          Salida
          <router-link
            v-if="(userType === 'Admin' || userModules.includes('Salidas')) && userType !== 'Invitado'"
            class="btn btn-primary float-right"
            :to="{ path: '/' + nodeOrder + '/salida/register' }"
          >
            <font-awesome-icon icon="plus" /> Registrar
          </router-link>
        </h5>
      </div>
      <div class="card-body">
        <ul class="nav nav-tabs">
          <li v-for="(row,idx) in statusOut" :key="row" class="nav-item">
            <a class="nav-link" data-toggle="tab" :href="'#tabStOut' + idx" :id="'navStOut' + idx"  role="tab">
              {{ row }}
              <span class="badge badge-primary">{{ $data["dataOrderOut" + idx].length }}</span>
            </a>
          </li>
        </ul>
        <div class="tab-content">
          <div
            v-for="(row,idx) in statusOut" :key="row"
            class="tab-pane"
            :id="'tabStOut' + idx"
            role="tabpanel"
          >
            <OrderListOut
              :status="idx"
              :dataOrder="$data['dataOrderOut' + idx]"
              @deleteOrder="deleteOrder"
              @statusOrder="statusOrder"
            ></OrderListOut>
          </div>
        </div>
      </div>
    </div>
    <div class="card mb-4">
      <div class="card-header">
        <h5>
          Traslado Bodega
          <router-link
            v-if="(userType === 'Admin' || userModules.includes('Traslado')) && userType !== 'Invitado'"
            class="btn btn-primary float-right"
            :to="{ path: '/' + nodeOrder + '/bodega/register' }"
          >
            <font-awesome-icon icon="plus" /> Registrar
          </router-link>
        </h5>
      </div>
      <div class="card-body">
        <ul class="nav nav-tabs">
          <li v-for="(row,idx) in statusWarehouse" :key="row" class="nav-item">
            <a class="nav-link" data-toggle="tab" :href="'#tabStWarehouse' + idx" :id="'navStWarehouse' + idx"  role="tab">
              {{ row }}
              <span class="badge badge-primary">{{ $data["dataOrderWarehouse" + idx].length }}</span>
            </a>
          </li>
        </ul>
        <div class="tab-content">
          <div
            v-for="(row,idx) in statusWarehouse" :key="row"
            class="tab-pane"
            :id="'tabStWarehouse' + idx"
            role="tabpanel"
          >
            <OrderListWarehouse
              :status="idx"
              :dataOrder="$data['dataOrderWarehouse' + idx]"
              @deleteOrder="deleteOrder"
              @statusOrder="statusOrder"
            ></OrderListWarehouse>
          </div>
        </div>
      </div>
    </div>
    <div class="card mb-4">
      <div class="card-header">
        <h5>
          Transformación Producto
          <router-link
            v-if="(userType === 'Admin' || userModules.includes('Transformacion')) && userType !== 'Invitado'"
            class="btn btn-primary float-right"
            :to="{ path: '/' + nodeOrder + '/producto/register' }"
          >
            <font-awesome-icon icon="plus" /> Registrar
          </router-link>
        </h5>
      </div>
      <div class="card-body">
        <OrderListProduct
          :dataOrder="dataOrderProduct"
          @deleteOrder="deleteOrder"
        ></OrderListProduct>
      </div>
    </div>
  </AppLayout>
</template>

<script>
const $ = require("jquery");
import api from "@/api.js";
import AppLayout from "@/components/layout/AppLayout.vue";
import OrderListIn from "@/components/order/OrderListIn.vue";
import OrderListOut from "@/components/order/OrderListOut.vue";
import OrderListWarehouse from "@/components/order/OrderListWarehouse.vue";
import OrderListProduct from "@/components/order/OrderListProduct.vue";
export default {
  name: "orderlist",
  components: {
    AppLayout,
    OrderListIn,
    OrderListOut,
    OrderListWarehouse,
    OrderListProduct
  },
  data() {
    return {
      dataOrderIn0: [],
      dataOrderIn1: [],
      dataOrderIn2: [],
      dataOrderIn3: [],
      dataOrderIn4: [],
      dataOrderIn5: [],
      dataOrderIn6: [],
      dataOrderIn7: [],
      dataOrderOut0: [],
      dataOrderOut1: [],
      dataOrderOut2: [],
      dataOrderOut3: [],
      dataOrderOut4: [],
      dataOrderWarehouse0: [],
      dataOrderWarehouse1: [],
      dataOrderWarehouse2: [],
      dataOrderWarehouse3: [],
      dataOrderProduct: [],
      nodeOrder: "orden",
      statusIn: [
        "Generada",
        "Compra",
        "Embarcada",
        "Aduana",
        "Tránsito",
        "Traslado",
        "Entregada",
        "Cancelada"
      ],
      statusOut: ["Documentada", "Confirmada", "Entregada", "Facturada", "Cancelada"],
      statusWarehouse: ["Solicitud de traslado", "Confirmada", "Recibida", "Cancelada"],
      userType: "Admin",
      userModules: [],
    };
  },
  mounted: function() {
    this.getOrders();
    document.getElementById("tabStIn0").classList.add("active");
    document.getElementById("navStIn0").classList.add("active");
    document.getElementById("tabStOut0").classList.add("active");
    document.getElementById("navStOut0").classList.add("active");
    document.getElementById("tabStWarehouse0").classList.add("active");
    document.getElementById("navStWarehouse0").classList.add("active");
    this.$data.userType = localStorage.getItem("userType");
    this.$data.userModules = localStorage.getItem("userModules");
  },
  methods: {
    getOrders: function() {
      let that = this;
      let loader = this.$loading.show();
      api
        .getOrdersIn()
        .then((data) => {
          if(data.orders.length > 0) {
            data.orders.forEach(order => {
              that.$data["dataOrderIn" + order.estado].push(order);
            });
          }
          api
            .getOrdersOut()
            .then((data) => {
              if(data.orders.length > 0) {
                data.orders.forEach(order => {
                  that.$data["dataOrderOut" + order.estado].push(order);
                });
              }
              api
                .getOrdersWarehouse()
                .then((data) => {
                  if(data.orders.length > 0) {
                    data.orders.forEach(order => {
                      that.$data["dataOrderWarehouse" + order.estado].push(order);
                    });
                  }
                  api
                    .getOrdersProduct()
                    .then((data) => {
                      loader.hide();
                      that.dataOrderProduct = data.orders;
                    })
                    .catch((error) => {
                      that.showAlert(error.response.data.message);
                      loader.hide();
                      if(error.response.status === 401){
                        localStorage.removeItem("userToken");
                        that.$router.push('/login');
                      }
                    });
                })
                .catch((error) => {
                  that.showAlert(error.response.data.message);
                  loader.hide();
                  if(error.response.status === 401){
                    localStorage.removeItem("userToken");
                    that.$router.push('/login');
                  }
                });
            })
            .catch((error) => {
              that.showAlert(error.response.data.message);
              loader.hide();
              if(error.response.status === 401){
                localStorage.removeItem("userToken");
                that.$router.push('/login');
              }
            });
        })
        .catch((error) => {
          that.showAlert(error.response.data.message);
          loader.hide();
          if(error.response.status === 401){
            localStorage.removeItem("userToken");
            that.$router.push('/login');
          }
        });
    },
    deleteOrder: function(id, status, type) {
      let that = this;
      let loader = this.$loading.show();
      let st = status === 0 ? status : status -1 ;
      if (type === "entrada") {
        api
          .deleteOrderIn(id)
          .then(() => {
            that.$data["dataOrderIn" + status].splice(that.$data["dataOrderIn" + st].findIndex(order => order.id == id),1);
            loader.hide();
          })
          .catch((error) => {
            that.showAlert(error.response.data.message);
            loader.hide();
            if(error.response.status === 401){
              localStorage.removeItem("userToken");
              that.$router.push('/login');
            }
          });
      }else if (type === "salida") {
        loader.hide();
        var canDeleteInvoice = false;
        var canDeletePreorder = false;
        let hasInvoice = that.$data["dataOrderOut" + status].find(order => order.id == id).asignada
        var options = {
          title: "Orden de Salida",
          icon: "warning",
          confirmButtonText: "Eliminar el pedido",
          showCancelButton: true,
          cancelButtonText: "Eliminar solo la orden"
        }
        if(hasInvoice) {
          options.showDenyButton = true;
          options.denyButtonText = "Eliminar la factura";
          options.denyButtonColor = "#8dc63f";
          options.confirmButtonText = "Eliminar el pedido y la factura";
        }
        this.$swal(options).then(result => {
          let loader2 = that.$loading.show()
          if (result.isDenied) {
            canDeleteInvoice = true;
          }
          if (result.isConfirmed) {
            canDeletePreorder = true;
            if(hasInvoice) {
              canDeleteInvoice = true;
            }
          }
          api
            .deleteOrderOut(id, {deleteInvoice: canDeleteInvoice, deletePreorder: canDeletePreorder} )
            .then(() => {
              that.$data["dataOrderOut" + status].splice(that.$data["dataOrderOut" + st].findIndex(order => order.id == id),1);
              loader2.hide();
            })
            .catch((error) => {
              that.showAlert(error.response.data.message);
              loader2.hide();
              if(error.response.status === 401){
                localStorage.removeItem("userToken");
                that.$router.push('/login');
              }
            });
        });
      }else if (type === "bodega") {
        api
          .deleteOrderWarehouse(id)
          .then(() => {
            that.$data["dataOrderWarehouse" + status].splice(that.$data["dataOrderWarehouse" + st].findIndex(order => order.id == id),1);
            loader.hide();
          })
          .catch((error) => {
            that.showAlert(error.response.data.message);
            loader.hide();
            if(error.response.status === 401){
              localStorage.removeItem("userToken");
              that.$router.push('/login');
            }
          });
      }else{
        api
          .deleteOrderProduct(id)
          .then(() => {
            that.dataOrderProduct.splice(that.dataOrderProduct.findIndex(order => order.id == id),1);
            loader.hide();
          })
          .catch((error) => {
            that.showAlert(error.response.data.message);
            loader.hide();
            if(error.response.status === 401){
              localStorage.removeItem("userToken");
              that.$router.push('/login');
            }
          });
      }
    },
    statusOrder: function(id, statusNew, statusOld, type) {
      let that = this;
      let loader = this.$loading.show();
      if (type === "entrada") {
        api
          .editOrderItemIn(id, { estado: statusNew })
          .then(() => {
            let order = that.$data["dataOrderIn" + statusOld].find(order => order.id == id);
            order.estado = statusNew;
            that.$data["dataOrderIn" + statusOld] = that.$data["dataOrderIn" + statusOld].filter(order => order.id != id);
            that.$data["dataOrderIn" + statusNew].push(order);
            $(".table0").DataTable().destroy();
            loader.hide();
          })
          .catch((error) => {
            that.showAlert(error.response.data.message);
            loader.hide();
            if(error.response.status === 401){
              localStorage.removeItem("userToken");
              that.$router.push('/login');
            }
          });
      }else if(type === "salida") {
        api
          .editOrderItemOut(id, { estado: statusNew })
          .then(() => {
            let order = that.$data["dataOrderOut" + statusOld].find(order => order.id == id);
            order.estado = statusNew;
            that.$data["dataOrderOut" + statusOld] = that.$data["dataOrderOut" + statusOld].filter(order => order.id != id);
            that.$data["dataOrderOut" + statusNew].push(order);
            $(".table1").DataTable().destroy();
            loader.hide();
          })
          .catch((error) => {
            that.showAlert(error.response.data.message);
            loader.hide();
            if(error.response.status === 401){
              localStorage.removeItem("userToken");
              that.$router.push('/login');
            }
          });
      }else{
        api
          .editOrderItemWarehouse(id, { estado: statusNew })
          .then(() => {
            let order = that.$data["dataOrderWarehouse" + statusOld].find(order => order.id == id);
            order.estado = statusNew;
            that.$data["dataOrderWarehouse" + statusOld] = that.$data["dataOrderWarehouse" + statusOld].filter(order => order.id != id);
            that.$data["dataOrderWarehouse" + statusNew].push(order);
            $(".table2").DataTable().destroy();
            loader.hide();
          })
          .catch((error) => {
            that.showAlert(error.response.data.message);
            loader.hide();
            if(error.response.status === 401){
              localStorage.removeItem("userToken");
              that.$router.push('/login');
            }
          });
      }
    },
    showAlert: function(message) {
      this.$swal({
        position: "top-end",
        icon: "error",
        title: message,
        showConfirmButton: false,
        timer: 1500
      });
    }
  }
};
</script>