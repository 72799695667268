<template>
  <AppLayout>
    <h2 class="mb-4">
      Listado de Ordenes General
    </h2>
    <div class="card mb-4">
      <div class="card-body">
        <ul class="nav nav-tabs">
          <li class="nav-item">
            <a class="active nav-link" data-toggle="tab" href="#tabIn" role="tab">
              Entrada
              <span class="badge badge-primary">{{ dataOrderIn.length }}</span>
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" data-toggle="tab" href="#tabOut" role="tab">
              Salida
              <span class="badge badge-primary">{{ dataOrderOut.length }}</span>
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" data-toggle="tab" href="#tabWarehouse" role="tab">
              Traslado Bodega
              <span class="badge badge-primary">{{ dataOrderWarehouse.length }}</span>
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" data-toggle="tab" href="#tabProduct" role="tab">
              Transformación Producto
              <span class="badge badge-primary">{{ dataOrderProduct.length }}</span>
            </a>
          </li>
        </ul>
        <div class="tab-content">
          <div class="active tab-pane" id="tabIn" role="tabpanel">
            <OrderListGeneralIn :dataOrder="dataOrderIn" ></OrderListGeneralIn>
          </div>
          <div class="tab-pane" id="tabOut" role="tabpanel">
            <OrderListGeneralOut :dataOrder="dataOrderOut" ></OrderListGeneralOut>
          </div>
          <div class="tab-pane" id="tabWarehouse" role="tabpanel">
            <OrderListGeneralWarehouse :dataOrder="dataOrderWarehouse" ></OrderListGeneralWarehouse>
          </div>
          <div class="tab-pane" id="tabProduct" role="tabpanel">
            <OrderListProduct :dataOrder="dataOrderProduct" ></OrderListProduct>
          </div>
        </div>
      </div>
    </div>
  </AppLayout>
</template>

<script>
import api from "@/api.js";
import AppLayout from "@/components/layout/AppLayout.vue";
import OrderListGeneralIn from "@/components/order/OrderListGeneralIn.vue";
import OrderListGeneralOut from "@/components/order/OrderListGeneralOut.vue";
import OrderListGeneralWarehouse from "@/components/order/OrderListGeneralWarehouse.vue";
import OrderListProduct from "@/components/order/OrderListProduct.vue";
export default {
  name: "orderlistgeneral",
  components: {
    AppLayout,
    OrderListGeneralIn,
    OrderListGeneralOut,
    OrderListGeneralWarehouse,
    OrderListProduct
  },
  data() {
    return {
      nodeOrder: "orden",
      dataOrderIn: [],
      dataOrderOut: [],
      dataOrderWarehouse: [],
      dataOrderProduct: [],
    };
  },
  mounted: function() {
    this.getOrders();
  },
  methods: {
    getOrders: function() {
      let that = this;
      let loader = this.$loading.show();
      api
        .getOrdersIn()
        .then((data) => {
          that.dataOrderIn = data.orders;
          api
            .getOrdersOut()
            .then((data) => {
              that.dataOrderOut = data.orders;
              api
                .getOrdersWarehouse()
                .then((data) => {
                  that.dataOrderWarehouse = data.orders;
                  api
                    .getOrdersProduct()
                    .then((data) => {
                      that.dataOrderProduct = data.orders;
                      loader.hide();
                    })
                    .catch((error) => {
                      that.showAlert(error.response.data.message);
                      loader.hide();
                      if(error.response.status === 401){
                        localStorage.removeItem("userToken");
                        that.$router.push('/login');
                      }
                    });
                })
                .catch((error) => {
                  that.showAlert(error.response.data.message);
                  loader.hide();
                  if(error.response.status === 401){
                    localStorage.removeItem("userToken");
                    that.$router.push('/login');
                  }
                });
            })
            .catch((error) => {
              that.showAlert(error.response.data.message);
              loader.hide();
              if(error.response.status === 401){
                localStorage.removeItem("userToken");
                that.$router.push('/login');
              }
            });
        })
        .catch((error) => {
          that.showAlert(error.response.data.message);
          loader.hide();
          if(error.response.status === 401){
            localStorage.removeItem("userToken");
            that.$router.push('/login');
          }
        });
    },
    showAlert: function(message) {
      this.$swal({
        position: "top-end",
        icon: "error",
        title: message,
        showConfirmButton: false,
        timer: 1500
      });
    }
  }
};
</script>