<template>
  <AppLayout>
    <h2 class="mb-4">Editar Pedido</h2>
    <div class="card mb-4">
      <div class="card-body">
        <form @submit.prevent="editPreorder">
          <div id="divSt0">
            <div class="row">
              <div class="col-md-4">
                <div class="form-group">
                  <label>Cliente</label>
                  <select v-model="id_cliente" @change="onChangeClient($event)" class="form-control">
                    <option v-for="row in dataClient" :key="row.id" :value="row.id">{{ row.nombre }}</option>
                  </select>
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label>Sucursal</label>
                  <select v-model="id_sucursal" class="form-control">
                    <option v-for="row in dataSubsidiary" :key="row.id" :value="row.id">{{ row.nombre }}</option>
                  </select>
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label>Bodega</label>
                  <select v-model="id_bodega" readonly class="form-control">
                    <option v-for="row in dataWarehouse" :key="row.id" :value="row.id">{{ row.nombre }}</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <p @click="addRow" class="btn btn-secondary float-right">
                  <font-awesome-icon icon="plus" /> Agregar Producto
                </p>
                <div class="form-group">
                  <table class="table table-hover">
                    <thead>
                      <tr>
                        <th>Número Lote</th>
                        <th>Producto</th>
                        <th>Cantidad</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(row, index) in detalle" :key="row[index]">
                        <td>
                          <v-select style="width:250px" :searchable="true" :options="dataStock2" v-model="row.lote_numero" @input="onChangeLote($event, index)" />
                        </td>
                        <td>
                          <input v-model="row.producto" type="text" readonly class="form-control" />
                          <input v-model="row.id_producto" type="hidden" />
                        </td>
                        <td>
                          <input v-model="row.cantidad" type="number" min="1" :max="row.cantidad2" :placeholder="row.cantidad2" class="form-control" />
                        </td>
                        <td>
                          <p @click="deleteRow(index)" class="btn btn-danger">
                            <font-awesome-icon icon="trash" />
                          </p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <button class="btn btn-primary" type="submit">
              <font-awesome-icon icon="save" /> Guardar
            </button>
          </div>
        </form>
      </div>
    </div>
  </AppLayout>
</template>

<script>
import api from "@/api.js"
import AppLayout from "@/components/layout/AppLayout.vue";
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';
export default {
  name: "preorderregister",
  components: {
    AppLayout,
    vSelect
  },
  data() {
    return {
      nodePreorder: "pedido",
      dataClient: [],
      dataSubsidiary: [],
      dataWarehouse: [],
      dataStock: [],
      dataStock2: [],
      //data
      id: null,
      id_bodega: null,
      cantidad: null,
      id_sucursal: null,
      id_cliente: null,
      detalle: [],
      detalle2: [],
    };
  },
  mounted: function(){
    this.id = this.$route.params.id;
    this.getPreorder();
  },
  methods: {
    copy: function(o) {
      var output, v, key;
      output = Array.isArray(o) ? [] : {};
      for (key in o) {
        v = o[key];
        output[key] = (typeof v === "object") ? this.copy(v) : v;
      }
      return output;
    },
    getPreorder: function() {
      let that = this;
      this.dataClient.length = 0;
      this.dataWarehouse.length = 0;
      let loader = this.$loading.show();
      api
        .getClients()
        .then((data) => {
          that.dataClient = data.clients;
          api
            .getWarehouses()
            .then((data) => {
              that.dataWarehouse = data.warehouses;
              api
                .getPreorder(that.id)
                .then(data => {
                  that.id_bodega = data.preorder.id_bodega;
                  that.id_cliente = data.preorder.id_cliente;
                  that.id_sucursal = data.preorder.id_sucursal;
                  that.detalle = data.preorder.detalle;
                  that.detalle2 = that.copy(data.preorder.detalle);
                  that.onChangeClient(that.id_cliente)
                  that.getStock(that.id_bodega)
                  loader.hide();
                })
                .catch((error) => {
                  that.showAlert("error", error.response.data.message);
                  loader.hide();
                  if(error.response.status === 401){
                    localStorage.removeItem("userToken");
                    that.$router.push('/login');
                  }
                });
            })
            .catch((error) => {
              that.showAlert("error", error.response.data.message);
              loader.hide();
              if(error.response.status === 401){
                localStorage.removeItem("userToken");
                that.$router.push('/login');
              }
            });
        })
        .catch((error) => {
          that.showAlert("error", error.response.data.message);
          loader.hide();
          if(error.response.status === 401){
            localStorage.removeItem("userToken");
            that.$router.push('/login');
          }
        });          
    },
    editPreorder: function() {
      let that = this;
      let loader = this.$loading.show();
      let dataPreorder = this.$data;
      if(that.id_sucursal && that.id_bodega) {
        if(this.detalle.length > 0) {
          dataPreorder.cantidad = this.detalle.length;
          api
            .editPreorder(that.id, dataPreorder)
            .then(data => {
              console.log(data)
              that.showAlert("success", "Pedido editado con el consecutivo: " + that.id);
              loader.hide();
              that.$router.push("/pedido");
            })
            .catch((error) => {
              that.showAlert("error", error.response.data.message);
              loader.hide();
              if(error.response.status === 401){
                localStorage.removeItem("userToken");
                that.$router.push('/login');
              }
            });
        }else{
          loader.hide();
          that.showAlert("Completa el detalle");
        }
      }else{
        loader.hide();
        that.showAlert("Completa los campos");
      }
    },
    onChangeClient: function(event) {
      let that = this;
      let loader = this.$loading.show();
      this.dataSubsidiary.length = 0;
      api
        .getSubsidiariesByClient(!event.target ? event : event.target.value)
        .then((data) => {
          that.dataSubsidiary = data.subsidiaries;
          loader.hide();
        })
        .catch((error) => {
          that.showAlert("error", error.response.data.message);
          loader.hide();
          if(error.response.status === 401){
            localStorage.removeItem("userToken");
            that.$router.push('/login');
          }
        });
    },
    getStock: function(id_warehouse) {
      let that = this;
      let loader = this.$loading.show();
      this.dataStock.length = 0;
      api
        .getStock(id_warehouse)
        .then((data) => {
          that.dataStock = data.stock;
          that.dataStock2 = [];
          data.stock.forEach(stock => {
            that.dataStock2.push({code: stock.lote_numero, label: stock.lote_numero + " - " + stock.codigo + " - " + stock.cantidad});
          })
          loader.hide();
        })
        .catch((error) => {
          that.dataStock2 = [];
          that.showAlert("error", error.response.data.message);
          loader.hide();
          if(error.response.status === 401){
            localStorage.removeItem("userToken");
            that.$router.push('/login');
          }
        });
    },
    onChangeLote: function(event, index) {
      if(event){
        if (this.detalle.filter(e => e.lote_numero === event).length > 1) {
          this.detalle[index].producto = null;
          this.detalle[index].id_producto = null;
          this.detalle[index].cantidad2 = null;
          this.detalle[index].lote_numero = null;
          this.detalle[index].lote_vencimiento = null;
          this.detalle[index].lote_fecha = null;
          this.detalle[index].cantidad = null;
          this.detalle[index].id_lote = null;
          console.log("ya hay")
        }else{
          console.log("no hay")
          let stock = this.dataStock.filter(stock => stock.lote_numero == event.code)[0];
          if(stock.lote_fecha != null){
            this.detalle[index].lote_fecha = stock.lote_fecha.substr(0,10);
          }
          if(stock.lote_vencimiento != null){
            this.detalle[index].lote_vencimiento = stock.lote_vencimiento.substr(0,10);
          }
          this.detalle[index].producto = stock.nombre + " | " + stock.codigo;
          this.detalle[index].id_producto = stock.id_producto;
          this.detalle[index].cantidad2 = stock.cantidad;
          this.detalle[index].id_lote = stock.id_lote;
        }
      }else{
        this.detalle[index].producto = null;
        this.detalle[index].id_producto = null;
        this.detalle[index].cantidad2 = null;
        this.detalle[index].lote_numero = null;
        this.detalle[index].lote_vencimiento = null;
        this.detalle[index].lote_fecha = null;
        this.detalle[index].cantidad = null;
        this.detalle[index].id_lote = null;
      }
    },
    addRow: function() {
      this.detalle.push({ id_lote: null, lote_numero: null, lote_fecha: null, lote_vencimiento: null, id_producto: null, cantidad: null});
    },
    deleteRow: function(index) {
      this.detalle.splice(index, 1);
    },
    showAlert: function(type, message) {
      this.$swal({
        position: "top-end",
        icon: type,
        title: message,
        showConfirmButton: false,
        timer: 1500
      });
    }
  }
};
</script>
