<template>
  <table class="table table-bordered table0">
    <thead>
      <tr>
        <th>Consecutivo</th>
        <th v-if="status === 0 || status === 6 || status === 7">Fecha Estimada</th>
        <th v-if="status === 0 || status === 6 || status === 7">Bodega</th>
        <th v-if="status === 0 || status === 6 || status === 7">Proveedor</th>
        <th v-if="status === 1">Proforma</th>
        <th v-if="status === 2">B/L Number</th>
        <th v-if="status === 2">Empresa Transportadora</th>
        <th v-if="status === 3">Commercial Invoice</th>
        <th v-if="status === 3">B/L</th>
        <th v-if="status === 3">Packing List</th>
        <th v-if="status === 5">Mensaje</th>
        <th v-if="status === 6">Fecha Entregada</th>
        <th>Observaciones</th>
        <th>Acciones</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="row in dataOrder" :key="row.id">
        <td>NUP {{ row.consecutivo }}</td>
        <td v-if="status === 0 || status === 6 || status === 7">{{ !row.fecha_estimada ? "" : row.fecha_estimada.substr(0,10) }}</td>
        <td v-if="status === 0 || status === 6 || status === 7">{{ row.bodega }}</td>
        <td v-if="status === 0 || status === 6 || status === 7">{{ row.proveedor }}</td>
        <td v-if="status === 1">{{ !row.documento_orden ? "No diligenciado" : "Diligenciado" }}</td>
        <td v-if="status === 2">{{ !row.numero_carga ? "No diligenciado" : row.numero_carga }}</td>
        <td v-if="status === 2">{{ !row.empresa ? "No diligenciado" : row.empresa }}</td>
        <td v-if="status === 3">{{ !row.documento_factura ? "No diligenciado" : "Diligenciado" }}</td>
        <td v-if="status === 3">{{ !row.documento_bl ? "No diligenciado" : "Diligenciado" }}</td>
        <td v-if="status === 3">{{ !row.documento_pl ? "No diligenciado" : "Diligenciado" }}</td>
        <td v-if="status === 5">{{ !row.fecha_entregada ? "Diligencia la fecha de entregada" : "Fecha de entrega diligenciada" }}</td>
        <td v-if="status === 6">{{ !row.fecha_entregada ? "No diligenciado" : row.fecha_entregada.substr(0,10) }}</td>
        <td>{{ !row.observaciones ? "" : row.observaciones[status] }}</td>
        <td>
          <router-link class="btn btn-primary" :to="{ path: '/orden/entrada/detail/' + row.id }">
            <font-awesome-icon icon="eye" /> Detalle
          </router-link>&nbsp;
          <router-link v-show="userType === 'Admin' || userType === 'Full Access' || userType === 'Comercial' || userType === 'Editor'" v-if="status !== 6 && status !== 7" class="btn btn-primary" :to="{ path: '/orden/entrada/edit/' + row.id }">
            <font-awesome-icon icon="edit" /> Editar
          </router-link>&nbsp;
          <button v-show="userType === 'Admin' || userModules.includes('Entradas')" v-if="status !== 6 && status !== 7" class="btn btn-info" @click="statusOrder(row.id, status, true, false)">
            <div v-if="status === 0"><font-awesome-icon icon="cart-plus" /> Pasar a Compra</div>
            <div v-if="status === 1"><font-awesome-icon icon="luggage-cart" />  Pasar a Embarcada</div>
            <div v-if="status === 2"><font-awesome-icon icon="landmark" />  Pasar a Aduana</div>
            <div v-if="status === 3"><font-awesome-icon icon="truck" />  Pasar a Tránsito</div>
            <div v-if="status === 4"><font-awesome-icon icon="truck-loading" />  Pasar a Traslado</div>
            <div v-if="status === 5"><font-awesome-icon icon="people-carry" />  Pasar a Entregada</div>
          </button>&nbsp;
          <button v-show="userType === 'Admin' || userModules.includes('Entradas')" v-if="status !== 0 && status !== 6 && status !== 7" class="btn btn-secondary" @click="statusOrder(row.id, status, false, false)">
            <div v-if="status === 1"><font-awesome-icon icon="cart-plus" /> Devolver a Generada</div>
            <div v-if="status === 2"><font-awesome-icon icon="luggage-cart" />  Devolver a Compraa</div>
            <div v-if="status === 3"><font-awesome-icon icon="landmark" />  Devolver a Embarcada</div>
            <div v-if="status === 4"><font-awesome-icon icon="truck" />  Devolver a Aduana</div>
            <div v-if="status === 5"><font-awesome-icon icon="truck-loading" />  Devolver a Tránsito</div>
          </button>&nbsp;
          <!--
          <button v-show="userType === 'Admin' || userModules.includes('Entradas')" v-if="status !== 6 && status !== 7" class="btn btn-warning" @click="statusOrder(row.id, status, false, true)">
            <font-awesome-icon icon="ban" /> Cancelar
          </button>&nbsp;
          -->
          <button v-show="userType === 'Admin' || userType === 'Full Access' || userType === 'Comercial'" v-if="status !== 6 && status !== 7" class="btn btn-danger" @click="deleteOrder(row.id)">
            <font-awesome-icon icon="trash-alt" /> Eliminar
          </button>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
const $ = require("jquery");
$.DataTable = require("datatables.net");
import "datatables.net-bs4/css/dataTables.bootstrap4.min.css";
import "datatables.net-bs4/js/dataTables.bootstrap4.min.js";
import "datatables.net-buttons/js/dataTables.buttons.min.js";
import "datatables.net-buttons/js/buttons.html5.min.js";
import "datatables.net-buttons/js/buttons.flash.min.js";
import "datatables.net-buttons/js/buttons.print.min.js";
import "datatables.net-buttons-bs4/css/buttons.bootstrap4.min.css";
import "datatables.net-buttons-bs4/js/buttons.bootstrap4.min.js";
window.JSZip = require( 'jszip' );
require( 'pdfmake' );

export default {
  name: "orderlistin",
  props: {
    status: Number,
    dataOrder: Array
  },
  data() {
    return {
      userType: "Admin",
      userModules: [],
      dataOrders: []
    };
  },
  mounted: function() {
    console.log()
    this.$data.userType = localStorage.getItem("userType");
    this.$data.userModules = localStorage.getItem("userModules");
  },
  watch: {
    dataOrder: function(value) {
      this.$data.dataOrders = value
    }
  },
  updated: function () {
    this.$nextTick(function () {
      $(".table0").DataTable({
        destroy     : true,
        paging      : true,
        lengthChange: true,
        searching   : true,
        ordering    : true,
        info        : true,
        autoWidth   : false,
        retrieve    : true,
        dom: 'Blfrtip', 
        buttons: [ 
          { 
            extend: 'excel', className: 'btn btn-secondary'
          },
        ],
        language: {
          "url": "//cdn.datatables.net/plug-ins/9dcbecd42ad/i18n/Spanish.json"
        },
      });
    })
  },
  methods: {
    deleteOrder: function(id) {
      let that = this;
      this.$swal({
        title: "Quieres Eliminar?",
        text: "Está seguro de querer eliminar esto",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si",
        cancelButtonText: "No"
      }).then(result => {
        if (result.value) {
          that.$emit("deleteOrder", id, that.status, "entrada");
        }
      });
    },
    statusOrder: function(id,status,isNext,isCancel) {
      let that = this;
      this.$swal({
        title: "Quieres Confirmar?",
        text: "Está seguro de querer cambiar el estado de la orden",
        icon: "info",
        showCancelButton: true,
        confirmButtonText: "Si",
        cancelButtonText: "No"
      }).then(result => {
        if (result.value) {
          if (isCancel) {
            that.$emit("statusOrder", id, 7, status, "entrada");
          }else{
            if(isNext){
              that.$emit("statusOrder", id, status + 1, status, "entrada");
            }else{
              that.$emit("statusOrder", id, status - 1, status, "entrada");
            }
          }
        }
      });
    }
  }
};
</script>
