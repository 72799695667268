<template>
  <table id="table" class="table table-bordered">
    <thead>
      <tr>
        <th>Nombre</th>
        <th v-if="dataList.length > 0 && dataList[0].email">Email</th>
        <th>Acciones</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="row in dataList" :key="row.id">
        <td>{{ row.nombre }}</td>
        <td v-if="row.email">{{ row.email }}</td>
        <td>
          <button v-if="userType === 'Admin' || userType === 'Full Access' || userType === 'Comercial'" class="btn btn-danger" @click="deleteList(row.id)">
            <font-awesome-icon icon="trash-alt" /> Eliminar
          </button>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
const $ = require("jquery");
$.DataTable = require("datatables.net");
import "datatables.net-bs4/css/dataTables.bootstrap4.min.css";
import "datatables.net-bs4/js/dataTables.bootstrap4.min.js";
import "datatables.net-buttons/js/dataTables.buttons.min.js";
import "datatables.net-buttons/js/buttons.html5.min.js";
import "datatables.net-buttons/js/buttons.flash.min.js";
import "datatables.net-buttons/js/buttons.print.min.js";
import "datatables.net-buttons-bs4/css/buttons.bootstrap4.min.css";
import "datatables.net-buttons-bs4/js/buttons.bootstrap4.min.js";
window.JSZip = require( 'jszip' );
require( 'pdfmake' );

export default {
  name: "listlist",
  props: {
    dataList: Array,
    userType: String,
  },
  updated: function () {
    $("#table").DataTable({ 
      retrieve: true,
      dom: 'Blfrtip', 
      buttons: [ 
        { 
          extend: 'excel', className: 'btn btn-secondary'
        },
      ],
      language: {
        "url": "//cdn.datatables.net/plug-ins/9dcbecd42ad/i18n/Spanish.json"
      },
    });
  },
  methods: {
    deleteList: function(id) {
      let that = this;
      this.$swal({
        title: "Quieres Eliminar?",
        text: "Está seguro de querer eliminar ésto",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si",
        cancelButtonText: "No"
      }).then(result => {
        if (result.value) {
          that.$emit("deleteList", id);
        }
      });
    }
  }
};
</script>
