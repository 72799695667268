<template>
  <AppLayout>
    <h2 class="mb-4">Mi Perfil</h2>
    <div class="card mb-4">
      <div class="card-body">
        <div class="card-body">
          <div class="row">
            <div class="col-md-6">
              <h4 class="mb-5">{{ nombre }}</h4>
              <p><strong>Email: </strong> {{ email }}</p>
              <p><strong>Tipo: </strong> {{ tipo }}</p>
              <p><strong>Módulos: </strong> {{ modulos.toString() }}</p>
            </div>
            <div class="col-md-6">
              <form @submit.prevent="editUserPassword">
                <div class="form-group">
                  <label>Nueva Contraseña</label>
                  <input v-model="password" type="text" class="form-control" />
                </div>
                <button class="btn btn-primary" type="submit">
                  <font-awesome-icon icon="save" /> Cambiar Contraseña
                </button>
              </form>
            </div>
          </div>    
        </div>
      </div>
    </div>
  </AppLayout>
</template>

<script>
import api from "@/api.js"
import AppLayout from "@/components/layout/AppLayout.vue"
export default {
  name: "userprofile",
  components: {
    AppLayout
  },
  data() {
    return {
      id: "",
      password: "",
      nombre: "",
      email: "",
      tipo: "",
      modulos: [],
    };
  },
  mounted() {
    this.id = localStorage.getItem("userId");
    this.nombre = localStorage.getItem("userName");
    this.email = localStorage.getItem("userEmail");
    this.tipo = localStorage.getItem("userType");
    this.modulos = localStorage.getItem("userModules").split(",");
  },
  methods: {
    editUserPassword: function() {
      let that = this;
      let loader = this.$loading.show();
      api
        .editUserPassword(that.id, that.$data)
        .then(() => {
          loader.hide();
          that.showAlert("Contraseña modificada exitosamente", "success");
        })
        .catch((error) => {
          that.showAlert(error.response.data.message, "error");
          loader.hide();
          if(error.response.status === 401){
            localStorage.removeItem("userToken");
            that.$router.push('/login');
          }
        });   
    },
    showAlert: function(message, type) {
      this.$swal({
        position: "top-end",
        icon: type,
        title: message,
        showConfirmButton: false,
        timer: 1500
      });
    }
  }
};
</script>
