<template>
  <div class="container-full h-100">
        <div class="row h-100 justify-content-center align-items-center">
            <div class="col-md-3">
                <!--<h1 class="text-center mb-4">Nuproxa | Zona Administrativa</h1>-->
                <div class="text-center mb-3">
                    <img src="../assets/logo.png" width="180" />
                </div>
                <div class="card">
                    <div class="card-body bg-dark">
                        <div class="input-group mb-3">
                            <div class="input-group-prepend">
                                <span class="input-group-text">
                                    <font-awesome-icon icon="envelope" />
                                </span>
                            </div>
                            <input type="email" class="form-control" v-model="email" placeholder="Usuario">
                        </div>
                        <div class="input-group mb-3">
                            <div class="input-group-prepend">
                                <span class="input-group-text">
                                    <font-awesome-icon icon="key" />
                                </span>
                            </div>
                            <input type="password" class="form-control" v-model="password" placeholder="Contraseña">
                        </div>
                        <div class="row">
                            <div class="col pr-2">
                                <button type="submit" @click="login" class="btn btn-block btn-primary mb-3">Iniciar Sesión</button>
                                <button class="btn btn-secondary btn-block" @click="recover">Recuperar Contraseña</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import api from "@/api.js"
export default {
  name: 'login',
  data() {
    return {
      email: '',
      password: ''
    }
  },
  created: function() {
    document.body.classList.add("bg-dark");
    document.body.classList.add("h-100");
  },
  methods: {
    login: function() {
      let that = this;
      let loader = this.$loading.show();
      if(that.email === "" || that.password === ""){
        loader.hide();
        that.showAlert("Completa los campos");
      }else{
        api
          .login(this.$data)
          .then((data) => {
            loader.hide();
            localStorage.setItem("userId", data.id);
            localStorage.setItem("userName", data.nombre);
            localStorage.setItem("userEmail", that.email);
            localStorage.setItem("userType", data.tipo);
            localStorage.setItem("userModules", data.modulos);
            localStorage.setItem("userToken", data.token);
            that.$router.push("/home")
          })
          .catch((error) => {
            loader.hide();
            that.showAlert(error.response.data.message);
          });
      }
    },
    recover: function() {
      let that = this;
      let loader = this.$loading.show();
      if(that.email === ""){
        loader.hide();
        that.showAlert("Escribe tu email, para enviar una contrasena nueva");
      }else{
        api
          .recover(this.$data)
          .then((data) => {
            that.showAlert(data.message);
            loader.hide();
          })
          .catch((error) => {
            loader.hide();
            that.showAlert(error.response.data.message);
          });
      }
    },
    showAlert: function(message) {
      this.$swal({
        position: "top-end",
        icon: "error",
        title: message,
        showConfirmButton: false,
        timer: 1500
      });
    }
  }
}
</script>
