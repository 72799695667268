<template>
    <AppLayout>
        <h2 class="mb-4">Países</h2>
        <div class="card mb-4">
          <div class="card-body">
              <div class="row">
                  <div class="col-md-4">
                      <h4>Registro</h4>
                      <ListForm @registerList="registerCountry"></ListForm>
                  </div>
                  <div class="col-md-8">
                      <h4>Listado</h4>
                      <ListList :userType="userType" :dataList="dataCountry" @deleteList="deleteCountry"></ListList>
                  </div>
              </div>    
          </div>
        </div> 
    </AppLayout>
</template>

<script>
import api from "@/api.js"
import AppLayout from '@/components/layout/AppLayout.vue'
import ListForm from '@/components/list/ListForm.vue'
import ListList from '@/components/list/ListList.vue'
export default {
  name: 'country',
  components: {
    AppLayout,
    ListForm,
    ListList,
  },
  data() {
    return {
        nodeCountry: "pais",
        dataCountry: [],
        userType: "Admin",
    }
  },
  created: function() {
    this.$data.userType = localStorage.getItem("userType");
  },
  mounted: function () {
    this.getCountries();
  },
  methods: {
    registerCountry: function (name) {
      let that = this;
      let loader = this.$loading.show();
      let data = { nombre: name };
      api
        .registerCountry(data)
        .then(() => {
          loader.hide();
          that.getCountries();
        })
        .catch((error) => {
          that.showAlert(error.response.data.message);
          loader.hide();
          if(error.response.status === 401){
            localStorage.removeItem("userToken");
            that.$router.push('/login');
          }
        });
    },
    getCountries: function () {
      let that = this;
      let loader = this.$loading.show();
      this.dataCountry.length = 0;
      api
        .getCountries()
        .then((data) => {
          that.dataCountry = data.countries;
          loader.hide();
        })
        .catch((error) => {
          that.showAlert(error.response.data.message);
          loader.hide();
          if(error.response.status === 401){
            localStorage.removeItem("userToken");
            that.$router.push('/login');
          }
        });
    },
    deleteCountry: function(id) {
      let that = this;
      let loader = this.$loading.show();
      api
        .deleteCountry(id)
        .then(() => {
          that.dataCountry = that.dataCountry.filter(country => country.id != id);
          loader.hide();
        })
        .catch((error) => {
          that.showAlert(error.response.data.message);
          loader.hide();
          if(error.response.status === 401){
            localStorage.removeItem("userToken");
            that.$router.push('/login');
          }
        });
    },
    showAlert: function(message) {
      this.$swal({
        position: "top-end",
        icon: "error",
        title: message,
        showConfirmButton: false,
        timer: 1500
      });
    }
  }
}
</script>
