<template>
  <AppLayout>
    <h2 class="mb-4">Detalle de Solicitud</h2>
    <div class="card mb-4">
      <div v-if="type == 'compra'" class="card-body">
        <div class="row">
          <div class="col">
            <p class="lead">Cliente</p>
            <p>{{ request.cliente }}</p>            
          </div>
          <div class="col">
            <p class="lead">Sucursal</p>
            <p>{{ request.sucursal }}</p>            
          </div>
        </div>
        <div class="row">
          <div class="col">
            <p class="lead">N° de Orden</p>
            <p>{{ request.num_orden_compra }}</p>
          </div>
          <div class="col">
            <p class="lead">Persona Solicitante</p>
            <p>{{ request.persona_solicitante }}</p>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <p class="lead">Condiciones</p>
            <p>{{ request.conditions }}</p>
          </div>
          <div class="col">
            <p class="lead">Bodega</p>
            <p>{{ request.bodega }}</p>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <p class="lead">Fecha Entrega</p>
            <p>{{ request.fecha_entrega }}</p>
          </div>
          <div class="col">
            <p class="lead">Tiempo Entrega</p>
            <p>{{ request.tiempo_entrega }} Hrs hábiles</p>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <p class="lead">Ciudad</p>
            <p>{{ request.ciudad_entrega }}</p>
          </div>
          <div class="col">
            <p class="lead">Direccion</p>
            <p>{{ request.direccion_entrega }}</p>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <p class="lead">Punto Entrega</p>
            <p>{{ request.punto_entrega }}</p>
          </div>
          <div class="col">
            <p class="lead">Telefono</p>
            <p>{{ request.telefono }}</p>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <p class="lead">Horario</p>
            <p>{{ request.horario }}</p>
          </div>
          <div class="col">
            <p class="lead">Recomendaciones</p>
            <p>{{ request.recomendaciones }}</p>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <p class="lead">Usuario</p>
            <p>{{ request.email }}</p>
          </div>
          <div class="col"></div>
        </div>
      </div>
      <div v-else class="card-body">
        <div class="row">
          <div class="col">
            <p class="lead">N° de Orden</p>
            <p>{{ request.num_orden_compra }}</p>
          </div>
          <div class="col">
            <p class="lead">Persona Solicitante</p>
            <p>{{ request.persona_solicitante }}</p>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <p class="lead">Fecha Entrega</p>
            <p>{{ request.fecha_entrega }}</p>
          </div>
          <div class="col">
            <p class="lead">Tiempo Entrega</p>
            <p>{{ request.tiempo_entrega }}  Hrs hábiles</p>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <p class="lead">Ciudad</p>
            <p>{{ request.ciudad_entrega }}</p>
          </div>
          <div class="col">
            <p class="lead">Direccion</p>
            <p>{{ request.direccion_entrega }}</p>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <p class="lead">Punto Entrega</p>
            <p>{{ request.punto_entrega }}</p>
          </div>
          <div class="col">
            <p class="lead">Razon Social</p>
            <p>{{ request.razon_social }}</p>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <p class="lead">Fecha Inicial</p>
            <p>{{ request.fecha_inicio }}</p>
          </div>
          <div class="col">
            <p class="lead">Fecha Final</p>
            <p>{{ request.fecha_final }}</p>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <p class="lead">Tecnico Responsable</p>
            <p>{{ request.tecnico_responsable }}</p>
          </div>
          <div class="col">
            <p class="lead">Prueba Realizar</p>
            <p>{{ request.prueba_realizar }}</p>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <p class="lead">Especie</p>
            <p>{{ request.especie }}</p>
          </div>
          <div class="col">
            <p class="lead">Cant Animales</p>
            <p>{{ request.cantidad_animales }}</p>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <p class="lead">Objetivo</p>
            <p>{{ request.objetivo }}</p>
          </div>
          <div class="col">
            <p class="lead">Resumen Protocolo</p>
            <p>{{ request.resumen_protocolo }}</p>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <p class="lead">Comentarios</p>
            <p>{{ request.comentarios }}</p>
          </div>
          <div class="col">
            <p class="lead">Compromiso Cliente</p>
            <p>{{ request.compromiso_cliente }}</p>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <p class="lead">Fecha Analisis</p>
            <p>{{ request.fecha_analisis_resultado }}</p>
          </div>
          <div class="col">
            <p class="lead">Fecha Presente</p>
            <p>{{ request.fecha_presente_contrato }}</p>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <p class="lead">Usuario</p>
            <p>{{ request.email }}</p>
          </div>
          <div class="col"></div>
        </div>
      </div>
    </div>
    <div class="card mb-4">
      <div class="card-body">
        <table id="table" class="table table-bordered">
          <thead>
            <tr>
              <th>Producto</th>
              <th>Cantidad</th>
              <th>Precio Unitario</th>
              <th>Moneda</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="row in request.detalle" :key="row.id">
              <td>{{ row.producto }}</td>
              <td>{{ row.cantidad }}</td>
              <td>{{ row.precio }}</td>
              <td>{{ row.moneda }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </AppLayout>
</template>

<script>
import api from "@/api.js"
import AppLayout from "@/components/layout/AppLayout.vue";
export default {
  name: "requestdetail",
  components: {
    AppLayout,
  },
  data() {
    return {
      request: {},
      id: null,
      type: null
    };
  },
  mounted: function(){
    this.id = this.$route.params.id;
    this.type = this.$route.params.type;
    this.getRequest();
  },
  methods: {
    getRequest: function() {
      let that = this;
      let loader = this.$loading.show();
      if(this.type == 'compra') {
      api
        .getRequestPurchase(that.id)
        .then(data => {
          that.request = data.request;
          loader.hide();
        })
        .catch((error) => {
          that.showAlert("error", error.response.data.message);
          loader.hide();
          if(error.response.status === 401){
            localStorage.removeItem("userToken");
            that.$router.push('/login');
          }
        });
      }else{
      api
        .getRequestSample(that.id)
        .then(data => {
          that.request = data.request;
          loader.hide();
        })
        .catch((error) => {
          that.showAlert("error", error.response.data.message);
          loader.hide();
          if(error.response.status === 401){
            localStorage.removeItem("userToken");
            that.$router.push('/login');
          }
        });

      }
    },
    showAlert: function(type, message) {
      this.$swal({
        position: "top-end",
        icon: type,
        title: message,
        showConfirmButton: false,
        timer: 1500
      });
    }
  }
};
</script>
