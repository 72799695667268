<template>
  <AppLayout>
    <h2 class="mb-4">Detalle Pedido</h2>
    <div class="card mb-4">
      <div class="card-body">
        <div id="divSt0">
          <div class="row">
            <div class="col-md-4">
              <div class="form-group">
                <label class="font-weight-bold">Cliente</label>
                <p class="lead">
                  {{ cliente }}
                </p>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group">
                <label class="font-weight-bold">Sucursal</label>
                <p class="lead">
                  {{ sucursal }}
                </p>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group">
                <label class="font-weight-bold">Bodega</label>
                <p class="lead">
                  {{ bodega }}
                </p>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <p class="font-weight-bold">Detalle</p>
              <div class="form-group">
                <table class="table table-hover">
                  <thead>
                    <tr>
                      <th>Número Lote</th>
                      <th>Producto</th>
                      <th>Cantidad</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(row, index) in detalle" :key="row[index]">
                      <td>{{ row.lote_numero }}</td>
                      <td>{{ row.producto }}</td>
                      <td>{{ row.cantidad }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div v-if="detalle2 != null" class="row mt-5">
            <div class="col-md-12">
              <p class="font-weight-bold">Detalle de la solicitud</p>
              <div class="form-group">
                <table class="table table-hover">
                  <thead>
                    <tr>
                      <th>Producto</th>
                      <th>Cantidad</th>
                      <th>Precio</th>
                      <th>Moneda</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(row, index) in detalle2" :key="row[index]">
                      <td>{{ row.producto }}</td>
                      <td>{{ row.cantidad }}</td>
                      <td>{{ row.precio }}</td>
                      <td>{{ row.moneda }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </AppLayout>
</template>

<script>
import api from "@/api.js";
import AppLayout from "@/components/layout/AppLayout.vue";
export default {
  name: "preorderdetail",
  components: {
    AppLayout,
  },
  data() {
    return {
      id: null,
      bodega: null,
      cantidad: null,
      sucursal: null,
      cliente: null,
      detalle: [],
      detalle2: [],
    };
  },
  mounted: function () {
    this.id = this.$route.params.id;
    this.getPreorder();
  },
  methods: {
    getPreorder: function () {
      let that = this;
      let loader = this.$loading.show();
      api
        .getPreorder(that.id)
        .then((data) => {
          that.bodega = data.preorder.bodega;
          that.cliente = data.preorder.cliente;
          that.sucursal = data.preorder.sucursal;
          that.detalle = data.preorder.detalle;
          that.detalle2 = data.preorder.detalle2;
          loader.hide();
        })
        .catch((error) => {
          that.showAlert("error", error.response.data.message);
          loader.hide();
          if (error.response.status === 401) {
            localStorage.removeItem("userToken");
            that.$router.push("/login");
          }
        });
    },
    showAlert: function (type, message) {
      this.$swal({
        position: "top-end",
        icon: type,
        title: message,
        showConfirmButton: false,
        timer: 1500,
      });
    },
  },
};
</script>
