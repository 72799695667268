<template>
  <form @submit.prevent="registerCommon">
    <div v-for="(row) in dataField" :key="row.id" class="form-group">
      <div v-if="row.tipo !== 'list'">
        <label>{{ row.nombre }}</label>
        <input v-model="row.nombre2" :type="row.tipo" :required="row.requerido" class="form-control" />
      </div>
      <div v-else>
        <label>{{ row.nombre }}</label>
        <select v-model="row.nombre2" class="form-control">
          <option v-for="row1 in dataListValues[row.nombre.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase()]" :key="row1.nombre" :value="row1.id">{{ row1.nombre }}</option>
        </select>
      </div>
    </div>
    <button class="btn btn-primary" type="submit">
      <font-awesome-icon icon="save" /> Guardar
    </button>
  </form>
</template>

<script>
import api from "@/api.js";
export default {
  name: "commonform",
  props: {
    isRegister: Boolean,
    nodeCommon: String,
    dataCommon: Object
  },
  data() {
    return {
      dataListValues: [],
      dataList: [],
      dataField: [],
      dataLoaded: [],
      isLoading: true,
      parentLoaded: false,
    };
  },
  created: function() {
    this.getFields();
  },
  watch: {
    dataCommon: function(value) {
      if(!this.$data.isLoading){
        this.$data.dataField.forEach(field => {
          if(field.tipo === 'list'){
            field.nombre2 = value['id_' + field.nombre.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase()];
          }else{
            field.nombre2 = value[field.nombre.normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/ /g, '_').toLowerCase()];
          }
        });
      }else{
        this.$data.parentLoaded = true;
      }
    }
  },
  methods: {
    registerCommon: function() {
      let formEmpty = false;
      let arr = {};
      this.dataField.forEach(item => {
        if (item.requerido && item.nombre2 === "") {
          formEmpty = true;
        }
      });
      if (!formEmpty) {
        this.dataField.forEach(item => {
          item.nombre = item.nombre.normalize('NFD').replace(/[\u0300-\u036f]/g, '').replace(/ /g, '_').toLowerCase();
          if(item.tipo === 'list'){
            arr['id_' + item.nombre] = item.nombre2;
          }else{
            arr[item.nombre] = item.nombre2;
          }
        });
        this.$emit("registerCommon", arr);
      } else {
        this.$swal({
          position: "top-end",
          icon: "error",
          title: "Completa los campos",
          showConfirmButton: false,
          timer: 1500
        });
      }
    },
    getFields: function() {
      let that = this;
      this.dataField.length = 0;
      let promises = [];
      let tableNames = ['bodega','cliente','producto','proveedor','sucursal'];
      api
        .getFields(tableNames.findIndex(table => table === that.nodeCommon) + 1)
        .then((data) => {
          promises.push(data.fields.forEach(field => {
            that.dataField.push({ ...field, nombre2: "" });
            if (field.tipo === "list") {
              that.dataList.push(field.nombre);
            }
          }));
          Promise.all(promises).then(() => {
            if(that.dataList.length > 0) {
              that.dataList.forEach(item => {
                let itemV = item.normalize('NFD').replace(/[\u0300-\u036f]/g, '').replace(/ /g, '_').toLowerCase();
                switch(itemV){
                  case 'pais': {
                    api
                      .getCountries()
                      .then((data) => that.$set(that.dataListValues,itemV,data.countries))
                      .catch((error) => {
                        that.showAlert(error.response.data.message);
                        if(error.response.status === 401){
                          localStorage.removeItem("userToken");
                          that.$router.push('/login');
                        }
                      });
                    break;
                  }
                  case 'ciudad': {
                    api
                      .getCities()
                      .then((data) => that.$set(that.dataListValues,itemV,data.cities))
                      .catch((error) => {
                        that.showAlert(error.response.data.message);
                        if(error.response.status === 401){
                          localStorage.removeItem("userToken");
                          that.$router.push('/login');
                        }
                      });
                    break;
                  }
                  case 'departamento': {
                    api
                      .getStates()
                      .then((data) => that.$set(that.dataListValues,itemV,data.states))
                      .catch((error) => {
                        that.showAlert(error.response.data.message);
                        if(error.response.status === 401){
                          localStorage.removeItem("userToken");
                          that.$router.push('/login');
                        }
                      });
                    break;
                  }
                  case 'medida': {
                    api
                      .getMeasures()
                      .then((data) => that.$set(that.dataListValues,itemV,data.measures))
                      .catch((error) => {
                        that.showAlert(error.response.data.message);
                        if(error.response.status === 401){
                          localStorage.removeItem("userToken");
                          that.$router.push('/login');
                        }
                      });
                    break;
                  }
                  case 'cliente': {
                    api
                      .getClients()
                      .then((data) => that.$set(that.dataListValues,itemV,data.clients))
                      .catch((error) => {
                        that.showAlert(error.response.data.message);
                        if(error.response.status === 401){
                          localStorage.removeItem("userToken");
                          that.$router.push('/login');
                        }
                      });
                    break;
                  }
                  case 'proveedor': {
                    api
                      .getProviders()
                      .then((data) => that.$set(that.dataListValues,itemV,data.providers))
                      .catch((error) => {
                        that.showAlert(error.response.data.message);
                        if(error.response.status === 401){
                          localStorage.removeItem("userToken");
                          that.$router.push('/login');
                        }
                      });
                    break;
                  }
                }
              });
            }
            that.isLoading = false
            if(that.parentLoaded){
              that.dataField.forEach(field => {
                if(field.tipo === 'list'){
                  field.nombre2 = that.dataCommon['id_' + field.nombre.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase()];
                }else{
                  field.nombre2 = that.dataCommon[field.nombre.normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/ /g, '_').toLowerCase()];
                }
              });
            }
          });
        })
        .catch((error) => {
          that.showAlert(error.response.data.message);
          if(error.response.status === 401){
            localStorage.removeItem("userToken");
            that.$router.push('/login');
          }
        });
    },
  }
};
</script>
