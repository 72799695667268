<template>
  <AppLayout>
    <h2 class="mb-4">
      Listado de Solicitudes Pendientes
      <router-link
        v-if="userType === 'Admin' || userModules.includes('Facturas')"
        class="btn btn-primary float-right"
        :to="{ path: '/' + nodePreorder + '/register' }"
      >
        <font-awesome-icon icon="plus" /> Asociar Pedidos
      </router-link>
    </h2>
    <div class="card mb-4">
      <div class="card-body">
        <ul class="nav nav-tabs" id="myTab" role="tablist">
          <li class="nav-item" role="presentation">
            <a class="nav-link active" id="tab1" data-toggle="tab" href="#div1" role="tab" aria-controls="div1" aria-selected="true">Compra</a>
          </li>
          <li class="nav-item" role="presentation">
            <a class="nav-link" id="tab2" data-toggle="tab" href="#div2" role="tab" aria-controls="div2" aria-selected="false">Muestra</a>
          </li>
        </ul>
        <div class="tab-content" id="myTabContent">
          <div class="tab-pane fade show active" id="div1" role="tabpanel" aria-labelledby="tab1">
            <table id="table1" class="table table-bordered">
              <thead>
                <tr>
                  <th>Numero Orden Compra</th>
                  <th>Cliente</th>
                  <th>Fecha</th>
                  <th>Tiempo</th>
                  <th>Usuario</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="row in datRequest.purchases" :key="row.id">
                  <td>{{ row.num_orden_compra }}</td>
                  <td>{{ row.cliente }}</td>
                  <td>{{ row.fecha_entrega }}</td>
                  <td>{{ row.tiempo_entrega }} Horas Habiles</td>
                  <td>{{ row.email }}</td>
                  <td>
                    <router-link class="btn btn-primary" :to="{ path: '/solicitudes/compra/detail/' + row.id }">
                      <font-awesome-icon icon="eye" /> Ver
                    </router-link>&nbsp;
                    <button class="btn btn-danger" @click="deleteRequest(row.id, 'purchase')">
                      <font-awesome-icon icon="trash-alt" /> Eliminar
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="tab-pane fade" id="div2" role="tabpanel" aria-labelledby="tab2">
            <table id="table2" class="table table-bordered">
              <thead>
                <tr>
                  <th>Numero Orden Compra</th>
                  <th>Razon Social</th>
                  <th>Fecha</th>
                  <th>Tiempo</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="row in datRequest.samples" :key="row.id">
                  <td>{{ row.num_orden_compra }}</td>
                  <td>{{ row.razon_social }}</td>
                  <td>{{ row.fecha_entrega }}</td>
                  <td>{{ row.tiempo_entrega }} Horas Habiles</td>
                  <td>
                    <router-link class="btn btn-primary" :to="{ path: '/solicitudes/muestra/detail/' + row.id }">
                      <font-awesome-icon icon="eye" /> Ver
                    </router-link>&nbsp;
                    <button class="btn btn-danger" @click="deleteRequest(row.id, 'sample')">
                      <font-awesome-icon icon="trash-alt" /> Eliminar
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </AppLayout>
</template>

<script>
const $ = require("jquery");
$.DataTable = require("datatables.net");
import "datatables.net-bs4/css/dataTables.bootstrap4.min.css";
import "datatables.net-bs4/js/dataTables.bootstrap4.min.js";
import "datatables.net-buttons/js/dataTables.buttons.min.js";
import "datatables.net-buttons/js/buttons.html5.min.js";
import "datatables.net-buttons/js/buttons.flash.min.js";
import "datatables.net-buttons/js/buttons.print.min.js";
import "datatables.net-buttons-bs4/css/buttons.bootstrap4.min.css";
import "datatables.net-buttons-bs4/js/buttons.bootstrap4.min.js";
window.JSZip = require( 'jszip' );
require( 'pdfmake' );

import api from "@/api.js"
import AppLayout from "@/components/layout/AppLayout.vue"
export default {
  name: "preorderrequests",
  components: {
    AppLayout,
  },
  data() {
    return {
      datRequest: [],
      nodePreorder: "pedido",
      userType: "Admin",
      userModules: [],
    };
  },
  created: function() {
    this.getRequests();
    this.$data.userType = localStorage.getItem("userType");
    this.$data.userModules = localStorage.getItem("userModules");
  },
  updated: function () {
    $("#table1").DataTable({
      destroy     : true,
      paging      : true,
      lengthChange: true,
      searching   : true,
      ordering    : true,
      info        : true,
      autoWidth   : false,
      retrieve    : true,
      dom: 'Blfrtip',
      buttons: [ 
        { 
          extend: 'excel', className: 'btn btn-secondary'
        },
      ],
      language: {
        "url": "//cdn.datatables.net/plug-ins/9dcbecd42ad/i18n/Spanish.json"
      },
    });
    $("#table2").DataTable({
      destroy     : true,
      paging      : true,
      lengthChange: true,
      searching   : true,
      ordering    : true,
      info        : true,
      autoWidth   : false,
      retrieve    : true,
      dom: 'Blfrtip',
      buttons: [ 
        { 
          extend: 'excel', className: 'btn btn-secondary'
        },
      ],
      language: {
        "url": "//cdn.datatables.net/plug-ins/9dcbecd42ad/i18n/Spanish.json"
      },
    });
  },
  methods: {
    getRequests: function() {
      let that = this;
      let loader = this.$loading.show();
      this.datRequest.length = 0;
      api
        .getRequests()
        .then((data) => {
          that.datRequest = data.requests;
          loader.hide();
        })
        .catch((error) => {
          that.showAlert(error.response.data.message);
          loader.hide();
          if(error.response.status === 401){
            localStorage.removeItem("userToken");
            that.$router.push('/login');
          }
        });
    },
    deleteRequest: function(id, type) {
      let that = this;
      this.$swal({
        title: "Quieres Eliminar?",
        text: "Está seguro de querer eliminar esto",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si",
        cancelButtonText: "No"
      }).then(result => {
        if (result.value) {
          let loader = this.$loading.show();
          api
            .deleteRequest(id, type)
            .then(() => {
              loader.hide();
              location.reload();
            })
            .catch((error) => {
              that.showAlert(error.response.data.message);
              loader.hide();
              if(error.response.status === 401){
                localStorage.removeItem("userToken");
                that.$router.push('/login');
              }
            });
        }
      });
    },
    showAlert: function(message) {
      this.$swal({
        position: "top-end",
        icon: "error",
        title: message,
        showConfirmButton: false,
        timer: 1500
      });
    }
  }
};
</script>
