<template>
  <AppLayout>
    <h2 class="mb-4">
      Listado de Pedidos
      <router-link
        v-if="(userType === 'Admin' || userModules.includes('Facturas')) && userType !== 'Invitado'"
        class="btn btn-primary float-right"
        :to="{ path: '/' + nodePreorder + '/register' }"
      >
        <font-awesome-icon icon="plus" /> Registrar
      </router-link>
    </h2>
    <div class="card mb-4">
      <div class="card-body">
        <table id="table" class="table table-bordered">
          <thead>
            <tr>
              <th>Consecutivo</th>
              <th>Bodega</th>
              <th>Sucursal</th>
              <th># Orden</th>
              <th># Factura</th>
              <th v-if="userType === 'Admin' || userModules.includes('Facturas')">Acciones</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="row in dataPreorder" :key="row.id">
              <td>{{ row.id.toString().padStart(4, '0') }}</td>
              <td>{{ row.bodega }}</td>
              <td>{{ row.sucursal }}</td>
              <td>{{ row.id_orden }}</td>
              <td>{{ row.id_factura }}</td>
              <td v-if="userType === 'Admin' || userModules.includes('Facturas')">
                <router-link class="btn btn-primary" :to="{ path: '/pedido/detail/' + row.id }">
                  <font-awesome-icon icon="eye" /> Detalle
                </router-link>&nbsp;
                <router-link v-show="userType === 'Admin' || userType === 'Full Access' || userType === 'Comercial' || userType === 'Editor'" v-if="!row.asignado" class="btn btn-primary" :to="{ path: '/pedido/edit/' + row.id }">
                  <font-awesome-icon icon="edit" /> Editar
                </router-link>&nbsp;
                <button v-show="userType === 'Admin' || userType === 'Full Access' || userType === 'Comercial'" v-if="!row.asignado" class="btn btn-danger" @click="deletePreorder(row.id)">
                  <font-awesome-icon icon="trash-alt" /> Eliminar
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </AppLayout>
</template>

<script>
const $ = require("jquery");
$.DataTable = require("datatables.net");
import "datatables.net-bs4/css/dataTables.bootstrap4.min.css";
import "datatables.net-bs4/js/dataTables.bootstrap4.min.js";
import "datatables.net-buttons/js/dataTables.buttons.min.js";
import "datatables.net-buttons/js/buttons.html5.min.js";
import "datatables.net-buttons/js/buttons.flash.min.js";
import "datatables.net-buttons/js/buttons.print.min.js";
import "datatables.net-buttons-bs4/css/buttons.bootstrap4.min.css";
import "datatables.net-buttons-bs4/js/buttons.bootstrap4.min.js";
window.JSZip = require( 'jszip' );
require( 'pdfmake' );

import api from "@/api.js"
import AppLayout from "@/components/layout/AppLayout.vue"
export default {
  name: "preorderlist",
  components: {
    AppLayout,
  },
  data() {
    return {
      dataPreorder: [],
      nodePreorder: "pedido",
      userType: "Admin",
      userModules: [],
    };
  },
  created: function() {
    this.getPreorders();
    this.$data.userType = localStorage.getItem("userType");
    this.$data.userModules = localStorage.getItem("userModules");
  },
  updated: function () {
    $("#table").DataTable({
      destroy     : true,
      paging      : true,
      lengthChange: true,
      searching   : true,
      ordering    : true,
      info        : true,
      autoWidth   : false,
      retrieve    : true,
      dom: 'Blfrtip', 
      buttons: [ 
        { 
          extend: 'excel', className: 'btn btn-secondary'
        },
      ],
      language: {
        "url": "//cdn.datatables.net/plug-ins/9dcbecd42ad/i18n/Spanish.json"
      },
    });
  },
  methods: {
    getPreorders: function() {
      let that = this;
      let loader = this.$loading.show();
      this.dataPreorder.length = 0;
      api
        .getPreorders()
        .then((data) => {
          that.dataPreorder = data.preorders;
          loader.hide();
        })
        .catch((error) => {
          that.showAlert(error.response.data.message);
          loader.hide();
          if(error.response.status === 401){
            localStorage.removeItem("userToken");
            that.$router.push('/login');
          }
        });
    },
    deletePreorder: function(id) {
      let that = this;
      this.$swal({
        title: "Quieres Eliminar?",
        text: "Está seguro de querer eliminar esto",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si",
        cancelButtonText: "No"
      }).then(result => {
        if (result.value) {
          let loader = this.$loading.show();
          api
            .deletePreorder(id)
            .then(() => {
              that.dataPreorder = that.dataPreorder.filter(preorder => preorder.id != id);
              $("#table").DataTable().destroy();
              loader.hide();
            })
            .catch((error) => {
              that.showAlert(error.response.data.message);
              loader.hide();
              if(error.response.status === 401){
                localStorage.removeItem("userToken");
                that.$router.push('/login');
              }
            });
        }
      });
    },
    showAlert: function(message) {
      this.$swal({
        position: "top-end",
        icon: "error",
        title: message,
        showConfirmButton: false,
        timer: 1500
      });
    }
  }
};
</script>
