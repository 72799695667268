<template>
    <AppLayout>
        <h2 class="mb-4">Inventario de Bodega {{ bodega }}</h2>
        <div class="card mb-4">
          <div class="card-body">
            <ul class="nav nav-tabs">
              <li class="nav-item">
                <a class="active nav-link" data-toggle="tab" href="#tabMovement" role="tab">
                  Movimientos
                  <span class="badge badge-primary">{{ dataMovement.length }}</span>
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" data-toggle="tab" href="#tabProduct" role="tab">
                  Productos
                </a>
              </li>
            </ul>
            <div class="tab-content">
              <div class="active tab-pane table-responsive" id="tabMovement" role="tabpanel">
                <table id="table" class="table table-bordered">
                  <thead>
                    <tr>
                      <th>Tipo</th>
                      <th>Fecha</th>
                      <th>Proveedor</th>
                      <th>Sucursal</th>
                      <th>Bodega</th>
                      <th># Factura/Orden</th>
                      <th>Lote</th>
                      <th>Vencimiento</th>
                      <th>Producto</th>
                      <th>Cantidad</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="row in dataMovement" :key="row.id">
                      <td v-if="row.id_orden_entrada" style="color:green">Entrada</td>
                      <td v-if="row.id_orden_salida" style="color:red">Salida</td>
                      <td v-if="row.id_orden_traslado && row.tipo_traslado === 0" style="color:red">Traslado Bodega</td>
                      <td v-if="row.id_orden_traslado && row.tipo_traslado === 1" style="color:green">Traslado Bodega</td>
                      <td v-if="row.id_orden_transformacion && row.tipo_traslado === 0" style="color:red">Transformación de Producto</td>
                      <td v-if="row.id_orden_transformacion && row.tipo_traslado === 1" style="color:green">Transformación de Producto</td>
                      <td v-if="row.id_devolucion" style="color:green">Devolucion</td>
                      <td>{{ row.fecha.substr(0,10) }}</td>
                      <td>{{ row.proveedor }}</td>
                      <td>{{ row.sucursal }}</td>
                      <td>{{ row.bodega_traslado }}</td>
                      <td v-if="row.id_orden_entrada">{{ row.id_orden_entrada.toString().padStart(4, "0") }}</td>
                      <td v-if="row.id_orden_salida">{{ row.id_orden_salida.toString().padStart(4, "0") }}</td>
                      <td v-if="row.id_orden_traslado">{{ row.id_orden_traslado.toString().padStart(4, "0") }}</td>
                      <td v-if="row.id_orden_transformacion">{{ row.id_orden_transformacion.toString().padStart(4, "0") }}</td>
                      <td v-if="row.id_devolucion">{{ row.id_devolucion.toString().padStart(4, "0") }}</td>
                      <td>{{ row.lote_numero }}</td>
                      <td>{{ row.lote_vencimiento ? row.lote_vencimiento.substr(0,10) : null }}</td>
                      <td>{{ row.producto }}</td>
                      <td v-if="row.id_orden_salida || (row.id_orden_traslado && row.tipo_traslado === 0) || (row.id_orden_transformacion && row.tipo_traslado === 0)">-{{ row.cantidad }}</td>
                      <td v-else>{{ row.cantidad }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="tab-pane" id="tabProduct" role="tabpanel">
                <div class="mt-4 mb-4 btn-group float-right" role="group" aria-label="First group">
                  <p>Ordenar por:</p> 
                  <button type="button" id="btnLote" class="ml-4 actives btn btn-primary" @click="groupListProduct(false)">Lotes</button> 
                  <button type="button" id="btnProduct" class="btn btn-primary" @click="groupListProduct(true)">Productos</button>
                </div>
                <table class="table table-bordered">
                  <thead>
                    <tr>
                      <th v-if="!groupByProducts">Número Lote</th>
                      <th>Código</th>
                      <th>Nombre</th>
                      <th>Cantidad</th>
                      <th>Cantidad Reservadas</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="row in dataStockTemp" :key="row.id">
                      <td v-if="!groupByProducts">{{ row.lote_numero }}</td>
                      <td>{{ row.codigo }}</td>
                      <td>{{ row.nombre }}</td>
                      <td>{{ row.cantidad }}</td>
                      <td>{{ row.cantidad_pendiente }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div> 
    </AppLayout>
</template>

<script>
const $ = require("jquery");
$.DataTable = require("datatables.net");
import "datatables.net-bs4/css/dataTables.bootstrap4.min.css";
import "datatables.net-bs4/js/dataTables.bootstrap4.min.js";
import "datatables.net-buttons/js/dataTables.buttons.min.js";
import "datatables.net-buttons/js/buttons.html5.min.js";
import "datatables.net-buttons/js/buttons.flash.min.js";
import "datatables.net-buttons/js/buttons.print.min.js";
import "datatables.net-buttons-bs4/css/buttons.bootstrap4.min.css";
import "datatables.net-buttons-bs4/js/buttons.bootstrap4.min.js";
window.JSZip = require( 'jszip' );
require( 'pdfmake' );

import api from "@/api.js"
import AppLayout from '@/components/layout/AppLayout.vue'
export default {
  name: 'warehousestock',
  components: {
    AppLayout,
  },
  data() {
    return {
      bodega: "",
      groupByProducts: false,
      warehouseId: "",
      dataMovement: [],
      nodeMovement: 'movimiento',
      dataStock: [],
      dataStockTemp: [],
      nodeProduct: 'inventario',
    }
  },
  created: function () {
    this.warehouseId = this.$route.params.id;
    this.getStock();
  },
  updated: function () {
    $("#table").DataTable({ 
      retrieve: true,
      dom: 'Blfrtip', 
      buttons: [ 
        { 
          extend: 'excel', className: 'btn btn-secondary'
        },
      ],
      language: {
        "url": "//cdn.datatables.net/plug-ins/9dcbecd42ad/i18n/Spanish.json"
      },
    });
  },
  methods: {
    getStock: function () {
      let that = this;
      let loader = this.$loading.show();
      this.dataStock.length = 0;
      api
        .getMovements(that.warehouseId)
        .then((data) => {
          that.dataMovement = data.movements;
          api
            .getStock(that.warehouseId)
            .then((data) => {
              that.dataStock = data.stock;
              if(that.dataStock.length > 0){
                that.bodega = that.dataStock[0].bodega;
              }
              that.dataStockTemp = data.stock;
              loader.hide();
            })
            .catch((error) => {
              that.showAlert(error.response.data.message);
              loader.hide();
              if(error.response.status === 401){
                localStorage.removeItem("userToken");
                that.$router.push('/login');
              }
            });
        })
        .catch((error) => {
          that.showAlert(error.response.data.message);
          loader.hide();
          if(error.response.status === 401){
            localStorage.removeItem("userToken");
            that.$router.push('/login');
          }
        });
    },
    groupListProduct: function(groupByProducts) {
      this.groupByProducts = groupByProducts;
      if(this.groupByProducts) {
        document.getElementById("btnProduct").classList.add("actives")
        document.getElementById("btnLote").classList.remove("actives")
        let array = this.dataStock
        var result = [];
        array.reduce(function(res, value) {
          if (!res[value.codigo]) {
            res[value.codigo] = { codigo: value.codigo, nombre: value.nombre, cantidad: 0, cantidad_pendiente: 0 };
            result.push(res[value.codigo])
          }
          res[value.codigo].cantidad += value.cantidad;
          res[value.codigo].cantidad_pendiente += value.cantidad_pendiente;
          return res;
        }, {});
        this.dataStockTemp = result;
      }else{
        document.getElementById("btnLote").classList.add("actives")
        document.getElementById("btnProduct").classList.remove("actives")
        this.dataStockTemp = this.dataStock;
      }
    },
    showAlert: function(message) {
      this.$swal({
        position: "top-end",
        icon: "error",
        title: message,
        showConfirmButton: false,
        timer: 1500
      });
    }
  }
}
</script>
