<template>
  <AppLayout>
    <h2 class="mb-4">
      Listado de Devoluciones
      <router-link
        v-if="(userType === 'Admin' || userModules.includes('Facturas')) && userType !== 'Invitado'"
        class="btn btn-primary float-right"
        :to="{ path: '/' + nodeRefund + '/register' }"
      >
        <font-awesome-icon icon="plus" /> Registrar
      </router-link>
    </h2>
    <div class="card mb-4">
      <div class="card-body">
        <table id="table" class="table table-bordered">
          <thead>
            <tr>
              <th># Orden</th>
              <th># Factura</th>
              <th># Pedido</th>
              <th>Bodega Destino</th>
              <th>Autorizante</th>
              <th v-if="userType === 'Admin' || userModules.includes('Facturas')">Acciones</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="row in dataRefund" :key="row.id">
              <td>{{ row.id_orden.toString().padStart(4, "0") }}</td>
              <td>NPX {{ row.id_factura }}</td>
              <td>{{ row.id_pedido == null ? "" : row.id_pedido.toString().padStart(4, "0") }}</td>
              <td>{{ row.bodega }}</td>
              <td>{{ row.autorizante }}</td>
              <td v-if="userType === 'Admin' || userModules.includes('Facturas')">
                <router-link class="btn btn-primary" :to="{ path: '/devolucion/detail/' + row.id }">
                  <font-awesome-icon icon="eye" /> Detalle
                </router-link>&nbsp;
                <!--
                <button class="btn btn-danger" @click="deleteRefund(row.id)">
                  <font-awesome-icon icon="trash-alt" /> Eliminar
                </button>
                -->
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </AppLayout>
</template>

<script>
const $ = require("jquery");
$.DataTable = require("datatables.net");
import "datatables.net-bs4/css/dataTables.bootstrap4.min.css";
import "datatables.net-bs4/js/dataTables.bootstrap4.min.js";
import "datatables.net-buttons/js/dataTables.buttons.min.js";
import "datatables.net-buttons/js/buttons.html5.min.js";
import "datatables.net-buttons/js/buttons.flash.min.js";
import "datatables.net-buttons/js/buttons.print.min.js";
import "datatables.net-buttons-bs4/css/buttons.bootstrap4.min.css";
import "datatables.net-buttons-bs4/js/buttons.bootstrap4.min.js";
window.JSZip = require( 'jszip' );
require( 'pdfmake' );

import api from "@/api.js"
import AppLayout from "@/components/layout/AppLayout.vue"
export default {
  name: "returnlist",
  components: {
    AppLayout,
  },
  data() {
    return {
      dataRefund: [],
      nodeRefund: "devolucion",
      userType: "Admin",
      userModules: [],
    };
  },
  created: function() {
    this.getRefunds();
    this.$data.userType = localStorage.getItem("userType");
    this.$data.userModules = localStorage.getItem("userModules");
  },
  updated: function () {
    $("#table").DataTable({
      destroy     : true,
      paging      : true,
      lengthChange: true,
      searching   : true,
      ordering    : true,
      info        : true,
      autoWidth   : false,
      retrieve    : true,
      dom: 'Blfrtip', 
      buttons: [ 
        { 
          extend: 'excel', className: 'btn btn-secondary'
        },
      ],
      language: {
        "url": "//cdn.datatables.net/plug-ins/9dcbecd42ad/i18n/Spanish.json"
      },
    });
  },
  methods: {
    getRefunds: function() {
      let that = this;
      let loader = this.$loading.show();
      this.dataRefund.length = 0;
      api
        .getRefunds()
        .then((data) => {
          that.dataRefund = data.refunds;
          loader.hide();
        })
        .catch((error) => {
          that.showAlert(error.response.data.message);
          loader.hide();
          if(error.response.status === 401){
            localStorage.removeItem("userToken");
            that.$router.push('/login');
          }
        });
    },
    deleteRefund: function(id) {
      let that = this;
      this.$swal({
        title: "Quieres Eliminar?",
        text: "Está seguro de querer eliminar esto",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si",
        cancelButtonText: "No"
      }).then(result => {
        if (result.value) {
          let loader = this.$loading.show();
          api
            .deleteRefund(id)
            .then(() => {
              that.dataRefund = that.dataRefund.filter(refund => refund.id != id);
              $("#table").DataTable().destroy();
              loader.hide();
            })
            .catch((error) => {
              that.showAlert(error.response.data.message);
              loader.hide();
              if(error.response.status === 401){
                localStorage.removeItem("userToken");
                that.$router.push('/login');
              }
            });
        }
      });
    },
    showAlert: function(message) {
      this.$swal({
        position: "top-end",
        icon: "error",
        title: message,
        showConfirmButton: false,
        timer: 1500
      });
    }
  }
};
</script>
