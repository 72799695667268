<template>
  <AppLayout>
    <h2 class="mb-4">Registro de Factura</h2>
    <div class="card mb-4">
      <div class="card-body">
        <form @submit.prevent="registerInvoice">
          <div id="divSt0">
            <div class="row">
              <div class="col-md-3">
                <div class="form-group">
                  <label>Pedido</label>
                  <select v-model="id_pedido" @change="onChangePreorder($event)" class="form-control">
                    <option v-for="row in dataPreorder" :key="row.id" :value="row.id">{{ row.id.toString().padStart(4, '0') + " - orden " + row.id_orden }}</option>
                  </select>
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <label>Prefijo</label>
                  <input v-model="prefijo" type="text" class="form-control" />
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <label>Consecutivo Nux</label>
                  <input v-model="id" type="text" disabled class="form-control" />
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <label>Consecutivo</label>
                  <input v-model="consecutivo" type="text" class="form-control" />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-3">
                <div class="form-group">
                  <label>Cliente</label>
                  <input id="clientItem0" type="text" disabled class="form-control" />
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <label>Sucursal</label>
                  <input id="subsidiaryItem0" type="text" disabled class="form-control" />
                  <input v-model="id_sucursal" type="hidden" />
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <label>Dirección</label>
                  <input id="subsidiaryItem1" type="text" disabled class="form-control" />
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <label>Contacto</label>
                  <input id="subsidiaryItem2" type="text" disabled class="form-control" />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-3">
                <div class="form-group">
                  <label>Bodega</label>
                  <input id="warehouseItem0" type="text" disabled class="form-control" />
                  <input v-model="id_bodega" type="hidden" />
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <label>Concepto</label>
                  <input v-model="concepto" type="text" class="form-control" />
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <label>Forma de Pago</label>
                  <select v-model="forma_pago" class="form-control">
                    <option>Crédito</option>
                    <option>Cheque</option>
                    <option>Débito</option>
                    <option>Efectivo</option>
                    <option>Transferencia</option>
                  </select>
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <label>Fecha Entregada</label>
                  <input v-model="fecha_entregada" type="date" class="form-control" />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <div class="form-group">
                  <table class="table table-hover">
                    <thead>
                      <tr>
                        <th>Número Lote</th>
                        <th>Producto</th>
                        <th>Cantidad</th>
                        <th>Precio Unitario</th>
                        <th>% Iva</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(row, index) in detalle" :key="row[index]">
                        <td>{{ row.lote_numero }}</td>
                        <td>
                          {{ row.producto }}
                          <input v-model="row.id_producto" type="hidden" />
                        </td>
                        <td>{{ row.cantidad }}</td>
                        <td>
                          <input v-model="row.precio" @paste="calculateTotals()" @keyup="calculateTotals()" type="number" class="form-control" />
                        </td>
                        <td>
                          <input v-model="row.iva" @paste="calculateTotals()" @keyup="calculateTotals()"  type="number" min="0" max="100" class="form-control" />
                        </td>
                      </tr>
                    </tbody>
                    <tfoot>
                      <tr>
                        <td colspan="3"></td>
                        <td><b>Subtotal:</b> {{ subtotal }}</td>
                        <td><b>Total:</b> {{ total }}</td>
                      </tr>
                    </tfoot>
                  </table>
                </div>
              </div>
            </div>
            <button class="btn btn-primary" type="submit">
              <font-awesome-icon icon="save" /> Guardar
            </button>
          </div>
        </form>
      </div>
    </div>
  </AppLayout>
</template>

<script>
import api from "@/api.js"
import AppLayout from "@/components/layout/AppLayout.vue";

export default {
  name: "invoiceregister",
  components: {
    AppLayout,
  },
  data() {
    return {
      nodeInvoice: "factura",
      dataPreorder: [],
      //data
      id: null,
      consecutivo: null,
      id_pedido: null,
      id_bodega: null,
      cantidad: null,
      id_sucursal: null,
      fecha_entregada: null,
      forma_pago: null,
      concepto: null,
      prefijo: null,
      detalle: [],
      //aux
      subtotal: 0,
      total: 0
    };
  },
  mounted: function(){
    this.getLastInvoiceId();
    this.getPreorders();
  },
  methods: {
    getLastInvoiceId: function() {
      let that = this;
      let loader = this.$loading.show();
      api
        .getLastInvoiceId()
        .then(data => {
          that.id = data.id.toString().padStart(4, "0");
          loader.hide();
        })
        .catch((error) => {
          that.showAlert("error", error.response.data.message);
          loader.hide();
          if(error.response.status === 401){
            localStorage.removeItem("userToken");
            that.$router.push('/login');
          }
        });
    },
    getPreorders: function() {
      let that = this;
      let loader = this.$loading.show();
      this.dataPreorder.length = 0;
      api
        .getPreordersWithout('invoice')
        .then(data => {
          that.dataPreorder = data.preorders;
          loader.hide();
        })
        .catch((error) => {
          that.showAlert("error", error.response.data.message);
          loader.hide();
          if(error.response.status === 401){
            localStorage.removeItem("userToken");
            that.$router.push('/login');
          }
        });
    },
    registerInvoice: function() {
      let that = this;
      let loader = this.$loading.show();
      let dataInvoice = this.$data;
      if(that.fecha_entregada && that.id_sucursal && that.id_bodega) {
        if(this.detalle.length > 0) {
          delete dataInvoice.nodeInvoice;
          dataInvoice.cantidad = this.detalle.length;
          api
            .registerInvoice(dataInvoice)
            .then(data => {
              that.showAlert("success", "Factura generada con el consecutivo: " + data.id.toString().padStart(4, "0"));
              loader.hide();
              that.$router.push("/factura");
            })
            .catch((error) => {
              that.showAlert("error", error.response.data.message);
              loader.hide();
              if(error.response.status === 401){
                localStorage.removeItem("userToken");
                that.$router.push('/login');
              }
            });
        }else{
          loader.hide();
          that.showAlert("error", "Completa el detalle");
        }
      }else{
        loader.hide();
        that.showAlert("error", "Completa los campos");
      }
    },
    onChangePreorder: function(event) {
      let that = this;
      let loader = this.$loading.show();
      this.detalle = [];
      api
        .getPreorder(event.target.value)
        .then(data => {
          data.preorder.detalle.forEach(detalle => {
            that.detalle.push({ ...detalle, precio: 0, iva: 0 })
          });
          that.id_sucursal = data.preorder.id_sucursal;
          that.id_bodega = data.preorder.id_bodega;
          document.getElementById("subsidiaryItem0").value = data.preorder.sucursal;
          document.getElementById("subsidiaryItem1").value = data.preorder.direccion;
          document.getElementById("subsidiaryItem2").value = data.preorder.telefono;
          document.getElementById("clientItem0").value = data.preorder.cliente;
          document.getElementById("warehouseItem0").value = data.preorder.bodega;
          loader.hide();
        })
        .catch((error) => {
          that.showAlert("error", error.response.data.message);
          loader.hide();
          if(error.response.status === 401){
            localStorage.removeItem("userToken");
            that.$router.push('/login');
          }
        });
    },
    calculateTotals: function() {
      setTimeout(() => {
        this.subtotal = 0;
        this.total = 0;
        this.detalle.forEach(detalle => {
          this.subtotal += Number(detalle.precio * detalle.cantidad);
          this.total += Number(detalle.precio * detalle.cantidad) + Number(((detalle.iva / 100) * detalle.precio) * detalle.cantidad);
        });
        return true;
      })
    },
    showAlert: function(type, message) {
      this.$swal({
        position: "top-end",
        icon: type,
        title: message,
        showConfirmButton: false,
        timer: 1500
      });
    }
  }
};
</script>
