import Vue from 'vue'
import VueRouter from 'vue-router'

import Home from './../views/Home.vue'
import Login from './../views/Login.vue'
//User
import UserList from './../views/user/UserList.vue'
import UserEdit from './../views/user/UserEdit.vue'
import UserRegister from './../views/user/UserRegister.vue'
import UserProfile from './../views/user/UserProfile.vue'
import UserRequests from './../views/user/UserRequests.vue'
//Forecast
import ForecastResume from './../views/forecast/ForecastResume.vue'
import ForecastList from './../views/forecast/ForecastList.vue'
import ForecastEdit from './../views/forecast/ForecastEdit.vue'
import ForecastRegister from './../views/forecast/ForecastRegister.vue'
//Client
import ClientList from './../views/client/ClientList.vue'
import ClientEdit from './../views/client/ClientEdit.vue'
import ClientRegister from './../views/client/ClientRegister.vue'
//Subsidiary
import SubsidiaryList from './../views/subsidiary/SubsidiaryList.vue'
import SubsidiaryEdit from './../views/subsidiary/SubsidiaryEdit.vue'
import SubsidiaryRegister from './../views/subsidiary/SubsidiaryRegister.vue'
//Provider
import ProviderList from './../views/provider/ProviderList.vue'
import ProviderEdit from './../views/provider/ProviderEdit.vue'
import ProviderRegister from './../views/provider/ProviderRegister.vue'
//Product
import ProductList from './../views/product/ProductList.vue'
import ProductEdit from './../views/product/ProductEdit.vue'
import ProductRegister from './../views/product/ProductRegister.vue'
//Warehouse
import WarehouseList from './../views/warehouse/WarehouseList.vue'
import WarehouseEdit from './../views/warehouse/WarehouseEdit.vue'
import WarehouseRegister from './../views/warehouse/WarehouseRegister.vue'
import WarehouseStock from './../views/warehouse/WarehouseStock.vue'
//Invoice
import InvoiceList from './../views/invoice/InvoiceList.vue'
import InvoiceRegister from './../views/invoice/InvoiceRegister.vue'
import InvoiceDetail from './../views/invoice/InvoiceDetail.vue'
//Preorder
import PreorderList from './../views/preorder/PreorderList.vue'
import PreorderEdit from './../views/preorder/PreorderEdit.vue'
import PreorderRegister from './../views/preorder/PreorderRegister.vue'
import PreorderRequests from './../views/preorder/PreorderRequests.vue'
import PreorderRequest from './../views/preorder/PreorderRequest.vue'
import PreorderDetail from './../views/preorder/PreorderDetail.vue'
//Refunds
import RefundList from './../views/refund/RefundList.vue'
import RefundRegister from './../views/refund/RefundRegister.vue'
import RefundDetail from './../views/refund/RefundDetail.vue'
//Order
import OrderList from './../views/order/OrderList.vue'
import OrderListGeneral from './../views/order/OrderListGeneral.vue'
import OrderEdit from './../views/order/OrderEdit.vue'
import OrderDetail from './../views/order/OrderDetail.vue'
import OrderRegister from './../views/order/OrderRegister.vue'
//Config
import Country from './../views/Country.vue'
import State from './../views/State.vue'
import City from './../views/City.vue'
import Measure from './../views/Measure.vue'
import Table from './../views/Table.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '*',
    redirect: '/login'
  },
  {
    path: '/',
    redirect: '/login'
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/home',
    name: 'Home',
    component: Home,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/pais',
    name: 'Country',
    component: Country,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/departamento',
    name: 'State',
    component: State,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/ciudad',
    name: 'City',
    component: City,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/medida',
    name: 'Measure',
    component: Measure,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/tabla',
    name: 'Table',
    component: Table,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/usuario',
    name: 'UserList',
    component: UserList,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/usuario/edit/:id',
    name: 'UserEdit',
    component: UserEdit,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/usuario/register',
    name: 'UserRegister',
    component: UserRegister,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/usuario/solicitudes',
    name: 'UserRequests',
    component: UserRequests,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/perfil',
    name: 'UserProfile',
    component: UserProfile,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/pronostico/resumen',
    name: 'ForecastResume',
    component: ForecastResume,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/pronostico',
    name: 'ForecastList',
    component: ForecastList,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/pronostico/edit/:id',
    name: 'ForecastEdit',
    component: ForecastEdit,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/pronostico/register',
    name: 'ForecastRegister',
    component: ForecastRegister,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/cliente',
    name: 'ClientList',
    component: ClientList,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/cliente/edit/:id',
    name: 'ClientEdit',
    component: ClientEdit,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/cliente/register',
    name: 'ClientRegister',
    component: ClientRegister,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/sucursal',
    name: 'SubsidiaryList',
    component: SubsidiaryList,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/sucursal/edit/:id/',
    name: 'SubsidiaryEdit',
    component: SubsidiaryEdit,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/sucursal/register',
    name: 'SubsidiaryRegister',
    component: SubsidiaryRegister,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/proveedor',
    name: 'ProviderList',
    component: ProviderList,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/proveedor/edit/:id',
    name: 'ProviderEdit',
    component: ProviderEdit,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/proveedor/register',
    name: 'ProviderRegister',
    component: ProviderRegister,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/producto',
    name: 'ProductList',
    component: ProductList,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/producto/edit/:id/',
    name: 'ProductEdit',
    component: ProductEdit,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/producto/register',
    name: 'ProductRegister',
    component: ProductRegister,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/bodega',
    name: 'WarehouseList',
    component: WarehouseList,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/bodega/edit/:id',
    name: 'WarehouseEdit',
    component: WarehouseEdit,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/bodega/register',
    name: 'WarehouseRegister',
    component: WarehouseRegister,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/bodega/stock/:id',
    name: 'WarehouseStock',
    component: WarehouseStock,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/factura',
    name: 'InvoiceList',
    component: InvoiceList,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/factura/register',
    name: 'InvoiceRegister',
    component: InvoiceRegister,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/factura/detail/:id',
    name: 'InvoiceDetail',
    component: InvoiceDetail,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/pedido',
    name: 'PreorderList',
    component: PreorderList,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/pedido/edit/:id',
    name: 'PreorderEdit',
    component: PreorderEdit,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/pedido/requests',
    name: 'PreorderRequests',
    component: PreorderRequests,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/solicitudes/:type/detail/:id',
    name: 'PreorderRequest',
    component: PreorderRequest,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/pedido/register',
    name: 'PreorderRegister',
    component: PreorderRegister,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/pedido/detail/:id',
    name: 'PreorderDetail',
    component: PreorderDetail,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/devolucion',
    name: 'RefundList',
    component: RefundList,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/devolucion/register',
    name: 'RefundRegister',
    component: RefundRegister,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/devolucion/detail/:id',
    name: 'RefundDetail',
    component: RefundDetail,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/orden',
    name: 'OrderList',
    component: OrderList,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/orden/general',
    name: 'OrderListGeneral',
    component: OrderListGeneral,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/orden/:type/edit/:id',
    name: 'OrderEdit',
    component: OrderEdit,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/orden/:type/detail/:id',
    name: 'OrderDetail',
    component: OrderDetail,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/orden/:type/register',
    name: 'OrderRegister',
    component: OrderRegister,
    meta: {
      requiresAuth: true
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  const currentUser = localStorage.getItem('userToken');
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  if (requiresAuth && !currentUser) next('login');
  else if (!requiresAuth && currentUser) next('home');
  else next();
});

export default router
