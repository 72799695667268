<template>
  <AppLayout>
    <h2 class="mb-4">
      Listado de Modificaciones
      <router-link
        v-if="(userType === 'Admin' || userModules.includes(moduleForecast)) && userType !== 'Invitado'"
        class="btn btn-primary float-right"
        :to="{ path: '/' + nodeForecast + '/register' }"
      >
        <font-awesome-icon icon="plus" /> Registrar
      </router-link>
    </h2>
    <div class="card mb-4">
      <div class="card-body">
        <table id="table"  class="table table-bordered">
          <thead>
            <tr>
              <th>Fecha</th>
              <th>Producto</th>
              <th>Valor</th>
              <th>Tipo</th>
              <th>Modificado Por</th>
              <th>Acciones</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="row in dataForecast" :key="row.id">
              <td>{{ row.fecha.substr(0,10) }}</td>
              <td>{{ row.producto }}</td>
              <td v-if="row.tipo == '1'">{{ row.valor + "%" }}</td>
              <td v-else>{{ row.valor }}</td>
              <td v-if="row.tipo == '1'">Porcentaje</td>
              <td v-else>Cantidad</td>
              <td>{{ row.usuario }}</td>
              <td>
                <router-link
                  v-if="userType === 'Admin' || userType === 'Full Access' || userType === 'Comercial' || userType === 'Editor'"
                  class="btn btn-primary"
                  :to="{ path: '/' + nodeForecast + '/edit/' + row.id }"
                >
                  <font-awesome-icon icon="edit" /> Editar
                </router-link>&nbsp;
                <button v-if="userType === 'Admin' || userType === 'Full Access' || userType === 'Comercial'" class="btn btn-danger" @click="deleteForecast(row.id)">
                  <font-awesome-icon icon="trash-alt" /> Eliminar
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </AppLayout>
</template>

<script>
import api from "@/api.js"
import AppLayout from "@/components/layout/AppLayout.vue"
export default {
  name: "forecastlist",
  components: {
    AppLayout,
  },
  data() {
    return {
      dataForecast: [],
      nodeForecast: "pronostico",
      moduleForecast: "Pronosticos",
      userType: "Admin",
      userModules: []
    };
  },
  created: function() {
    this.getForecasts();
    this.$data.userType = localStorage.getItem("userType");
    this.$data.userModules = localStorage.getItem("userModules");
  },
  methods: {
    getForecasts: function() {
      let loader = this.$loading.show();
      let that = this;
      this.dataForecast.length = 0;
      api
        .getForecasts()
        .then((data) => {
          that.dataForecast = data.forecasts;
          loader.hide();
        })
        .catch((error) => {
          that.showAlert(error.response.data.message);
          loader.hide();
          if(error.response.status === 401){
            localStorage.removeItem("userToken");
            that.$router.push('/login');
          }
        });
    },
    deleteForecast: function(id) {
      let that = this;
      this.$swal({
        title: "Quieres Eliminar?",
        text: "Está seguro de querer eliminar esto",
       icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si",
        cancelButtonText: "No"
      }).then(result => {
        if (result.value) {
          let loader = this.$loading.show();
          api
            .deleteForecast(id)
            .then(() => {
              that.dataForecast = that.dataForecast.filter(forecast => forecast.id != id);
              loader.hide();
            })
            .catch((error) => {
              that.showAlert(error.response.data.message);
              loader.hide();
              if(error.response.status === 401){
                localStorage.removeItem("userToken");
                that.$router.push('/login');
              }
            });
        }
      });
    },
    showAlert: function(message) {
      this.$swal({
        position: "top-end",
        icon: "error",
        title: message,
        showConfirmButton: false,
        timer: 1500
      });
    }
  }
};
</script>
