<template>
  <div>
    <div id="divSt0">
      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label class="font-weight-bold">Bodega</label>
            <p class="lead">{{ bodega }}</p>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label class="font-weight-bold">Producto Saliente</label>
            <p class="lead">{{ producto_saliente }}</p>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-3">
          <div class="form-group">
            <label class="font-weight-bold">Producto Entrante</label>
            <p class="lead">{{ producto_entrante }}</p>
          </div>
        </div>
        <div class="col-md-3">
          <div class="form-group">
            <label class="font-weight-bold">Número Lote</label>
            <p class="lead">{{ lote_numero }}</p>
          </div>
        </div>
        <div class="col-md-2">
          <div class="form-group">
            <label class="font-weight-bold">Fecha Lote</label>
            <p class="lead">{{ lote_fecha }}</p>
          </div>
        </div>
        <div class="col-md-2">
          <div class="form-group">
            <label class="font-weight-bold">Fecha Vencimiento</label>
            <p class="lead">{{ lote_vencimiento }}</p>
          </div>
        </div>
        <div class="col-md-2">
          <div class="form-group">
            <label class="font-weight-bold">Cantidad</label>
            <p class="lead">{{ cantidad_entrante }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "orderdetailproduct",
  props: {
    dataOrder: Object,
  },
  data() {
    return {
      //object
      bodega: null,
      producto_saliente: null,
      producto_entrante: null,
      lote_numero: null,
      lote_fecha: null,
      lote_vencimiento: null,
      cantidad_entrante: null,
    };
  },
  watch: {
    dataOrder: function(value) {
      this.$data.bodega = value.bodega;
      this.$data.producto_saliente = value.producto_saliente;
      this.$data.producto_entrante = value.producto_entrante;
      this.$data.lote_numero = value.lote_numero;
      if(value.lote_fecha) {
        this.$data.lote_fecha = value.lote_fecha.substr(0,10);
      }
      if(value.lote_vencimiento) {
        this.$data.lote_vencimiento = value.lote_vencimiento.substr(0,10);
      }
      this.$data.cantidad_entrante = value.cantidad_entrante;
    }
  },
  methods: {
    showAlert: function(message) {
      this.$swal({
        position: "top-end",
        icon: "error",
        title: message,
        showConfirmButton: false,
        timer: 1500
      });
    }
  } 
};
</script>
