<template>
    <AppLayout>
        <h2 class="mb-4">Ciudades</h2>
        <div class="card mb-4">
          <div class="card-body">
              <div class="row">
                  <div class="col-md-4">
                    <h4>Registro</h4>
                    <form @submit.prevent="registerCity">
                      <div class="form-group">
                        <label>Nombre</label>
                        <input v-model="nombre" type="text" class="form-control" />
                      </div>
                      <div class="form-group">
                        <label>Departamento</label>
                        <select v-model="id_departamento" class="form-control">
                          <option v-for="row in dataStates" :key="row.id" :value="row.id">{{ row.nombre }}</option>
                        </select>
                      </div>
                      <button class="btn btn-primary" type="submit">
                        <font-awesome-icon icon="save" /> Registrar
                      </button>
                    </form>
                  </div>
                  <div class="col-md-8">
                      <h4>Listado</h4>
                      <ListList :userType="userType" :dataList="dataCity" @deleteList="deleteCity"></ListList>
                  </div>
              </div>    
          </div>
        </div> 
    </AppLayout>
</template>

<script>
import api from "@/api.js"
import AppLayout from '@/components/layout/AppLayout.vue'
import ListList from '@/components/list/ListList.vue'
export default {
  name: 'city',
  components: {
    AppLayout,
    ListList,
  },
  data() {
    return {
        nombre: null,
        id_departamento: null,
        dataCity: [],
        dataStates: [],
        nodeCity: "ciudad",
        userType: "Admin",
    }
  },
  created: function() {
    this.$data.userType = localStorage.getItem("userType");
  },
  mounted: function () {
    this.getCities();
    this.getStates();
  },
  methods: {
    registerCity: function () {
      let that = this;
      let loader = this.$loading.show();
      let data = { nombre: this.nombre, id_departamento: this.id_departamento };
      api
        .registerCity(data)
        .then(() => {
          loader.hide();
          that.getCities();
        })
        .catch((error) => {
          that.showAlert(error.response.data.message);
          loader.hide();
          if(error.response.status === 401){
            localStorage.removeItem("userToken");
            that.$router.push('/login');
          }
        });
    },
    getCities: function () {
      let that = this;
      let loader = this.$loading.show();
      this.dataCity.length = 0;
      api
        .getCities()
        .then((data) => {
          that.dataCity = data.cities;
          loader.hide();
        })
        .catch((error) => {
          that.showAlert(error.response.data.message);
          loader.hide();
          if(error.response.status === 401){
            localStorage.removeItem("userToken");
            that.$router.push('/login');
          }
        });
    },
    getStates: function () {
      let that = this;
      let loader = this.$loading.show();
      this.dataStates.length = 0;
      api
        .getStates()
        .then((data) => {
          that.dataStates = data.states;
          loader.hide();
        })
        .catch((error) => {
          that.showAlert(error.response.data.message);
          loader.hide();
          if(error.response.status === 401){
            localStorage.removeItem("userToken");
            that.$router.push('/login');
          }
        });
    },
    deleteCity: function(id) {
      let that = this;
      let loader = this.$loading.show();
      api
        .deleteCity(id)
        .then(() => {
          that.dataCity = that.dataCity.filter(city => city.id != id);
          loader.hide();
        })
        .catch((error) => {
          that.showAlert(error.response.data.message);
          loader.hide();
          if(error.response.status === 401){
            localStorage.removeItem("userToken");
            that.$router.push('/login');
          }
        });
    },
    showAlert: function(message) {
      this.$swal({
        position: "top-end",
        icon: "error",
        title: message,
        showConfirmButton: false,
        timer: 1500
      });
    }
  }
}
</script>
